import { Button } from '@nextui-org/react';
import React, { useState } from 'react';

import { IconAiSuggest, IconAiSuggestBlack } from '@/assets';
import { getSuggestPrompt } from '@/services';

interface SuggestPromptProps {
  createForm: { watch: any; setValue: any };
  children: React.ReactNode;
}

const SuggestPrompt: React.FC<SuggestPromptProps> = ({ createForm, children }) => {
  const { watch, setValue } = createForm;
  const [suggestPrompt, setSuggestPrompt] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const handleSuggestPrompt = async () => {
    setLoading(true);
    const prompt = watch('prompt');
    if (prompt) {
      try {
        const res = await getSuggestPrompt(prompt);
        if (res.status === 200) {
          setSuggestPrompt([res.data]);
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      try {
        const res = await getSuggestPrompt('');
        if (res.status === 200) {
          setSuggestPrompt([res.data]);
        }
      } catch (error) {
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const handleSelectPrompt = (prompt: string) => {
    setValue('prompt', prompt);
    setSuggestPrompt((prompts) => prompts.filter((item) => item !== prompt));
  };

  return (
    <>
      <div className="relative">
        {children}
        <Button
          isLoading={loading}
          // aria-disabled={Boolean(!watch('prompt'))}
          onClick={handleSuggestPrompt}
          className="shadow-card-transparent absolute bottom-[16px] right-[8px] flex max-h-[29px] max-w-[130px] cursor-pointer items-center rounded-[30px] bg-white px-3 py-[5px] aria-disabled:cursor-not-allowed"
        >
          <IconAiSuggest />
          {/* {!watch('prompt') ? <IconAiSuggestBlack className="opacity-50" /> : <IconAiSuggest />} */}
          <p
            // aria-disabled={Boolean(!watch('prompt'))}
            className="text-12 font-normal aria-disabled:opacity-50 sm:hidden"
          >
            AI suggestion
          </p>
        </Button>
      </div>

      {suggestPrompt?.length > 0 && (
        <div className="my-5 flex flex-wrap gap-2">
          {suggestPrompt?.map((value: any, index: number) => (
            <span
              className="relative flex cursor-pointer items-start rounded-[10px] border-1 border-orange-200 bg-[#FFF8F6] px-2 py-1 text-12 font-normal"
              key={index}
              onClick={() => handleSelectPrompt(value)}
            >
              <IconAiSuggestBlack className="absolute left-[10px] top-[3px]" />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{value}
            </span>
          ))}
        </div>
      )}
    </>
  );
};

export default SuggestPrompt;
