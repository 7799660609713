import { Input } from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import type { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { match } from 'ts-pattern';

interface ParamsCustomerProps {
  param: any;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

const ParamsCustomer: React.FC<ParamsCustomerProps> = ({ param, watch, setValue }) => {
  const [selectValue, setSelectValue] = useState(param.example);

  const handleParams = (param: any, value: string | number) => {
    if (selectValue === value) return;

    setSelectValue(value);
    let prompt = watch('prompt');
    prompt = prompt.split('--')[0].trim();
    if (param.name === 'style') {
      return setValue('prompt', `${prompt} ${value}`);
    }
    return setValue('prompt', `${prompt} ${param.key} ${value}`);
  };

  const RenderParamFollowName = () => {
    return match(param.type)
      .with('rangeInput', () => (
        <div className="flex flex-wrap items-center gap-2">
          {param.defaultValue.map((item: string | number, index: number) => (
            <span
              aria-expanded={selectValue.toString() === item.toString()}
              className="cursor-pointer rounded-full border border-gray-200 px-3 py-[6px] text-13 font-medium text-gray-500 aria-expanded:border-black aria-expanded:text-black"
              key={index}
              onClick={() => handleParams(param, item)}
            >
              {item}
            </span>
          ))}
        </div>
      ))
      .with('numberArray', () => (
        <div className="flex flex-wrap items-center gap-2">
          {param.defaultValue.map((item: string | number, index: number) => (
            <span
              aria-expanded={selectValue.toString() === item.toString()}
              className="cursor-pointer rounded-full border border-gray-200 px-3 py-[6px] text-13 font-medium text-gray-500 aria-expanded:border-black aria-expanded:text-black"
              key={index}
              onClick={() => handleParams(param, item)}
            >
              {item}
            </span>
          ))}
        </div>
      ))
      .with('stringArray', () => (
        <div className="flex flex-wrap items-center gap-2">
          {param.defaultValue.map((item: string | number, index: number) => (
            <span
              aria-expanded={selectValue.toString() === item.toString()}
              className="cursor-pointer rounded-full border border-gray-200 px-3 py-[6px] text-13 font-medium text-gray-500 aria-expanded:border-black aria-expanded:text-black"
              key={index}
              onClick={() => handleParams(param, item)}
            >
              {item}
            </span>
          ))}
        </div>
      ))
      .with('stringInput', () => (
        <div className="flex w-full flex-wrap items-center gap-2 rounded-[100px] bg-white">
          <Input
            disabled={param.disable}
            classNames={{
              base: 'flex justify-center w-full',
              input: [
                'dark:placeholder:text-white font-normal text-14 pl-1 disabled:cursor-not-allowed w-full',
                'rounded-[100px]',
              ],
              innerWrapper: ['bg-transparent', 'rounded-[100px] w-full'],
              inputWrapper: ['bg-gray-50', 'rounded-[100px] w-full'],
            }}
            placeholder={param.placeholder}
            onChange={(e) => handleParams(param, e.target.value)}
          />
        </div>
      ))
      .with('numberInput', () => (
        <div className="flex w-full flex-wrap items-center gap-2 rounded-[100px] bg-white">
          <Input
            disabled={param.disable}
            type="number"
            classNames={{
              base: 'flex justify-center w-full input-hidden-arrow',
              input: [
                'dark:placeholder:text-white font-normal text-14 pl-1 disabled:cursor-not-allowed w-full',
                'rounded-[100px]',
              ],
              innerWrapper: ['bg-transparent', 'rounded-[100px] w-full'],
              inputWrapper: ['bg-gray-50', 'rounded-[100px] w-full'],
            }}
            placeholder={param.placeholder}
            onChange={(e) => handleParams(param, e.target.value)}
          />
        </div>
      ))
      .otherwise(() => <>null</>);
  };

  useEffect(() => {
    if (param.example || param.example === 0) {
      setSelectValue(param.example);
    } else {
      setSelectValue('');
    }
  }, [param]);

  return <div className="w-full">{RenderParamFollowName()}</div>;
};

export default ParamsCustomer;
