import { ScrollShadow } from '@nextui-org/react';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

import { IconCheckboxGen, IconCheckboxNull, ImageTempGenItem } from '@/assets';
import { Images } from '@/components';
import useGetWidth from '@/hooks/useGetWidth';
import type { PromptProviderState } from '@/provider';
import { usePrompt } from '@/provider';

interface ImageMidjourneyRightBarProps {
  imgSelect: PromptProviderState;
  setImgSelect: React.Dispatch<React.SetStateAction<PromptProviderState>>;
  setIdAssetIsSelected: React.Dispatch<React.SetStateAction<number[]>>;
  resetIsSelect: boolean;
}

const ImageMidjourneyRightBar: React.FC<ImageMidjourneyRightBarProps> = ({
  imgSelect,
  setImgSelect,
  setIdAssetIsSelected,
  resetIsSelect,
}) => {
  const widthScreen = useGetWidth();
  const { state } = usePrompt();
  const [arrSelected, setArrSelected] = useState<number[]>([]);

  const isSelected = (id: number) => arrSelected.some((item) => item === id);

  const onSelectItem = (id: number) => {
    if (isSelected(id)) setArrSelected(arrSelected.filter((asset) => asset !== id));
    else {
      setArrSelected((pre) => [...pre, id]);
    }
  };
  useEffect(() => {
    if (resetIsSelect) {
      setArrSelected([]);
    }
  }, [resetIsSelect]);

  useEffect(() => {
    setIdAssetIsSelected(arrSelected);
  }, [arrSelected]);

  return (
    <div className="h-full border-l-1 border-gray-100 p-5 pr-0 lg:w-full md:mb-20 md:border-none md:px-0">
      <ScrollShadow
        orientation={widthScreen < 992 ? 'horizontal' : 'vertical'}
        hideScrollBar
        className="h-full select-none lg:flex lg:h-auto"
        size={5}
      >
        {[...state]?.reverse()?.map((img, index) =>
          img.url === '' ? (
            <Image
              onClick={() => setImgSelect(img)}
              key={index}
              className={`mb-2 !h-[100px] !w-[100px] cursor-pointer lg:mb-0 lg:mr-2 ${
                imgSelect.imageId === img.imageId || (img?.assetId && isSelected(img.assetId))
                  ? '!opacity-100'
                  : '!opacity-50'
              }`}
              src={ImageTempGenItem}
              alt="bg"
            />
          ) : (
            <div className="relative">
              <Images
                isFallbackLoading
                aria-expanded={imgSelect.imageId === img.imageId}
                onClick={() => setImgSelect(img)}
                key={index}
                imageWrapper="!min-h-[100px] !min-w-[100px] !h-[100px] !w-[100px] cursor-pointer mb-2 lg:mb-0 lg:mr-2"
                className={`cursor-pointer rounded-[16px] object-cover hover:!opacity-100 ${
                  imgSelect.imageId === img.imageId || (img?.assetId && isSelected(img.assetId))
                    ? '!opacity-100'
                    : '!opacity-50'
                }`}
                src={img.url}
                alt="image"
              />

              {img?.assetId && isSelected(img.assetId) ? (
                <div
                  className="absolute bottom-[13px] right-[5px] z-10 flex h-5 w-5 cursor-pointer items-center justify-center rounded-[50%] lg:right-4"
                  onClick={() => {
                    img?.assetId && onSelectItem(img.assetId);
                  }}
                >
                  <IconCheckboxGen className="" />
                </div>
              ) : (
                <div
                  className="absolute bottom-[13px] right-[5px] z-10 flex h-5 w-5 cursor-pointer items-center justify-center rounded-[50%] lg:right-4"
                  onClick={() => {
                    img?.assetId && onSelectItem(img.assetId);
                  }}
                >
                  <IconCheckboxNull className="" />
                </div>
              )}
            </div>
          ),
        )}
      </ScrollShadow>
    </div>
  );
};

export default ImageMidjourneyRightBar;
