import { Divider, Progress, ScrollShadow, useDisclosure } from '@nextui-org/react';
import mixpanel from 'mixpanel-browser';
import { useSearchParams } from 'next/navigation';
import React, { memo, useEffect, useState } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import { match } from 'ts-pattern';

import { Buttons, SelectBase } from '@/components';
import ClientOnly from '@/components/client-only';
import { MIXPANEL_TRACK } from '@/configs/constant';
import Blend from '@/contents/gen-model/sideBar/command/Blend';
import BtnSubmit from '@/contents/gen-model/sideBar/command/BtnSubmit';
import CreateImage from '@/contents/gen-model/sideBar/command/CreateImage';
import ItemCustomImg from '@/contents/gen-model/sideBar/custom/ItemCustomImg';
import OpsUV from '@/contents/gen-model/sideBar/custom/OpsUV';
import ModelRequestNotificationLimit from '@/contents/gen-model/sideBar/modal/ModelRequestNotificationLimits';
import type { PromptProviderState } from '@/provider';
import { usePrompt } from '@/provider';
import { useAuth } from '@/provider/UserProvider';
import {
  upscale2xImageMidjourney,
  upscale4xImageMidjourney,
  varyStrongImageMidjourney,
  zoomOut15ImageMidjourney,
  zoomOut20ImageMidjourney,
} from '@/services';
import { getPlan } from '@/services/pricing';
import { getUseCase } from '@/services/usecase';
import { useSMutation, useSQuery } from '@/utils';

import SkeletonGen from '../SkeletonGen';
import CampaignGen from './CampaignGen';
import ModelGenOutOfCredits from './modal/ModelGenOutOfCredits';

interface ImageMidjourneyModelProps {
  isLoading: boolean;
  isLoadingBlendImageMidjourney: boolean;
  imgSelect: PromptProviderState;
  generateImg: (prompt: string, tool: string) => void;
  blendImageMidjourney: (fileList: File[]) => void;
  setQueueImg: React.Dispatch<React.SetStateAction<number[]>>;
  setImgSelect: React.Dispatch<React.SetStateAction<PromptProviderState>>;
  customImgCallback: (
    customId: 'V1' | 'V2' | 'V3' | 'V4' | 'U1' | 'U2' | 'U3' | 'U4',
    prompt: string,
    tool: string,
  ) => void;
  createForm: UseFormReturn<FieldValues, any, undefined>;
  campaigns?: any;
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  feeCredit: number;
  totalCredit: number;
}

const ImageMidjourneyModel: React.FC<ImageMidjourneyModelProps> = ({
  isLoading,
  isLoadingBlendImageMidjourney,
  imgSelect,
  generateImg,
  blendImageMidjourney,
  setQueueImg,
  setImgSelect,
  customImgCallback,
  createForm,
  campaigns,
  setOptionsSelectStyle,
  feeCredit,
  totalCredit,
}) => {
  const { auth, setAuth, isLogin } = useAuth();
  const { state, setState, modelConfigRetry } = usePrompt();
  const [tool, setTool] = useState('--v 5.2');
  const searchParams = useSearchParams();
  const [modelConfigSate, setModelConfigSate] = useState<any>(null);
  const { isOpen, onOpen: onOpenModelOutOfCredit, onOpenChange } = useDisclosure();

  const { data: useCase } = useSQuery({
    queryKey: ['get-use-case-id', searchParams.get('template')],
    queryFn: () => getUseCase(Number(searchParams.get('template'))),
    enabled: Boolean(searchParams.get('template')) && !isNaN(Number(searchParams.get('template'))),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const { register, handleSubmit, watch, setValue, reset } = createForm;

  const { mutate: zoomOut15, isLoading: isLoadingZoomOut15 } = useSMutation({
    mutationFn: zoomOut15ImageMidjourney,
  });

  const { mutate: zoomOut20, isLoading: isLoadingZoomOut20 } = useSMutation({
    mutationFn: zoomOut20ImageMidjourney,
  });

  const { mutate: upscale4x, isLoading: isLoadingUpscale4x } = useSMutation({
    mutationFn: upscale4xImageMidjourney,
  });

  const { mutate: upscale2x, isLoading: isLoadingUpscale2x } = useSMutation({
    mutationFn: upscale2xImageMidjourney,
  });

  const { mutate: varyStrong, isLoading: isLoadingVaryStrong } = useSMutation({
    mutationFn: varyStrongImageMidjourney,
  });

  const img = state.find((item) => item.imageId === imgSelect.imageId);

  const loading =
    isLoading ||
    isLoadingZoomOut15 ||
    isLoadingZoomOut20 ||
    isLoadingUpscale4x ||
    isLoadingUpscale2x ||
    isLoadingVaryStrong ||
    isLoadingBlendImageMidjourney;

  const onSubmit = async (data: any) => {
    if (totalCredit < feeCredit) {
      onOpenModelOutOfCredit();
    } else {
      mixpanel.track(MIXPANEL_TRACK.GENERATE_RUN);
      if (tool === 'blend') {
        const files: File[] = watch('files');
        await blendImageMidjourney(files);
      } else {
        const { prompt, tool } = data;
        if (!prompt && state.some((img) => img.readyRun)) return;
        await generateImg(prompt, tool);
        handleGetCredit();
      }
    }
  };

  const handleEditFunctionMidjourney = (name: 'strong' | 'x15' | 'x20' | '4x' | '2x') => {
    if (
      !auth.user.isPlus ||
      loading ||
      state.some((img) => img?.readyRun) ||
      img?.usedOptions?.includes(name)
    ) {
      return;
    }

    const stateWUseOps = state.map((item) =>
      item.imageId === img?.imageId
        ? {
            ...item,
            usedOptions: [...(item?.usedOptions?.length ? item.usedOptions : []), name],
          }
        : item,
    );

    const assetId = img?.assetId ?? 0;

    const tempState = {
      ...img,
      step: 1,
      done: false,
      progress: '0%',
      url: '',
      readyRun: true,
      customId: name,
      prompt: img?.prompt ?? '',
    };

    const handleResponseFunction = (data: any) => {
      const { imageId, assetId } = data;
      setQueueImg((state) => [...state, imageId]);
      setImgSelect({ ...tempState, imageId, assetId });
      setState([...stateWUseOps, { ...tempState, imageId, assetId }]);
    };

    match(name)
      .with('x15', () => {
        zoomOut15(assetId, {
          onSuccess(data) {
            handleResponseFunction(data.data);
          },
        });
      })
      .with('x20', () => {
        zoomOut20(assetId, {
          onSuccess(data) {
            handleResponseFunction(data.data);
          },
        });
      })
      .with('4x', () => {
        upscale4x(assetId, {
          onSuccess(data) {
            handleResponseFunction(data.data);
          },
        });
      })
      .with('2x', () => {
        upscale2x(assetId, {
          onSuccess(data) {
            handleResponseFunction(data.data);
          },
        });
      })
      .with('strong', () => {
        varyStrong(assetId, {
          onSuccess(data) {
            handleResponseFunction(data.data);
          },
        });
      })
      .otherwise(() => {});
  };
  const handleGetCredit = async () => {
    try {
      const res = await getPlan();

      if (res && res.credit !== undefined) {
        setAuth({ ...auth, user: { ...auth.user, credit: res?.credit } });
      } else {
        console.error('Invalid response format from getPlan');
      }
    } catch (error) {
      console.error('Error fetching credit');
    }
  };

  const handleOnChangeTool = (e: string) => {
    setValue('tool', e);
    setTool(e);
  };

  useEffect(() => {
    if (isLogin) {
      handleGetCredit();
    }
  }, [img?.progress]);

  useEffect(() => {
    setValue('prompt', img?.prompt);
  }, [imgSelect]);

  useEffect(() => {
    if (modelConfigSate) {
      reset({
        prompt: modelConfigSate?.prompt?.prompt,
        files: [],
      });
    } else if (!watch('campaignId')) {
      reset({ files: [], prompt: '' });
    }
  }, [modelConfigSate]);

  useEffect(() => {
    if (useCase || modelConfigRetry) {
      setModelConfigSate(useCase || modelConfigRetry);
    }
  }, [useCase, modelConfigRetry]);

  return (
    <>
      <ClientOnly skeleton={<SkeletonGen />}>
        <form
          className="flex h-full flex-col justify-between overflow-hidden overflow-y-auto scrollbar-hide md:w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <ScrollShadow
            size={10}
            hideScrollBar
            className="flex-1 overflow-y-auto scrollbar-hide md:flex md:flex-col md:justify-start"
          >
            <CampaignGen createForm={createForm} campaigns={campaigns} />
            <div className="mb-5">
              <div className="mb-2 mt-5 text-14 font-semibold">Tool</div>
              <SelectBase
                onChange={(e) => handleOnChangeTool(e)}
                defaultValue="--v 5.2"
                list={[
                  { label: 'Use the default model (V5.2)', value: '--v 5.2' },
                  { label: 'Midjourney Model V6 [ALPHA]', value: '--v 6.0' },
                  { label: 'Midjourney Model V5.1', value: '--v 5.1' },
                  { label: 'Niji Model V5', value: '--niji 5' },
                  {
                    label: 'blend',
                    value: 'blend',
                    desc: 'Blend images together seamlessly!',
                  },
                ]}
              />
            </div>
            {match(tool)
              .with('blend', () => <Blend setValue={setValue} watch={watch} />)
              .otherwise(() => (
                <CreateImage
                  setOptionsSelectStyle={setOptionsSelectStyle}
                  img={img}
                  register={register}
                  setValue={setValue}
                  watch={watch}
                  reset={reset}
                />
              ))}
            {img?.step === 3 && img?.customId?.slice(0, 1) === 'U' ? (
              <>
                <Divider className="mb-4 mt-5 bg-gray-100 md:hidden" />
                <ItemCustomImg
                  loading={loading}
                  handleEditFunctionMidjourney={handleEditFunctionMidjourney}
                  img={img}
                />
              </>
            ) : (
              img?.step === 2 && (
                <>
                  <Divider className="mb-4 mt-5 bg-gray-100 md:hidden" />
                  <OpsUV img={img} customImgCallback={customImgCallback} />
                </>
              )
            )}
          </ScrollShadow>
          <BtnSubmit>
            <div className="md:flex md:items-center md:justify-end">
              {isLogin ? (
                <p className="mb-3 flex items-center text-14 font-normal text-gray-500 md:m-0 md:mr-4">
                  Your available credits: {auth.user?.credit}
                  <ModelRequestNotificationLimit />
                </p>
              ) : (
                <p className="mb-3 flex items-center text-16 font-semibold text-orange-500 md:m-0 md:mr-4 md:text-13">
                  You must login to use model PLUS
                </p>
              )}
              {isLogin ? (
                <Buttons
                  className="w-full p-0 "
                  typeBtn="disabled"
                  type="submit"
                  disabled={
                    tool === 'blend'
                      ? watch('files')?.length < 2 || loading || state.some((img) => img?.readyRun)
                      : !watch('prompt') || loading || state.some((img) => img?.readyRun)
                  }
                >
                  <Progress
                    disableAnimation
                    value={
                      tool === 'blend'
                        ? watch('files')?.length < 2
                          ? 0
                          : 100
                        : watch('prompt')
                        ? Number(img?.progress.slice(0, -1)) > 0
                          ? Number(img?.progress.slice(0, -1))
                          : 100
                        : 0
                    }
                    isIndeterminate={loading || (img?.progress === '0%' && img?.readyRun)}
                    color="primary"
                    label={
                      <span className="text-white">
                        Generate {loading || (state.some((img) => img?.readyRun) && img?.progress)}
                      </span>
                    }
                    className="relative h-full"
                    classNames={{
                      labelWrapper: 'contents z-9999',
                      base: 'h-full',
                      track: `h-full ${
                        loading || (img?.progress !== '100%' && img?.readyRun) || !watch('prompt')
                          ? 'bg-disabled'
                          : ''
                      }`,
                      label: ['absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-999'],
                    }}
                  />
                </Buttons>
              ) : (
                <Buttons className="w-full p-0" typeBtn="disabled" disabled>
                  <span className="text-white">Generate</span>
                </Buttons>
              )}
            </div>
          </BtnSubmit>
        </form>
      </ClientOnly>
      {isLogin && <ModelGenOutOfCredits isOpen={isOpen} onOpenChange={onOpenChange} />}
    </>
  );
};

export default memo(ImageMidjourneyModel);
