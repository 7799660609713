import React, { useState } from 'react';
import type { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { toast } from 'react-toastify';

import { CancelFileIcon, WarningIcon } from '@/assets';
import { ButtonIcon, Images, UpLoadFileMultiple } from '@/components';
import { checkSizeBeforeUpload } from '@/utils';

interface BlendProps {
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
}

const Blend: React.FC<BlendProps> = ({ setValue, watch }) => {
  const [files, setFiles] = useState<File[]>(watch('files') ?? []);

  const handleChangeFile = (fileList: File[]) => {
    const filesArray = Array.from(fileList || []);

    let isCheck = false;
    filesArray.forEach((file) => {
      if (checkSizeBeforeUpload(file, 2)) isCheck = true;
    });

    if (isCheck) return;

    if (filesArray.length > 5 || files.length > 5)
      return toast.error('The maximum for blending is 5 files.');

    if (filesArray.length + files.length < 6) {
      setFiles([...files, ...filesArray]);
      setValue('files', [...files, ...filesArray]);
    } else {
      return toast.error('The maximum for blending is 5 files.');
    }
  };

  const handleRemoveFile = (file_i: File, index_i: number) => {
    const fileRemote = files.filter(
      (file_k, index_k) => index_i + file_i.name !== index_k + file_k.name,
    );
    setFiles(fileRemote);
    setValue('files', fileRemote);
  };

  return (
    <div>
      <span className="mb-2 flex items-center text-14 font-semibold">
        Image <span className="ml-1 text-red-600"> *</span>
      </span>
      <div className="w-full">
        <UpLoadFileMultiple
          handleChange={handleChangeFile}
          title="The blend command allows you to upload 2–5 images each photo is maximum 2mb"
        />
      </div>
      <p className="mt-3 flex items-center rounded-[8px] bg-yellow-50 p-2 text-13 font-normal text-yellow-600">
        <WarningIcon className="mr-2" />
        You can be banned if you upload sexual images
      </p>
      <div className="mt-4 grid grid-cols-3 gap-2">
        {files.map((file: File, index) => {
          const url = URL.createObjectURL(file);
          return (
            <div className="rounded-[16px]">
              <div className="flex h-[100px] items-center justify-center rounded-[16px]">
                <Images className="rounded-[16px] object-cover" src={url} alt={url} />
              </div>
              <div className="mt-3 flex w-[100px] items-center">
                <span className="line-clamp-1 w-[80px] text-14 font-normal text-gray-600">
                  {file.name}
                </span>
                <ButtonIcon onClick={() => handleRemoveFile(file, index)}>
                  <CancelFileIcon className="cursor-pointer" />
                </ButtonIcon>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Blend;
