import { Button } from '@nextui-org/react';
import type { ReactElement } from 'react';
import React from 'react';
import { toast } from 'react-toastify';

import { IconTrash22x22, IconTrashBlack22x22 } from '@/assets';
import { Buttons } from '@/components';
import useGetWidth from '@/hooks/useGetWidth';
import { usePrompt } from '@/provider';
import { useAuth } from '@/provider/UserProvider';

interface BtnSubmitProps {
  children: ReactElement;
}

const BtnSubmit: React.FC<BtnSubmitProps> = ({ children }) => {
  const { isLogin } = useAuth();
  const { isRemix, tab, idAssetIsSelected, publishAsset, useModalControl } = usePrompt();
  const { onOpen } = useModalControl;

  const widthScreen = useGetWidth();
  return (
    <>
      <div
        aria-expanded={isRemix}
        className="z-99 border-t-1 border-gray-100 bg-white py-5 md:fixed md:bottom-0 md:left-0 md:w-full md:!p-[10px] aria-expanded:md:absolute aria-expanded:md:rounded-b-[12px]"
      >
        {widthScreen > 767 ? (
          children
        ) : tab === 'create' ? (
          children
        ) : (
          <>
            <div className="flex items-center gap-4">
              <Buttons
                onClick={() => {
                  isLogin
                    ? publishAsset(idAssetIsSelected)
                    : toast.warning('You must login to publish images!');
                }}
                typeBtn={idAssetIsSelected.length > 0 ? 'primary' : 'disabled'}
                disabled={!(idAssetIsSelected.length > 0)}
                className="flex flex-1 items-center rounded-[100px]"
              >
                Publish
              </Buttons>

              <Button
                isIconOnly
                variant="flat"
                onClick={() => {
                  isLogin ? onOpen() : toast.warning('You must login to delete images!');
                }}
                disabled={!(idAssetIsSelected.length > 0)}
                className="flex items-center rounded-[100px]"
              >
                {idAssetIsSelected.length > 0 ? <IconTrashBlack22x22 /> : <IconTrash22x22 />}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BtnSubmit;
