import { Input } from '@nextui-org/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { HeaderSearchIcon, IconCheckWhite, IconLeft } from '@/assets';
import { Images } from '@/components';
import type { Models } from '@/types';

const SelectModel: React.FC<{
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  defaultValue: string;
  createForm: UseFormReturn<FieldValues, any, undefined>;
  fieldName: string;
  modelDetail: Models;
}> = ({ setOptionsSelectStyle, defaultValue, createForm, fieldName, modelDetail }) => {
  const { setValue } = createForm;
  const [select, setSelect] = useState(defaultValue);
  const [name, setName] = useState('');
  const queryClient = useQueryClient();
  const dataListModels: any = queryClient.getQueryData(['listMode']);

  const [list, setList] = useState((dataListModels as any)?.data || []);

  const handleSelect = useCallback((val: string) => {
    setSelect(val);
    setValue(fieldName, val);
  }, []);

  useEffect(() => {
    if (dataListModels) {
      const list = (dataListModels as any)?.data.filter((item: any) =>
        item.label.toLowerCase().includes(name.toLowerCase()),
      );
      setList(list);
    }
  }, [name, modelDetail, dataListModels]);


  console.log('dataListModels', dataListModels);


  return (
    <div className="h-full">
      <div className="relative flex items-center justify-between border-b-1 border-gray-100 p-6 sm:p-4">
        <h2
          onClick={() => setOptionsSelectStyle('')}
          className="relative flex cursor-pointer items-center whitespace-nowrap text-18 font-semibold lg:text-14 sm:pl-[14px]"
        >
          <IconLeft className="mr-2 lg:mr-1 lg:scale-75 sm:absolute sm:left-[-10px]" />{' '}
          <span>Select a model</span>
        </h2>
        <div>
          <Input
            classNames={{
              input: [
                'dark:placeholder:text-white font-normal text-[14px] sm:text-[13px] pl-1',
                'rounded-[100px]',
              ],
              innerWrapper: ['bg-transparent', 'rounded-[100px]'],
              inputWrapper: ['bg-gray-50', 'rounded-[100px]', 'w-[320px] lg:w-[180px]'],
            }}
            startContent={<HeaderSearchIcon />}
            placeholder="Search models"
            onValueChange={(e) => setName(e)}
          />
        </div>
      </div>
      {list && (
        <ResponsiveMasonry
          className="scrollbar-custom h-[calc(100%-89px)] overflow-hidden overflow-y-auto p-6 sm:p-4"
          columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 4, 1200: 6 }}
        >
          <Masonry gutter="12px">
            {list.map((item: any) => (
              <div
                key={item.value}
                onClick={() => {
                  handleSelect(item.value);
                  setOptionsSelectStyle('');
                }}
                className="relative flex h-auto cursor-pointer flex-col rounded-[16px] bg-gray-50 p-2"
              >
                <Images
                  imageWrapper="w-full !h-auto"
                  className="h-auto w-full rounded-[10px] object-cover object-top"
                  src={item.image[0]}
                  alt="model"
                  isFallbackLoading
                  skeleton
                />

                {select === item.value && (
                  <div className="absolute right-[5px] top-[5px] z-10 flex h-7 w-7 items-center justify-center rounded-[50%] bg-green-500">
                    <IconCheckWhite />
                  </div>
                )}
                <span className="mt-2 line-clamp-1 break-words text-13 font-semibold leading-[1.5]">
                  {item.label}
                </span>
                <span className="line-clamp-2 break-words text-12 font-normal leading-[1.4] text-gray-500">
                  {item.value}
                </span>
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      )}
    </div>
  );
};

export default SelectModel;
