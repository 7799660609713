import { Divider, Progress } from '@nextui-org/react';
import React, { memo, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { UploadFileIcon } from '@/assets';
import { Buttons, UpLoadFileModel } from '@/components';
import { CardFileRenderFollowType } from '@/components/card';
import { sizeOfFile, typeAll } from '@/configs/constant';
import type { PromptProviderState } from '@/provider';
import { initialState, usePrompt } from '@/provider';
import { checkSizeBeforeUpload } from '@/utils';
import { uploadFileToFirebase } from '@/utils/serviceHelper';

interface AirightModelProps {
  isLoading: boolean;
  imgSelect: PromptProviderState;
  generateImg: (prompt: string) => void;
  setImgSelect: React.Dispatch<React.SetStateAction<PromptProviderState>>;
}

const AirightModel: React.FC<AirightModelProps> = ({
  isLoading,
  imgSelect,
  generateImg,
  setImgSelect,
}) => {
  const { setState } = usePrompt();
  const [file, setFile] = useState<File>();

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  // const { isOpen, onOpenChange } = useDisclosure();

  const handleClearFile = () => {
    setState([initialState]);
    setImgSelect(initialState);
    setValue('file', null);
    setFile(undefined);
  };

  const handleChangeFile = (file: File) => {
    const type = file.type.split('/')[0];
    if (type !== 'audio' && type !== 'image' && type !== 'video' && type !== 'application') return;
    if (checkSizeBeforeUpload(file, sizeOfFile[type])) return;

    const fileInfo = {
      ...initialState,
      file,
      done: true,
      readyRun: false,
      step: 3,
      progress: '100%',
    };
    setState([fileInfo]);
    setImgSelect(fileInfo);
    setValue('file', file);
    setFile(file);
  };

  const onSubmit = async (data: any) => {
    const { file } = data;
    if (!file) return;
    if (file?.type.split('/')[0] === 'video' || file?.type.split('/')[0] === 'audio') {
      uploadFileToFirebase(file, (progress: any) => {
        if (progress.url) {
          return generateImg({
            ...data,
            mimeType: progress.mimeType,
            assetUrl: progress.url,
          });
        }
        if (progress === 100)
          setImgSelect({
            ...imgSelect,
            progress: `${progress}%`,
            readyRun: true,
          });
        else
          setImgSelect({
            ...imgSelect,
            progress: `${progress}%`,
            readyRun: true,
          });
      });
    } else {
      return await generateImg(data);
    }
  };

  const RenderImgFollowType = useMemo(() => {
    return file ? (
      <CardFileRenderFollowType
        file={file}
        type={file.type.split('/')[0] ?? ''}
        isControl={window.screen.width < 768}
        enablerAnimation
      />
    ) : (
      <></>
    );
  }, [file]);

  return (
    <form className="flex h-full w-full flex-col justify-between" onSubmit={handleSubmit(onSubmit)}>
      {/* <ModelMyPrompt setValue={setValue} onOpenChange={onOpenChange} isOpen={isOpen} /> */}
      <div className="flex-1 overflow-y-auto scrollbar-hide md:flex-none md:overflow-y-visible">
        {errors.exampleRequired && <span>This field is required</span>}
        <span className="mb-2 flex items-center text-14 font-semibold">
          Upload artwork file <span className="text-red-600">*</span>
        </span>
        <div className="w-full">
          <UpLoadFileModel
            types={typeAll}
            handleClearFile={handleClearFile}
            setFile={setFile}
            file={file}
            handleChange={handleChangeFile}
          >
            {watch('file') && file ? (
              <div className="h-[200px] md:h-auto md:w-full">{RenderImgFollowType}</div>
            ) : (
              <div className="flex cursor-pointer flex-col items-center justify-center rounded-[16px] border border-dashed border-gray-200 bg-gray-50 stroke-2 py-5">
                <UploadFileIcon className="mb-4" />
                <span className="text-14 font-semibold">Drop your file here, or browse</span>
                <span className="text-center text-13 font-normal text-gray-400">
                  Support: Image/Json (max size: 20MB), Video (max size: 200MB), Audio (max size:
                  30MB).
                </span>
              </div>
            )}
          </UpLoadFileModel>
        </div>
        {/* <Divider className="my-6 bg-gray-100" />
        {file && file.type.split('/')[0] === 'audio' && (
          <>
            <span className="mb-2 flex items-center text-14 font-semibold">
              Upload thumbnail file <span className="text-red-600">*</span>
            </span>
            <UpLoadFileModel
              handleClearFile={handleClearFile}
              setFile={setThumbnail}
              file={thumbnail}
              handleChange={handleChangeFile}
            />
          </>
        )} */}
      </div>

      <div className="md:mt-4 md:flex md:items-center md:justify-end">
        <Divider className="mb-4 mt-5 bg-gray-100 md:hidden" />
        {/* <span className="mb-3 flex items-center text-14 font-normal text-gray-500 md:mb-0 md:mr-4">
          This month’s limit: {usedReq}/{totalReq}
          <ModelRequestNotificationLimit totalReq={totalReq} usedReq={usedReq} />
        </span> */}
        <div className="lg:fixed lg:bottom-0 lg:z-9999 lg:-ml-2 lg:flex lg:w-full lg:bg-white lg:p-2">
          <Buttons
            className="w-full p-0"
            typeBtn="disabled"
            type="submit"
            disabled={!watch('file')}
          >
            <Progress
              value={Number(imgSelect?.progress.slice(0, -1))}
              isIndeterminate={isLoading}
              color="primary"
              label={
                <span className="text-white">
                  <span className="text-white">
                    Generate {imgSelect?.readyRun && imgSelect?.progress}
                  </span>
                </span>
              }
              disableAnimation
              className="relative h-full"
              classNames={{
                labelWrapper: 'contents z-9999',
                base: 'h-full',
                track: `h-full ${
                  isLoading || imgSelect?.progress !== '100%' || !watch('file') ? 'bg-disabled' : ''
                }`,
                label: ['absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-999'],
              }}
            />
          </Buttons>
        </div>
      </div>
    </form>
  );
};

export default memo(AirightModel);
