import { match } from 'ts-pattern';

import {
  baseDalle2,
  baseInpaintModel,
  baseModel,
  baseModelAnime,
  baseModelIpAdapter,
  baseModelLivePortrait,
  baseModelQRCode,
  baseModelSticker,
  baseSDXL3,
  baseSdxlModel,
  baseText2Model,
  sizeText2Image,
} from '@/configs/constant';

export const transferUseCase = (
  model: string,
  useCase: any,
  modelConfig: any,
  watch: any,
  listLora: any,
) => {
  const loraConvert = modelConfig.lora
    ? modelConfig.lora.split(',').map((item: any) => item.split(':'))
    : '';
  const loras = loraConvert
    ? listLora?.data
        .filter((item: any) => loraConvert.some((lora: any) => lora[0] === item.alias))
        .map((lora: any) => ({
          ...lora,
          value: loraConvert.find((item: any) => item[0] === lora.alias)[1],
        }))
    : [];

  return match(model)
    .with('Text2Image', () => ({
      prompt: useCase?.prompt?.prompt,
      negative_prompt: modelConfig.negative_prompt,
      modelId: useCase.processingAsset.id,
      model: modelConfig.model,
      steps: modelConfig.steps,
      cfg_scale: modelConfig.cfg_scale,
      lora: loras || [],
      batch_size: modelConfig.batch_size || 1,
      width: modelConfig.width,
      height: modelConfig.height,
      enable_hires: modelConfig.enable_hires === 1,
      adetailer: modelConfig.enable_hires === 1,
      sampler: modelConfig?.samplers.replace(' Karras', ''),
      size:
        sizeText2Image.find(
          (item: any) => item.width === modelConfig.width && item.height === modelConfig.height,
        )?.text || '512x512',
      seed: modelConfig.seed ?? -1,
      vae: modelConfig.vae,
      hr_upscaler: modelConfig.hr_upscaler,
      hr_scale_by: modelConfig.hr_scale_by,
      hr_denoising_strength: modelConfig.hr_denoising_strength,
      hr_width: modelConfig.hr_width,
      hr_height: modelConfig.hr_height,
      hr_steps: modelConfig.hr_steps,
    }))
    .with('SDXL', () => ({
      prompt: useCase?.prompt?.prompt,
      negative_prompt: modelConfig.negative_prompt,
      modelId: useCase.processingAsset.id,
      model: modelConfig.model,
      steps: modelConfig.steps,
      cfg_scale: modelConfig.cfg_scale,
      lora: loras || [],
      batch_size: modelConfig.batch_size || 1,
      width: modelConfig.width,
      height: modelConfig.height,
      enable_hires: modelConfig.enable_hires === 1,
      sampler: modelConfig?.samplers.replace(' Karras', ''),
      size:
        sizeText2Image.find(
          (item: any) => item.width === modelConfig.width && item.height === modelConfig.height,
        )?.text || '512x512',
      seed: modelConfig.seed ?? -1,
      vae: modelConfig.vae,
      hr_upscaler: modelConfig.hr_upscaler,
      hr_scale_by: modelConfig.hr_scale_by,
      hr_denoising_strength: modelConfig.hr_denoising_strength,
      hr_width: modelConfig.hr_width,
      hr_height: modelConfig.hr_height,
      hr_steps: modelConfig.hr_steps,
    }))
    .with('ControlNet', () => ({
      prompt: useCase?.prompt?.prompt,
      modelId: useCase.modelId,
      steps: modelConfig.steps,
      cfg_scale: modelConfig.cfg_scale,
      control_weight: modelConfig.control_weight,
      generate_type: modelConfig.task,
      file: watch('file'),
      lora: loras || [],
      seed: modelConfig.seed ?? -1,
    }))
    .with('Qr Art', () => ({
      prompt: useCase?.prompt?.prompt,
      modelId: useCase.modelId,
      steps: modelConfig.steps,
      cfg_scale: modelConfig.cfg_scale,
      control_weight: modelConfig.control_weight,
      file: watch('file'),
      lora: loras || [],
      seed: modelConfig.seed ?? -1,
    }))
    .with('Image2Anime', () => ({
      modelId: useCase.modelId,
      control_weight: modelConfig.control_weight,
      model: modelConfig.model,
      file: watch('file'),
      lora: loras || [],
      prompt: useCase?.prompt?.prompt,
      negative_prompt: modelConfig.negative_prompt,
      width: modelConfig.width,
      height: modelConfig.height,
      batch_size: modelConfig.batch_size || 1,
    }))
    .with('Sticker', () => ({
      modelId: useCase.processingAsset.id,
      prompt: useCase?.prompt?.prompt,
      file: watch('file'),
    }))
    .with('Inpaint', () => ({
      prompt: useCase?.prompt?.prompt,
      modelId: useCase.modelId,
      steps: modelConfig.steps,
      cfg_scale: modelConfig.cfg_scale,
      control_weight: modelConfig.control_weight,
      model: modelConfig.model,
      negative_prompt: modelConfig.negative_prompt,
      batch_size: modelConfig.batch_size || 1,
      image: watch('image'),
      lora: loras || [],
      seed: modelConfig.seed ?? -1,
    }))
    .with('DALL-E 2&3', () => ({
      prompt: useCase?.prompt?.prompt,
      modelId: useCase?.modelId,
      model: modelConfig?.model,
      size: modelConfig?.size,
      quality: modelConfig?.quality,
      style: modelConfig?.style,
    }))
    .with('SDXL-3', () => ({
      prompt: useCase?.prompt?.prompt,
      modelId: useCase?.modelId,
      model: modelConfig?.model,
      size: modelConfig?.size || '1:1',
      negative_prompt: modelConfig?.negative_prompt || '',
      seed: modelConfig?.seed || 0,
    }))
    .with('IP-Adapter', () => ({
      modelId: useCase.processingAsset.id,
      prompt: useCase?.prompt?.prompt,
      file: watch('file'),
      negative_prompt: modelConfig.negative_prompt,
      controlType: modelConfig?.control_type || 'strong style and composition',
      control_module: modelConfig?.control_module || 'scribble',
      image: modelConfig?.original_image_url || null,
      refImage: modelConfig?.ref_image_url || null,
    }))
    .with('LivePortrait', () => ({
      modelId: useCase.processingAsset.id,
      image: watch('image'),
      video: watch('video'),
    }))
    .with('Midjourney', () => ({
      prompt: useCase?.prompt?.prompt,
      files: watch('files'),
    }))
    .otherwise(() => ({}));
};

export const transferBaseModel = (model: string, watch: any) => {
  return match(model)
    .with('Text2Image', () => baseText2Model)
    .with('SDXL', () => baseSdxlModel)
    .with('SDXL-3', () => baseSDXL3)
    .with('IP-Adapter', () => baseModelIpAdapter)
    .with('LivePortrait', () => baseModelLivePortrait)
    .with('ControlNet', () => ({ ...baseModel, file: watch('file') }))
    .with('Qr Art', () => ({ ...baseModelQRCode, file: watch('file') }))
    .with('Image2Anime', () => ({ ...baseModelAnime, file: watch('file') }))
    .with('Sticker', () => ({ ...baseModelSticker, file: watch('file') }))
    .with('Inpaint', () => ({ ...baseInpaintModel, image: watch('image') }))
    .with('DALL-E 2&3', () => ({ ...baseDalle2 }))
    .with('Midjourney', () => ({
      prompt: '',
      files: watch('files'),
    }))
    .otherwise(() => ({}));
};
