import { configs } from '@/configs';
import { api } from '@/services';

export function useConnectFreeAccount() {
  const connectFreeAcc = () => {
    const accessToken = configs.freeToken;
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  };

  return { connectFreeAcc };
}
