import { Button } from '@nextui-org/react';
import Image from 'next/image';
import React from 'react';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { toast } from 'react-toastify';

import { IconLightningPublish, ImageTempGenItem } from '@/assets';
import { Images } from '@/components';
import useContainedSizeImage from '@/hooks/useContainedSizeImage';
import type { PromptProviderState } from '@/provider';
import { usePrompt } from '@/provider';
import { useInpaintModel } from '@/provider/InpaintModelProvider';
import { useAuth } from '@/provider/UserProvider';
import { publishTemplate } from '@/services/usecase';

import InpaintControl from '../control/InpaintControl';
import { onClear, onRedo, onUndo } from '../helpers/inpaint';

const InpaintDisplay: React.FC<{
  imgSelect: PromptProviderState;
  canvasRef: any;
  backgroundRef: any;
}> = ({ canvasRef, backgroundRef, imgSelect }) => {
  const { isLogin } = useAuth();
  const { imageImport, brushRadius, setBrushRadius } = useInpaintModel();
  const { width, height } = useContainedSizeImage('image-load', Boolean(imageImport));
  const { state } = usePrompt();
  const img = state.find((item) => item.imageId === imgSelect.imageId);

  const isInitStep = !imageImport || img?.step === 1;
  const isPaintStep = Boolean(imageImport) && img?.step === -1;
  const isShowStep = !isInitStep && !isPaintStep;

  const publishAsset = async (id: number) => {
    const res = await publishTemplate([id]);
    if (res) {
      toast.success('Published successfully');
    }
  };
  return (
    <>
      <div
        aria-checked={isPaintStep}
        className="relative hidden h-full items-center justify-center bg-gray-100 p-6 px-[75px] aria-checked:flex sm:h-auto sm:flex-wrap sm:px-0"
      >
        <img id="image-load" src={imageImport} className="h-full w-full object-contain" alt="bg" />
        <div
          style={{ width, height }}
          className="absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] cursor-move opacity-70"
        >
          <ReactSketchCanvas
            ref={canvasRef}
            strokeWidth={brushRadius}
            strokeColor="#ffffff"
            canvasColor="transparent"
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
            }}
          />
          <canvas style={{ display: 'none' }} width={width} height={height} ref={backgroundRef} />
        </div>
        <div className="absolute right-0 top-0 z-[10] h-full w-[75px] p-4 sm:static sm:h-auto sm:w-full sm:p-0">
          <InpaintControl
            onClear={() => onClear(canvasRef.current)}
            onUndo={() => onUndo(canvasRef.current)}
            onRedo={() => onRedo(canvasRef.current)}
            onChangeRadius={setBrushRadius}
          />
        </div>
      </div>
      {isInitStep && (
        <div className="relative flex h-full flex-col items-center justify-center bg-gray-50 p-6">
          <Image
            className="mb-10 max-h-[325px] w-full max-w-[435px]"
            src={ImageTempGenItem}
            alt="bg"
          />
          <span className="text-14 font-semibold text-gray-400">
            Your generated artworks will show up here
          </span>
        </div>
      )}
      {isShowStep && (
        <div className="group relative flex h-full items-center justify-center bg-gray-100 p-6  md:p-0">
          <Images className="object-contain" src={img?.url} alt="bg" isFallbackLoading />
          <Button
            className="invisible absolute bottom-7 z-99 flex items-center rounded-[100px] bg-orange-500 py-[10px] pl-[20px] pr-[16px] group-hover:visible xl:visible"
            onClick={() => {
              isLogin
                ? img?.assetId && publishAsset(img.assetId)
                : toast.warning('You must login to publish images!');
            }}
          >
            <IconLightningPublish />
            <span className="text-15 font-semibold leading-[25.5px] text-white">Publish</span>
          </Button>
        </div>
      )}
    </>
  );
};

export default InpaintDisplay;
