import { useEffect, useState } from 'react';

import { useInpaintModel } from '@/provider';
import { getContainedSize, getDimensionImage } from '@/utils';

const useContainedSizeImage = (elementId: string, isReady: boolean) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const { setDimensions } = useInpaintModel();

  useEffect(() => {
    const imgLoad = document.getElementById(elementId);
    if (imgLoad && isReady) {
      imgLoad.addEventListener('load', function (e) {
        const imgTarget = e.target as HTMLImageElement;
        const [w, h] = imgTarget ? getContainedSize(imgTarget) : [0, 0];
        const dimensions = imgTarget ? getDimensionImage(imgTarget) : { width: 0, height: 0 };
        setWidth(w ?? 0);
        setHeight(h ?? 0);
        setDimensions(dimensions);
      });
    }
  }, [isReady]);

  return { width: Math.round(width), height: Math.round(height) };
};

export default useContainedSizeImage;
