import { Modal, ModalContent } from '@nextui-org/react';
import React from 'react';

import { WarningIcon } from '@/assets';
import { Buttons } from '@/components';
import { usePrompt } from '@/provider';

interface PopupValidateModelProps {}

const PopupValidateModel: React.FC<PopupValidateModelProps> = () => {
  const { validateFieldError, useModalValidate } = usePrompt();
  const { onOpenChange, isOpen } = useModalValidate;

  return (
    <Modal
      hideCloseButton
      placement="center"
      className="w-[446px] overflow-hidden overflow-y-auto sm:max-w-[95%]"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <ModalContent>
        {(onClose) => (
          <div className="w-full p-6">
            <h1 className="mb-4 flex items-center text-[20px] font-semibold">
              <WarningIcon className="mr-3 scale-150" /> Generate failed
            </h1>
            <div className="flex flex-col">
              {validateFieldError.errors.map((item) => (
                <p className="text-[16px] font-semibold text-gray-500">{item}</p>
              ))}
            </div>
            <div className="mt-6 flex w-full justify-end">
              <Buttons onClick={onClose} className="" typeBtn="primary">
                <span className="text-15 font-semibold text-white">ok</span>
              </Buttons>
            </div>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};

export default PopupValidateModel;
