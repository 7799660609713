import { Divider, Progress, ScrollShadow } from '@nextui-org/react';
import { useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'next/navigation';
import React, { memo, useEffect, useState } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

import { Buttons, UpLoadFileModel } from '@/components';
import ClientOnly from '@/components/client-only';
import { baseModelAnime } from '@/configs/constant';
import { generateTypeAnime } from '@/configs/optionsModel';
import AdvancedSetting from '@/contents/gen-model/sideBar/advanced-setting';
import BtnSubmit from '@/contents/gen-model/sideBar/command/BtnSubmit';
import PopupValidateModel from '@/contents/gen-model/sideBar/modal/PopupValidateModel';
import SuggestLora from '@/contents/gen-model/sideBar/modal/SuggestLora';
import type { PromptProviderState } from '@/provider';
import { usePrompt } from '@/provider';
import { useAuth } from '@/provider/UserProvider';
import { getUseCase } from '@/services/usecase';
import { getImageDimensions, isJSONReturn, useSQuery } from '@/utils';

import SkeletonGen from '../SkeletonGen';
import CampaignGen from './CampaignGen';

interface AnimeModelProps {
  isLoading: boolean;
  imgSelect: PromptProviderState;
  generateImg: (data: { [key: string]: any }) => void;
  createForm: UseFormReturn<FieldValues, any, undefined>;
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  campaigns?: any;
}

const AnimeModel: React.FC<AnimeModelProps> = ({
  isLoading,
  imgSelect,
  generateImg,
  createForm,
  setOptionsSelectStyle,
  campaigns,
}) => {
  const [file, setFile] = useState<File>();
  const { isLogin } = useAuth();
  const searchParams = useSearchParams();
  const { register, handleSubmit, watch, setValue, reset, getValues } = createForm;
  const { state, modelConfigRetry, validateFieldError, useModalValidate, tab } = usePrompt();
  const { onOpen } = useModalValidate;
  const queryClient = useQueryClient();
  const [modelConfigSate, setModelConfigSate] = useState<any>(null);
  const listLora: any = queryClient.getQueryData(['listLoras']);
  const img = state.find((item) => item.imageId === imgSelect.imageId);

  const { data: useCase } = useSQuery({
    queryKey: ['get-use-case-id', searchParams.get('template')],
    queryFn: () => getUseCase(Number(searchParams.get('template'))),
    enabled: Boolean(searchParams.get('template')) && !isNaN(Number(searchParams.get('template'))),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const handleChangeFile = (file: File) => {
    setValue('file', file);
    setFile(file);
  };

  const handleClearFile = () => {
    setValue('file', null);
  };

  const onSubmit = async (data: any) => {
    const { prompt, batch_size, ...rest } = data;
    const { file } = data;
    if (!prompt || !file) {
      validateFieldError.checkRequirePrompt(prompt);
      validateFieldError.checkRequireFile(file);
      return onOpen();
    }

    const { height, width } = await getImageDimensions(file);

    await generateImg({
      ...rest,
      prompt,
      height: height || 512,
      width: width || 512,
      batch_size: Number(batch_size),
    });
    setOptionsSelectStyle('');
  };

  useEffect(() => {
    if (img?.generateType && img.file) {
      setFile(img.file);
      setValue('generate_type', img?.generateType);
    } else if (!watch('campaignId')) {
      setValue('generate_type', 'CounterfeitV30_v30');
    }
  }, [img]);

  useEffect(() => {
    if (modelConfigSate) {
      const modelConfig = isJSONReturn(modelConfigSate?.processingAsset?.modelConfig);
      const loraConvert = modelConfig.lora
        ? modelConfig.lora.split(',').map((item: any) => item.split(':'))
        : '';
      const loras = loraConvert
        ? listLora?.data
            .filter((item: any) => loraConvert.some((lora: any) => lora[0] === item.alias))
            .map((lora: any) => ({
              ...lora,
              value: loraConvert.find((item: any) => item[0] === lora.alias)[1],
            }))
        : [];
      if (modelConfig.mode === 'upscale') {
        return setValue('prompt', modelConfigSate?.prompt?.prompt || '');
      }
      reset({
        modelId: modelConfigSate.modelId,
        lora: loras || [],
        control_weight: modelConfig.control_weight,
        model: modelConfig.model,
        prompt: modelConfigSate?.prompt?.prompt || '',
        negative_prompt: modelConfig.negative_prompt,
        width: modelConfig.width,
        height: modelConfig.height,
        batch_size: isLogin ? modelConfig.batch_size || 1 : 1,
      });
      if (modelConfigRetry) {
        queryClient.removeQueries({ queryKey: ['model-config-retry'] });
      }
    } else {
      reset(baseModelAnime);
    }
  }, [modelConfigSate, listLora]);

  useEffect(() => {
    if (useCase || modelConfigRetry) {
      setModelConfigSate(useCase || modelConfigRetry);
    }
  }, [useCase, modelConfigRetry]);

  return (
    <ClientOnly skeleton={<SkeletonGen />}>
      <form
        className="flex h-full flex-col justify-between md:w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <PopupValidateModel />
        <ScrollShadow
          aria-checked={tab === 'result'}
          size={10}
          hideScrollBar
          className="flex flex-col overflow-y-auto md:flex-row aria-checked:md:hidden sm:!flex-col"
        >
          <div className="flex flex-col md:mb-4 md:!w-full">
            <CampaignGen createForm={createForm} campaigns={campaigns} />
            <div className="mb-2 mt-5 flex items-center justify-between">
              <div className="text-14 font-semibold">
                Upload artwork file <span className="ml-1 text-red-600"> *</span>
              </div>
            </div>
            <div className="w-full">
              <UpLoadFileModel
                handleClearFile={handleClearFile}
                setFile={setFile}
                file={file}
                handleChange={handleChangeFile}
                required={false}
              />
            </div>
            <Divider className="my-5 w-full bg-gray-100" />
            <SuggestLora createForm={createForm} setOptionsSelectStyle={setOptionsSelectStyle} />
            <div className="md:!ml-0 md:!w-full [&>*:first-child]:px-0">
              {generateTypeAnime.AnimeOption.options.map((item, index) => (
                <AdvancedSetting
                  getValues={getValues}
                  setOptionsSelectStyle={setOptionsSelectStyle}
                  register={register}
                  watch={watch}
                  index={index}
                  item={item}
                  setValue={setValue}
                />
              ))}
            </div>
          </div>
        </ScrollShadow>
        <BtnSubmit>
          <div className="md:flex md:items-center md:justify-end">
            <Buttons
              disabled={isLoading || state.some((img) => img?.readyRun)}
              className="w-full p-0"
              type="submit"
            >
              <Progress
                disableAnimation
                value={img?.progress === '0%' ? 100 : Number(img?.progress.slice(0, -1))}
                isIndeterminate={isLoading || img?.readyRun}
                color="primary"
                label={<span className="text-white">Generate</span>}
                className="relative h-full"
                classNames={{
                  labelWrapper: 'contents z-9999',
                  base: 'h-full',
                  track: `h-full ${
                    isLoading || img?.progress !== '100%' || !watch('file') ? 'bg-disabled' : ''
                  }`,
                  label: ['absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-999'],
                }}
              />
            </Buttons>
          </div>
        </BtnSubmit>
      </form>
    </ClientOnly>
  );
};

export default memo(AnimeModel);
