import { Divider, Progress, ScrollShadow, useDisclosure } from '@nextui-org/react';
import { useQueryClient } from '@tanstack/react-query';
import mixpanel from 'mixpanel-browser';
import { useSearchParams } from 'next/navigation';
import React, { memo, useEffect, useState } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

import { IconCreditFlashGray, IconCreditFlashOrange, InfoGenIcon, UseCaseIcon } from '@/assets';
import { Buttons } from '@/components';
import ClientOnly from '@/components/client-only';
import TooltipCustom from '@/components/tooltip-custom';
import {
  baseDalle2,
  MIXPANEL_TRACK,
  sizeOfImageDallE2,
  sizeOfImageDallE3,
} from '@/configs/constant';
import BtnSubmit from '@/contents/gen-model/sideBar/command/BtnSubmit';
import SuggestPrompt from '@/contents/gen-model/sideBar/command/SuggestPrompt';
import PopupValidateModel from '@/contents/gen-model/sideBar/modal/PopupValidateModel';
import type { PromptProviderState } from '@/provider';
import { usePrompt } from '@/provider';
import { useAuth } from '@/provider/UserProvider';
import { getPlan } from '@/services/pricing';
import { getUseCase } from '@/services/usecase';
import { isJSONReturn, useSQuery } from '@/utils';

import SkeletonGen from '../SkeletonGen';
import CampaignGen from './CampaignGen';
import ModelGenOutOfCredits from './modal/ModelGenOutOfCredits';
import { iconOfRatio } from '@/contents/gen-model/sideBar/custom/OptionText2';

interface DalleModelProps {
  isLoading: boolean;
  imgSelect: PromptProviderState;
  generateImg: (data: any) => void;
  createForm: UseFormReturn<FieldValues, any, undefined>;
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  campaigns?: any;
  feeCredit: number;
  totalCredit: number;
}

const DalleModel: React.FC<DalleModelProps> = ({
  isLoading,
  imgSelect,
  generateImg,
  createForm,
  setOptionsSelectStyle,
  campaigns,
  feeCredit,
  totalCredit,
}) => {
  const { auth, setAuth, isLogin } = useAuth();
  const { state, modelConfigRetry, validateFieldError, useModalValidate, tab } = usePrompt();
  const { onOpen } = useModalValidate;
  const [prompt, setPrompt] = useState(imgSelect.prompt);
  const queryClient = useQueryClient();
  const [modelConfigSate, setModelConfigSate] = useState<any>(null);
  const { register, handleSubmit, watch, setValue, reset } = createForm;
  const searchParams = useSearchParams();
  const { isOpen, onOpen: onOpenModelOutOfCredit, onOpenChange } = useDisclosure();

  const { data: useCase } = useSQuery({
    queryKey: ['get-use-case-id', searchParams.get('template')],
    queryFn: () => getUseCase(Number(searchParams.get('template'))),
    enabled: Boolean(searchParams.get('template')) && !isNaN(Number(searchParams.get('template'))),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const img = state.find((item) => item.imageId === imgSelect.imageId);

  const onSubmit = async (data: any) => {
    if (totalCredit < feeCredit) {
      onOpenModelOutOfCredit();
    } else {
      const { prompt } = data;
      if (!prompt) {
        validateFieldError.checkRequirePrompt(prompt);
        return onOpen();
      }
      mixpanel.track(MIXPANEL_TRACK.GENERATE_RUN);
      await generateImg(data);
    }
  };

  const handleGetCredit = async () => {
    try {
      const res = await getPlan();

      if (res && res.credit !== undefined) {
        setAuth({ ...auth, user: { ...auth.user, credit: res?.credit } });
      } else {
        console.error('Invalid response format from getPlan');
      }
    } catch (error) {
      console.error('Error fetching credit');
    }
  };

  useEffect(() => {
    if (isLogin) {
      handleGetCredit();
    }
  }, [img?.progress, isLogin]);

  const handleSwitchModelDalle = (modelName: string) => {
    setValue('model', modelName);
    if (modelName === 'dall-e-2') {
      // reset(baseDalle2);
      setValue('prompt', prompt);
      setValue('modelId', 0);
      setValue('model', 'dall-e-2');
      setValue('size', '1024x1024');
    } else {
      // reset(baseDalle3);
      setValue('prompt', prompt);
      setValue('modelId', 0);
      setValue('model', 'dall-e-3');
      setValue('quality', 'standard');
      setValue('size', '1024x1024');
      setValue('style', 'vivid');
    }
  };

  useEffect(() => {
    setPrompt(imgSelect.prompt);
    setValue('prompt', state.find((item) => item.imageId === imgSelect.imageId)?.prompt);
  }, [imgSelect]);

  useEffect(() => {
    if (modelConfigSate) {
      const modelConfig = isJSONReturn(modelConfigSate?.processingAsset?.modelConfig);
      reset({
        prompt: modelConfigSate?.prompt?.prompt,
        modelId: modelConfigSate?.modelId,
        model:
          modelConfig?.model !== 'dall-e-2' && modelConfig?.model !== 'dall-e-3'
            ? 'dall-e-2'
            : modelConfig?.model,
        size: '1024x1024',
        quality: modelConfig?.quality || '',
        style: modelConfig?.style || '',
      });
      if (modelConfigRetry) {
        queryClient.removeQueries({ queryKey: ['model-config-retry'] });
      }
    } else if (!watch('campaignId')) {
      reset(baseDalle2);
    }
  }, [modelConfigSate]);

  useEffect(() => {
    if (useCase || modelConfigRetry) {
      setModelConfigSate(useCase || modelConfigRetry);
    }
  }, [useCase, modelConfigRetry]);

  return (
    <>
      <ClientOnly skeleton={<SkeletonGen />}>
        <form
          className="flex h-full flex-col justify-between md:w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <PopupValidateModel />
          <ScrollShadow
            aria-checked={tab === 'result'}
            size={10}
            hideScrollBar
            className="flex-1 overflow-y-auto scrollbar-hide md:flex md:justify-start aria-checked:md:hidden sm:flex-col"
          >
            <div className="md:mb-2 md:!w-full">
              <CampaignGen createForm={createForm} campaigns={campaigns} />
              <div className="mb-2  mt-5 flex items-center justify-between">
                <div className="text-14 font-semibold">
                  Prompt
                  <span className="text-primary">*</span>
                </div>
                <div
                  onClick={() => setOptionsSelectStyle('use-case')}
                  className="flex cursor-pointer items-center text-13 font-semibold text-primary"
                >
                  <UseCaseIcon className="mr-[6px]" /> Template
                </div>
              </div>
              <SuggestPrompt createForm={{ watch, setValue }}>
                <textarea
                  defaultValue=""
                  className="min-h-[149px] w-full resize-none rounded-[16px] border-none bg-gray-50 px-4 py-[14px] text-black outline-none"
                  placeholder="Write a detailed description of how you want your artwork to look like or select from My prompts, or both"
                  {...register('prompt', {
                    onChange: (e) => setPrompt(e.target.value),
                  })}
                />
              </SuggestPrompt>
              <Divider className="my-5 w-full bg-gray-100" />
              <div className="md:!w-full [&>*:first-child]:px-0">
                <div>
                  <div className="mb-2">
                    <span className="flex items-center text-14 font-semibold">
                      Model
                      <TooltipCustom
                        showArrow
                        content={
                          <span className="max-w-[370px] text-13 font-normal text-white">
                            The model to use for image generation.
                          </span>
                        }
                        classNames={{ base: 'tooltip', arrow: 'arrow-tooltip' }}
                      >
                        <span className="ml-2">
                          <InfoGenIcon />
                        </span>
                      </TooltipCustom>
                    </span>
                  </div>
                  <div className="mb-4 flex flex-wrap items-center gap-2">
                    {['dall-e-2', 'dall-e-3'].map((item, index) => (
                      <span
                        aria-expanded={watch('model') === item}
                        className="cursor-pointer rounded-full border border-gray-100 px-3 py-[6px] text-13 font-medium text-gray-500 aria-expanded:border-black aria-expanded:text-black"
                        key={index}
                        onClick={() => handleSwitchModelDalle(item)}
                      >
                        {item}
                      </span>
                    ))}
                  </div>
                  {watch('model') === 'dall-e-3' && (
                    <>
                      <Divider className="mb-4 w-full bg-gray-100" />
                      <div className="mb-2">
                        <span className="flex items-center text-14 font-semibold">
                          Quality
                          <TooltipCustom
                            showArrow
                            content={
                              <span className="max-w-[370px] text-13 font-normal text-white">
                                The quality of the image that will be generated. hd creates images
                                with finer details and greater consistency across the image.
                              </span>
                            }
                            classNames={{ base: 'tooltip', arrow: 'arrow-tooltip' }}
                          >
                            <span className="ml-2">
                              <InfoGenIcon />
                            </span>
                          </TooltipCustom>
                        </span>
                      </div>
                      <div className="mb-4 flex flex-wrap items-center gap-2">
                        {['standard', 'hd'].map((item, index) => (
                          <span
                            aria-expanded={watch('quality') === item}
                            className="cursor-pointer rounded-full border border-gray-100 px-3 py-[6px] text-13 font-medium text-gray-500 aria-expanded:border-black aria-expanded:text-black"
                            key={index}
                            onClick={() => setValue('quality', item)}
                          >
                            {item}
                          </span>
                        ))}
                      </div>
                    </>
                  )}
                  <Divider className="my-4 w-full bg-gray-100" />
                  <div className="mb-2">
                    <span className="flex items-center text-14 font-semibold">
                      Size
                      <TooltipCustom
                        showArrow
                        content={
                          <span className="max-w-[370px] text-13 font-normal text-white">
                            The size of the generated images.
                          </span>
                        }
                        classNames={{ base: 'tooltip', arrow: 'arrow-tooltip' }}
                      >
                        <span className="ml-2">
                          <InfoGenIcon />
                        </span>
                      </TooltipCustom>
                    </span>
                  </div>
                  <div className="mb-4 grid grid-cols-3 gap-2">
                    {(watch('model') === 'dall-e-2' ? sizeOfImageDallE2 : sizeOfImageDallE3).map(
                      (item, index) => (
                        <div
                          key={index}
                          aria-expanded={watch('size') === item.text}
                          className="flex cursor-pointer flex-col items-center rounded-[12px] border bg-gray-50 px-2 py-3 aria-expanded:border-black"
                          onClick={() => setValue('size', item.text)}
                        >
                          <span className="mb-2 text-center">{iconOfRatio[item.ratio]}</span>
                          <span className="text-12 font-semibold">{item.shape}</span>
                          <span className="text-[10px] font-normal text-gray-500">{item.text}</span>
                        </div>
                      ),
                    )}
                  </div>
                  <Divider className="my-4 w-full bg-gray-100" />
                  {watch('model') === 'dall-e-3' && (
                    <>
                      <div className="mb-2">
                        <span className="flex items-center text-14 font-semibold">
                          Style
                          <TooltipCustom
                            showArrow
                            content={
                              <span className="max-w-[370px] text-13 font-normal text-white">
                                The style of the generated images. Must be one of vivid or natural.
                                Vivid causes the model to lean towards generating hyper-real and
                                dramatic images. Natural causes the model to produce more natural,
                                less hyper-real looking images.
                              </span>
                            }
                            classNames={{ base: 'tooltip', arrow: 'arrow-tooltip' }}
                          >
                            <span className="ml-2">
                              <InfoGenIcon />
                            </span>
                          </TooltipCustom>
                        </span>
                      </div>
                      <div className="mb-4 flex flex-wrap items-center gap-2">
                        {['vivid', 'natural'].map((item, index) => (
                          <span
                            aria-expanded={watch('style') === item}
                            className="cursor-pointer rounded-full border border-gray-100 px-3 py-[6px] text-13 font-medium text-gray-500 aria-expanded:border-black aria-expanded:text-black"
                            key={index}
                            onClick={() => setValue('style', item)}
                          >
                            {item}
                          </span>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </ScrollShadow>

          <BtnSubmit>
            <div className="md:flex md:flex-col md:items-start">
              <div className="mb-3 flex items-center justify-between text-14 text-gray-500 md:mb-1 md:flex-col md:items-start md:justify-normal">
                {isLogin ? (
                  <p className="flex items-center text-14 font-normal text-gray-500">
                    Your available credits: {totalCredit} <IconCreditFlashGray />
                    {/* <ModelRequestNotificationLimit /> */}
                  </p>
                ) : (
                  <p className="flex items-center text-16 font-semibold text-orange-500 md:m-0 md:mr-4 md:text-13">
                    You must login to use model PLUS
                  </p>
                )}
                <p className="flex items-center gap-1">
                  Fee:
                  <span className="flex items-center text-orange-500">
                    {feeCredit.toString() !== '0.0' ? `-${feeCredit}` : '0'}
                    <IconCreditFlashOrange />
                  </span>{' '}
                </p>
              </div>

              {isLogin ? (
                <Buttons
                  disabled={isLoading || state.some((img) => img?.readyRun)}
                  className="w-full p-0"
                  type="submit"
                >
                  <Progress
                    disableAnimation
                    value={img?.progress === '0%' ? 100 : Number(img?.progress.slice(0, -1))}
                    isIndeterminate={isLoading || img?.readyRun}
                    color="primary"
                    label={<span className="text-white">Generate</span>}
                    className="relative h-full"
                    classNames={{
                      labelWrapper: 'contents z-9999',
                      base: 'h-full',
                      track: `h-full ${
                        isLoading || img?.progress !== '100%' || !watch('prompt')
                          ? 'bg-disabled'
                          : ''
                      }`,
                      label: ['absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-999'],
                    }}
                  />
                </Buttons>
              ) : (
                <Buttons className="w-full p-0" typeBtn="disabled" disabled>
                  <span className="text-white">Generate</span>
                </Buttons>
              )}
            </div>
          </BtnSubmit>
        </form>
      </ClientOnly>
      {isLogin && <ModelGenOutOfCredits isOpen={isOpen} onOpenChange={onOpenChange} />}
    </>
  );
};

export default memo(DalleModel);
