import { Input } from '@nextui-org/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { HeaderSearchIcon, IconCheckWhite, IconLeft } from '@/assets';
import { Images } from '@/components';
import NsfwMark from '@/components/NsfwMark';
import { transferBaseModel, transferUseCase } from '@/contents/gen-model/helpers/usecase';
import { getUseCases } from '@/services/usecase';
import type { Models } from '@/types';
import { isJSONReturn, useSQuery } from '@/utils';

interface SelectUseCaseProps {
  optionsSelectStyle: string;
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  modelDetail: Models;
  createForm: UseFormReturn<FieldValues, any, undefined>;
}

const SelectUseCase: React.FC<SelectUseCaseProps> = ({
  setOptionsSelectStyle,
  optionsSelectStyle,
  modelDetail,
  createForm,
}) => {
  const [selectItem, setSelectItem] = useState<any>({ id: '' });
  const [name, setName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [listUsecase, setListUsecase] = useState<any[]>([]);
  const { reset, watch } = createForm;
  const queryClient = useQueryClient();
  const listLora: any = queryClient.getQueryData(['listLoras']);
  const campaignId = watch('campaignId');

  const {
    data: useCases,
    refetch: refetchUsecase,
    isLoading: isLoadingUseCases,
  } = useSQuery({
    queryKey: ['get-use-case-model', modelDetail.id, name],
    queryFn: () =>
      getUseCases({
        filter: `{"modelId":${modelDetail.id},"name":"${name}"}`,
        size: 30,
        offset: (currentPage - 1) * 30,
      }),
    enabled: Boolean(optionsSelectStyle) && optionsSelectStyle === 'use-case',
    keepPreviousData: true,
    staleTime: 0,
  });

  const useCase =
    useCases &&
    useCases.items
      .filter(
        (useCase: any) =>
          useCase?.processingAsset?.modelConfig &&
          (useCase?.processingAsset?.modelConfig !== '{}' || modelDetail.type === 'Midjourney'),
      )
      .map((useCase: any) => ({
        ...useCase,
        processingAsset: {
          ...useCase.processingAsset,
          modelConfig: isJSONReturn(useCase?.processingAsset?.modelConfig),
        },
      }));

  const handleSelect = (item: any) => {
    if (item.id === selectItem.id) {
      setSelectItem('');
      reset({
        ...transferBaseModel(modelDetail.type, watch),
        ...(campaignId && { campaignId }),
      });
    } else {
      const { modelConfig } = item.processingAsset;
      setSelectItem(item);
      reset({
        ...transferUseCase(modelDetail.type, item, modelConfig, watch, listLora),
        ...(campaignId && { campaignId }),
      });
    }
  };

  useEffect(() => {
    if (listUsecase.length > 1) {
      refetchUsecase().then((data: any) =>
        setListUsecase((useCases: any) => [
          ...useCases,
          ...(data?.data?.items
            .filter(
              (useCase: any) =>
                useCase?.processingAsset?.modelConfig &&
                (useCase?.processingAsset?.modelConfig !== '{}' ||
                  modelDetail.type === 'Midjourney'),
            )
            .map((useCase: any) => ({
              ...useCase,
              processingAsset: {
                ...useCase.processingAsset,
                modelConfig: isJSONReturn(useCase?.processingAsset?.modelConfig),
              },
            })) || []),
        ]),
      );
    }
  }, [currentPage]);

  useEffect(() => {
    if (listUsecase.length < 1 && useCase) {
      setListUsecase(useCase);
    }
  }, [useCase]);

  return (
    <div className="h-full">
      <div className="flex items-center justify-between border-b-1 border-gray-100 p-6 sm:p-4">
        <h2
          onClick={() => setOptionsSelectStyle('')}
          className="relative flex cursor-pointer items-center whitespace-nowrap text-18 font-semibold lg:text-14 sm:pl-[14px]"
        >
          <IconLeft className="mr-2 lg:mr-1 lg:scale-75 sm:absolute sm:left-[-10px]" />{' '}
          <span>Template</span>
        </h2>
        <div>
          <Input
            classNames={{
              input: ['dark:placeholder:text-white font-normal text-14 pl-1', 'rounded-[100px]'],
              innerWrapper: ['bg-transparent', 'rounded-[100px]'],
              inputWrapper: ['bg-gray-50', 'rounded-[100px]', 'w-[320px] lg:w-[180px]'],
            }}
            startContent={<HeaderSearchIcon />}
            placeholder="Search by name"
            onValueChange={(e) => setName(e)}
          />
        </div>
      </div>
      <div
        className="scrollbar-custom !h-[calc(100%-89px)] overflow-y-auto [&>div]:!h-full"
        id="div-list-useCase"
      >
        {listUsecase && (
          <InfiniteScroll
            style={{ overflow: 'visible' }}
            dataLength={listUsecase?.length}
            next={() => {
              if (!isLoadingUseCases) setCurrentPage(currentPage + 1);
            }}
            hasMore
            loader=""
            className="!h-full"
            scrollableTarget="div-list-useCase"
          >
            <ResponsiveMasonry
              className="p-6 sm:p-4"
              columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 4, 1200: 6 }}
            >
              <Masonry gutter="12px">
                {listUsecase.map((item: any) => (
                  <div
                    onClick={() => {
                      handleSelect(item);
                      setOptionsSelectStyle('');
                    }}
                    key={item}
                    className="relative flex h-fit cursor-pointer flex-col rounded-[16px] bg-gray-50 p-2"
                  >
                    <div className="relative mb-1 w-full">
                      <Images
                        imageWrapper="w-full h-[180px]"
                        className="h-auto w-full rounded-[10px] object-contain object-center"
                        src={item.processingAsset.url}
                        alt={item.processingAsset.url}
                        isFallbackLoading
                        skeleton
                      />
                      {item.processingAsset.isNsfw && (
                        <NsfwMark
                          className="overflow-hidden rounded-[10px]"
                          url={item.processingAsset.url}
                        />
                      )}
                    </div>
                    {selectItem.id === item.id && (
                      <div className="absolute right-[5px] top-[5px] z-10 flex h-7 w-7 items-center justify-center rounded-[50%] bg-green-500">
                        <IconCheckWhite />
                      </div>
                    )}
                    <span className="line-clamp-1 break-words text-13 font-semibold">
                      {item.name}
                    </span>
                    <span className="line-clamp-2 break-words text-12 font-normal text-gray-500">
                      {item.description}
                    </span>
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default SelectUseCase;
