import { Button } from '@nextui-org/react';
import React from 'react';

import { InfoGenIcon } from '@/assets';
import type { PromptProviderState } from '@/provider';
import { usePrompt } from '@/provider';
import TooltipCustom from '@/components/tooltip-custom';

interface ItemOpsUVProps {
  // 'V1' | 'V2' | 'V3' | 'V4' | 'U1' | 'U2' | 'U3' | 'U4';
  customId: any;
  className: string;
}

interface OpsUVProps {
  img: PromptProviderState;
  customImgCallback: (
    customId: 'V1' | 'V2' | 'V3' | 'V4' | 'U1' | 'U2' | 'U3' | 'U4',
    prompt: string,
    tool: string,
  ) => void;
}

const OpsUV: React.FC<OpsUVProps> = ({ img, customImgCallback }) => {
  const ItemOpsUV = ({ customId, className = '' }: ItemOpsUVProps) => {
    const { setState, state } = usePrompt();
    const customImg = (customId: any) => {
      setState((prev) =>
        prev.map((item) =>
          item.imageId === img?.imageId
            ? {
                ...item,
                usedOptions: [...(item?.usedOptions?.length ? item.usedOptions : []), customId],
              }
            : item,
        ),
      );
      customImgCallback(customId, img?.prompt ?? '', img?.tool ?? '');
    };

    return (
      <TooltipCustom
        delay={1500}
        showArrow
        content={
          <span className="max-w-[370px] text-13 font-normal text-white">
            {customId.slice(0, 1) === 'U'
              ? ' Use the U1 U2 U3 U4 buttons to separate your selected image. You can then use the Upscale (2x) or Upscale (4x) tools to increase the size of your image.'
              : 'Create subtle or strong variations of a generated image using the the V1 V2 V3 V4 buttons.'}
          </span>
        }
        classNames={{ base: 'tooltip', arrow: 'arrow-tooltip' }}
      >
        <Button
          onClick={() => customImg(customId)}
          aria-checked={
            (img?.customId && img.readyRun && img.customId === customId) ||
            img?.usedOptions?.includes(customId)
          }
          className={`mr-2 flex min-w-0 justify-center rounded-full bg-gray-100 px-3 py-[10px] aria-checked:bg-green-400 aria-checked:text-white ${className}`}
          disabled={
            img?.readyRun ||
            state.some((img) => img?.readyRun) ||
            img?.usedOptions?.includes(customId)
          }
        >
          <span className="rounded-[100px] text-14 font-semibold">{customId}</span>
        </Button>
      </TooltipCustom>
    );
  };

  return (
    <div className="mb-4 md:mt-4">
      <div className="mb-4 flex items-start gap-2 text-13 font-normal text-gray-400">
        <InfoGenIcon />
        <span>These options are for this current image only</span>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex w-full justify-between">
          {['V1', 'V2', 'V3', 'V4'].map((customId, index) => (
            <>
              <ItemOpsUV
                key={index}
                className={index === 3 ? 'mx-0 !w-1/4' : ' !w-1/4'}
                customId={customId}
              />
            </>
          ))}
        </div>
        <div className="flex w-full justify-between">
          {['U1', 'U2', 'U3', 'U4'].map((customId, index) => (
            <>
              <ItemOpsUV
                key={index}
                className={index === 3 ? 'mx-0 !w-1/4' : ' !w-1/4'}
                customId={customId}
              />
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OpsUV;
