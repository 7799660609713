'use client';

import { Button, useDisclosure } from '@nextui-org/react';
import { debounce } from 'lodash';
import mixpanel from 'mixpanel-browser';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { ReactSketchCanvasRef } from 'react-sketch-canvas';
import { toast } from 'react-toastify';
import { match } from 'ts-pattern';

import { IconTrash22x22, IconTrashBlack22x22 } from '@/assets';
import { Buttons } from '@/components';
import { MIXPANEL_TRACK } from '@/configs/constant';
import ModelControlImg from '@/contents/gen-model/control/ModelControlImg';
import { ImageMidjourneyRightBar } from '@/contents/gen-model/rightBar';
import {
  AirightModel,
  ControlNetModel,
  ImageMidjourneyModel,
  InpaintingModel,
  QRCodeModel,
  StickerModel,
  Text2Image,
} from '@/contents/gen-model/sideBar';
import AnimeModel from '@/contents/gen-model/sideBar/AnimeModel';
import DalleModel from '@/contents/gen-model/sideBar/DalleModel';
import IpAdapter from '@/contents/gen-model/sideBar/IpAdapter';
import SDXL3 from '@/contents/gen-model/sideBar/SDXL3';
import SDXLModel from '@/contents/gen-model/sideBar/SDXLModel';
import { useConnectFreeAccount } from '@/hooks/useConnectFreeAccount';
import { initialState, PromptProviderState, useHistoryState, usePrompt } from '@/provider';
import { useRouter } from '@/provider/RouterProvider';
import { useAuth } from '@/provider/UserProvider';
import { generateVideoLivePortrait, IMultiMintAsset, IUpdateMultiTags } from '@/services';
import {
  blendImageMidjourneyApi,
  calculateCreditInModel,
  createImageMidjourney,
  generateControlNet,
  generateDalle,
  generateFileOrigin,
  generateImgIpAdapter,
  generateInpainting,
  generateModelAnime,
  generateModelQRCode,
  generateSdxl,
  generateSdxl3,
  generateSticker,
  generateText2Image,
  getGenerateText2Image,
  getImageMidjourney,
  getListLoras,
  getListLorasSdxl,
  getListMode,
  getListModelInpaint,
  getListModelSdxl,
  getProcessingAssetByIds,
  multiMintAssets,
  postImageMidjourney,
  updateMultiTags,
  upscaleImageMidjourney,
  upscaleText2Image,
  variationImageMidjourney,
} from '@/services';
import type { ModelLoraDetail, Models, ResModels } from '@/types';
import {
  checkParamExpect,
  comparisonObject,
  detectBannedWords,
  isJSON,
  SSocket,
  testPreventSubmitLink,
  useSMutation,
  useSQuery,
} from '@/utils';

import Feed from './contentFeed';
import { checkPassRuleWithPlan } from './helpers/plans';
import RuleWithPlan from './sideBar/modal/RuleWithPlan';
import MainHistory from '@/contents/gen-model/contentFeed/MainHistory';
import LivePortraitModel from '@/contents/gen-model/sideBar/LivePortraitModel';

interface GenModelProps {
  modelDetail: Models;
  campaigns?: any;
  models: ResModels;
  isRemix?: boolean;
  setSateModelDetail?: React.Dispatch<React.SetStateAction<Models | undefined>>;
  modelLoraSelected?: ModelLoraDetail;
}

const GenModel: React.FC<GenModelProps> = ({
  modelDetail,
  campaigns,
  models,
  isRemix,
  setSateModelDetail,
  modelLoraSelected,
}) => {
  const { auth, isLogin, setAuth, isLoginClient } = useAuth();
  const {
    state,
    setState,
    tab,
    setTab,
    resetIsSelect,
    idAssetIsSelected,
    deleteAsset,
    publishAsset,
    setIdAssetIsSelected,
    useModalControl,
    dataTotalCredit,
    refetchGetPlan,
    userPlan,
  } = usePrompt();
  const { setImgSelect, imgSelect } = useHistoryState();
  const router = useRouter();
  const createForm = useForm();
  const { setValue } = createForm;
  const { onOpen, onOpenChange, isOpen } = useModalControl;
  const {
    onOpen: onOpenUpgrade,
    isOpen: isOpenUpgrade,
    onOpenChange: onOpenChangeUpgrade,
  } = useDisclosure();

  const myInterval = useRef<any>();
  const canvasRef = useRef<ReactSketchCanvasRef>(null);
  const backgroundRef = useRef<HTMLCanvasElement>(null);

  const { connectFreeAcc } = useConnectFreeAccount();

  const { data: promptCache } = useSQuery({ queryKey: ['promptCache'], initialData: '' });
  const [queueImg, setQueueImg] = useState<number[]>([]);
  const [error, setError] = useState<any>('');
  const [idGenerateText2Img, setIdGenerateText2Img] = useState('');
  const [arrayIdGenerateText2Img, setArrayIdGenerateText2Img] = useState([]);
  const [promptGenerateText2Img, setPromptGenerateText2Img] = useState('');
  const [optionsSelectStyle, setOptionsSelectStyle] = useState('');

  const campaignId = Number(createForm.getValues(['campaignId']));
  const [autoMintArr, setAutoMintArr] = useState<number[]>([0]);

  const isNotModelSpecial =
    modelDetail.type !== 'Midjourney' &&
    modelDetail.type !== 'Text2Image' &&
    modelDetail.type !== 'ControlNet' &&
    modelDetail.type !== 'Image2Anime' &&
    modelDetail.type !== 'DALL-E 2&3' &&
    modelDetail.type !== 'Inpaint' &&
    modelDetail.type !== 'SDXL' &&
    modelDetail.type !== 'Sticker' &&
    modelDetail.type !== 'Qr Art' &&
    modelDetail.type !== 'SDXL-3' &&
    modelDetail.type !== 'IP-Adapter' &&
    modelDetail.type !== 'LivePortrait';

  const fieldNameModel = match(modelDetail.type)
    .with('ControlNet', () => 'generate_type')
    .otherwise(() => 'model');

  useSQuery({
    queryKey: ['listLoras'],
    queryFn: getListLoras,
    keepPreviousData: true,
    staleTime: 0,
    enabled:
      modelDetail.type !== 'SDXL' &&
      modelDetail.type !== 'Midjourney' &&
      modelDetail.type !== 'DALL-E 2&3' &&
      modelDetail.type !== 'SDXL-3',
  });

  useSQuery({
    queryKey: ['listLoras'],
    queryFn: getListLorasSdxl,
    keepPreviousData: true,
    staleTime: 0,
    enabled: modelDetail.type === 'SDXL',
  });

  useSQuery({
    queryKey: ['listMode'],
    queryFn: getListMode,
    keepPreviousData: true,
    staleTime: 0,
    enabled:
      modelDetail.type !== 'SDXL' &&
      modelDetail.type !== 'DALL-E 2&3' &&
      modelDetail.type !== 'Midjourney' &&
      modelDetail.type !== 'Inpaint',
  });

  useSQuery({
    queryKey: ['listMode'],
    queryFn: getListModelInpaint,
    keepPreviousData: true,
    staleTime: 0,
    enabled: modelDetail.type === 'Inpaint',
  });

  useSQuery({
    queryKey: ['listMode'],
    queryFn: getListModelSdxl,
    keepPreviousData: true,
    staleTime: 0,
    enabled: modelDetail.type === 'SDXL',
  });

  const { mutate: mutateGenerate, isLoading } = useSMutation({
    mutationFn: createImageMidjourney,
  });
  const { mutate: mutateBlendImageMidjourney, isLoading: isLoadingBlendImageMidjourney } =
    useSMutation({
      mutationFn: blendImageMidjourneyApi,
    });
  const { mutate: mutateVariation, isLoading: isLoadingVariation } = useSMutation({
    mutationFn: variationImageMidjourney,
  });
  const { mutate: mutateUpscale, isLoading: isLoadingUpscale } = useSMutation({
    mutationFn: upscaleImageMidjourney,
  });
  const { mutate: mutatePostImageMidjourney, data: resDataPostIdMidjourney } = useSMutation({
    mutationFn: postImageMidjourney,
  });

  const { mutate: mutateGenerateText2Image, isLoading: isLoadingText2 } = useSMutation({
    mutationFn: generateText2Image,
  });

  const { mutate: mutateGenerateIpAdapter, isLoading: isLoadingIpAdapter } = useSMutation({
    mutationFn: generateImgIpAdapter,
  });

  const { mutate: mutateGenerateLivePortrait, isLoading: isLoadingLivePortrait } = useSMutation({
    mutationFn: generateVideoLivePortrait,
  });

  const { mutate: mutateUpscaleText2Image, isLoading: isLoadingText2UpscaleText2Image } =
    useSMutation({
      mutationFn: upscaleText2Image,
    });

  const { mutate: mutateGenerateSdxl, isLoading: isLoadingSdxl } = useSMutation({
    mutationFn: generateSdxl,
  });

  const { mutate: mutateGenerateSdxl3, isLoading: isLoadingSdxl3 } = useSMutation({
    mutationFn: generateSdxl3,
  });

  const { mutate: mutateGenerateInpainting, isLoading: isLoadingInpaiting } = useSMutation({
    mutationFn: generateInpainting,
  });

  const { mutate: mutateGenerateDalle, isLoading: isLoadingDalle } = useSMutation({
    mutationFn: generateDalle,
  });

  const { mutate: mutateGenerateControlNet, isLoading: isLoadingControl } = useSMutation({
    mutationFn: generateControlNet,
  });

  const { mutate: mutateGenerateQRCode, isLoading: isLoadingQRCode } = useSMutation({
    mutationFn: generateModelQRCode,
  });

  const { mutate: mutateGenerateAnime, isLoading: isLoadingAnime } = useSMutation({
    mutationFn: generateModelAnime,
  });

  const { mutate: mutateGenerateSticker, isLoading: isLoadingSticker } = useSMutation({
    mutationFn: generateSticker,
  });

  const { mutate: mutateGenerateFileOrigin } = useSMutation({
    mutationFn: generateFileOrigin,
  });

  const autoMint = async (processId: number, tag: string) => {
    const inputMintQueue: IMultiMintAsset[] = [
      {
        id: processId,
        details: {
          royalty: 5,
        },
      },
    ];

    const res = await multiMintAssets(inputMintQueue);

    if (res) {
      const assetId = res?.data?.processing_asset[0].tokenId;
      const inputTag: IUpdateMultiTags[] = [
        {
          assetId,
          tags: [{ content: tag }],
        },
      ];
      await updateMultiTags(inputTag);
    }
  };

  const generateImg = (prompt: string, tool = '') => {
    const campaignId = createForm.getValues(['campaignId']);
    if (isLoading) return;
    prompt = prompt.trim();

    if (error && comparisonObject(error, prompt)) return;
    setError('');

    if (
      testPreventSubmitLink(prompt) ||
      detectBannedWords(prompt).length > 0 ||
      checkParamExpect(prompt)
    ) {
      setError(prompt);
      return;
    }

    if (prompt.trim().slice(0, 2) === '--') {
      return toast.error('You must enter the prompt!');
    }

    mutateGenerate(
      {
        prompt: tool ? `${prompt} ${tool}` : prompt,
        modelId: modelDetail.id,
        campaignId: Number(campaignId),
      },
      {
        onSuccess(data) {
          setOptionsSelectStyle('');
          const { imageId, assetId } = data.data;
          const tempImg = {
            assetId,
            imageId,
            step: 1,
            prompt,
            done: false,
            progress: '0%',
            url: '',
            readyRun: true,
            customId: null,
            tool,
            createdAt: new Date().toISOString(),
          };
          if (state[0]?.imageId !== 0) {
            setState([tempImg, ...state]);
          } else {
            setState([tempImg]);
          }
          setImgSelect(tempImg);
          setQueueImg([...queueImg, imageId]);
        },
      },
    );
  };

  const blendImageMidjourney = (fileList: File[]) => {
    if (isLoadingBlendImageMidjourney) return;
    const campaignId = createForm.getValues(['campaignId']);

    const bodyFormData = new FormData();
    const modelId: any = modelDetail?.id;

    for (let i = 0; i < fileList.length; i++) {
      const file: any = fileList[i];
      bodyFormData.append(`file${i + 1}`, file);
    }

    bodyFormData.append('modelId', modelId);
    if (campaignId) {
      const Id = campaignId?.toString();
      bodyFormData.append('campaignId', Id);
    }
    mutateBlendImageMidjourney(bodyFormData, {
      onSuccess(data) {
        const { imageId, assetId } = data.data;
        const tempImg = {
          assetId,
          imageId,
          step: 1,
          prompt: '',
          done: false,
          progress: '0%',
          url: '',
          readyRun: true,
          customId: null,
          createdAt: new Date().toISOString(),
        };
        if (state[0]?.imageId !== 0) {
          setState([tempImg, ...state]);
        } else {
          setState([tempImg]);
        }
        setImgSelect(tempImg);
        setQueueImg([...queueImg, imageId]);
      },
    });
  };

  const customImgCallback = (
    customId: 'V1' | 'V2' | 'V3' | 'V4' | 'U1' | 'U2' | 'U3' | 'U4',
    prompt: string,
    tool: string,
  ) => {
    if (testPreventSubmitLink(prompt)) {
      return;
    }
    const queryParams = {
      assetId: Number(imgSelect.assetId),
      flags: 0,
      custom: customId,
    };

    const tempState = (imageId: number, assetId: number) => ({
      assetId,
      imageId,
      step: 1,
      prompt,
      done: false,
      progress: '0%',
      url: '',
      readyRun: true,
      customId,
      tool,
      createdAt: new Date().toISOString(),
    });

    const setNewCustomId = (imageId: number, assetId: number) =>
      setState((prev) => [tempState(imageId, assetId), ...prev]);
    if (customId.slice(0, 1) === 'V') {
      mutateVariation(queryParams, {
        onSuccess(data) {
          const { imageId, assetId } = data.data;
          setNewCustomId(imageId, assetId);
          setQueueImg([...queueImg, imageId]);
          setImgSelect(tempState(imageId, assetId));
        },
      });
    } else {
      mutateUpscale(queryParams, {
        onSuccess(data) {
          const { imageId, assetId } = data.data;
          setNewCustomId(imageId, assetId);
          setImgSelect(tempState(imageId, assetId));
          const myInterval = setInterval(async () => {
            const resUpscale = await getImageMidjourney(assetId);

            if (resUpscale.data.uri) {
              setState((prev) =>
                prev.map((item) => {
                  if (item.imageId === imageId) {
                    const img = {
                      ...item,
                      url: resUpscale.data.uri,
                      step: 3,
                      done: true,
                      readyRun: false,
                      progress: '100%',
                    };
                    setImgSelect(img);
                    return img;
                  }
                  return item;
                }),
              );
              mutatePostImageMidjourney(assetId);
              clearTimeout(myInterval);
            }
          }, 5000);
        },
      });
    }
  };

  const generateImgControlNet = (data: { [key: string]: any }) => {
    data = { ...data, modelId: modelDetail?.id };
    const { file, generate_type } = data;
    let { prompt, lora } = data;
    prompt = prompt.trim();
    if (error && comparisonObject(error, data)) return;
    setError('');
    if (testPreventSubmitLink(prompt)) {
      setError(data);
      return;
    }
    if (lora?.length > 0) {
      lora = lora.map((lora: any) => `${lora.alias}:${lora.value}`).join(',');
      data = { ...data, lora };
    }
    const bodyFormData = new FormData();
    for (const k in data) {
      bodyFormData.append(k, data[k]);
    }
    mutateGenerateControlNet(bodyFormData, {
      onSuccess: (data) => {
        setOptionsSelectStyle('');
        setIdGenerateText2Img(data.data.id);
        setPromptGenerateText2Img(prompt);
        if (state[0]?.imageId !== 0) {
          setState([
            {
              customId: null,
              done: false,
              imageId: 0,
              progress: '0%',
              prompt,
              readyRun: true,
              step: 1,
              url: '',
              file,
              generateType: generate_type,
              createdAt: new Date().toISOString(),
            },
            ...state,
          ]);
          setImgSelect({ ...initialState, readyRun: true, file, generateType: generate_type });
        } else {
          setState((prev) =>
            prev.map((item) => ({
              ...item,
              readyRun: true,
              prompt,
              file,
              generateType: generate_type,
            })),
          );
        }
      },
    });
  };

  const generateImgQRCode = (data: { [key: string]: any }) => {
    data = { ...data, modelId: modelDetail?.id };
    const { file, generate_type } = data;
    let { prompt, lora } = data;
    prompt = prompt.trim();
    if (error && comparisonObject(error, data)) return;
    setError('');
    if (testPreventSubmitLink(prompt)) {
      setError(data);
      return;
    }
    if (lora?.length > 0) {
      lora = lora.map((lora: any) => `${lora.alias}:${lora.value}`).join(',');
      data = { ...data, lora };
    }
    const bodyFormData = new FormData();
    for (const k in data) {
      bodyFormData.append(k, data[k]);
    }
    mutateGenerateQRCode(bodyFormData, {
      onSuccess: (data) => {
        setOptionsSelectStyle('');
        setIdGenerateText2Img(data.data.id);
        setPromptGenerateText2Img(prompt);
        if (state[0]?.imageId !== 0) {
          setState([
            {
              customId: null,
              done: false,
              imageId: 0,
              progress: '0%',
              prompt,
              readyRun: true,
              step: 1,
              url: '',
              file,
              generateType: generate_type,
              createdAt: new Date().toISOString(),
            },
            ...state,
          ]);
          setImgSelect({ ...initialState, readyRun: true, file, generateType: generate_type });
        } else {
          setState((prev) =>
            prev.map((item) => ({
              ...item,
              readyRun: true,
              prompt,
              file,
              generateType: generate_type,
            })),
          );
        }
      },
    });
  };

  const generateImgSticker = (data: { [key: string]: any }) => {
    data = { ...data, modelId: modelDetail?.id };
    const { file } = data;
    const prompt = data.prompt.trim();

    if (error && comparisonObject(error, data)) return;
    setError('');
    if (testPreventSubmitLink(prompt)) {
      setError(data);
      return;
    }
    const bodyFormData = new FormData();
    for (const k in data) {
      bodyFormData.append(k, data[k]);
    }
    mutateGenerateSticker(bodyFormData, {
      onSuccess: (data) => {
        setOptionsSelectStyle('');
        setArrayIdGenerateText2Img(data.data);
        setPromptGenerateText2Img(prompt);
        if (state[0]?.imageId !== 0) {
          setState([
            {
              customId: null,
              done: false,
              imageId: 0,
              progress: '0%',
              prompt,
              readyRun: true,
              step: 1,
              url: '',
              file,
              createdAt: new Date().toISOString(),
            },
            ...state,
          ]);
          setImgSelect({ ...initialState, readyRun: true, file, prompt });
        } else {
          setState((prev) =>
            prev.map((item) => ({
              ...item,
              readyRun: true,
              prompt,
              file,
            })),
          );
        }
      },
    });
  };

  const generateImgAnime = (data: { [key: string]: any }) => {
    data = { ...data, modelId: modelDetail?.id };
    const { file, generate_type } = data;
    let { lora } = data;
    if (lora?.length > 0) {
      lora = lora.map((lora: any) => `${lora.alias}:${lora.value}`).join(',');
      data = { ...data, lora };
    }
    const bodyFormData = new FormData();
    for (const k in data) {
      bodyFormData.append(k, data[k]);
    }
    mutateGenerateAnime(bodyFormData, {
      onSuccess: (data) => {
        setOptionsSelectStyle('');
        setIdGenerateText2Img(data.data.id);
        if (state[0]?.imageId !== 0) {
          setState([
            {
              customId: null,
              done: false,
              imageId: 0,
              progress: '0%',
              prompt: '',
              readyRun: true,
              step: 1,
              url: '',
              file,
              generateType: generate_type,
              createdAt: new Date().toISOString(),
            },
            ...state,
          ]);
          setImgSelect({ ...initialState, readyRun: true, file, generateType: generate_type });
        } else {
          setState((prev) =>
            prev.map((item) => ({
              ...item,
              readyRun: true,
              prompt: '',
              file,
              generateType: generate_type,
            })),
          );
        }
      },
    });
  };

  const generateIpAdapter = (data: { [key: string]: any }) => {
    data = { ...data, modelId: modelDetail?.id };
    const prompt = data.prompt.trim();

    const bodyFormData = new FormData();
    for (const k in data) {
      bodyFormData.append(k, data[k]);
    }
    mutateGenerateIpAdapter(bodyFormData, {
      onSuccess: (data) => {
        setOptionsSelectStyle('');
        setArrayIdGenerateText2Img(data.data);
        setPromptGenerateText2Img(prompt);
        if (state[0]?.imageId !== 0) {
          setState([
            {
              customId: null,
              done: false,
              imageId: 0,
              progress: '0%',
              prompt,
              readyRun: true,
              step: 1,
              url: '',
              createdAt: new Date().toISOString(),
            },
            ...state,
          ]);
          setImgSelect({ ...initialState, readyRun: true });
        } else {
          setState((prev) =>
            prev.map((item) => ({
              ...item,
              readyRun: true,
              prompt,
            })),
          );
        }
      },
    });
  };

  const generatePortrait = (data: { [key: string]: any }) => {
    data = { ...data, modelId: modelDetail?.id };

    const bodyFormData = new FormData();
    for (const k in data) {
      bodyFormData.append(k, data[k]);
    }
    mutateGenerateLivePortrait(bodyFormData, {
      onSuccess: (data) => {
        setOptionsSelectStyle('');
        setArrayIdGenerateText2Img(data.data);
        if (state[0]?.imageId !== 0) {
          setState([
            {
              customId: null,
              done: false,
              imageId: 0,
              progress: '0%',
              prompt: '',
              readyRun: true,
              step: 1,
              url: '',
              createdAt: new Date().toISOString(),
            },
            ...state,
          ]);
          setImgSelect({ ...initialState, readyRun: true });
        } else {
          setState((prev) =>
            prev.map((item) => ({
              ...item,
              readyRun: true,
              prompt: '',
            })),
          );
        }
      },
    });
  };

  const generateImgDalle = (data: any) => {
    data = { ...data, modelId: modelDetail?.id };
    const { prompt } = data;
    prompt.trim();
    if (error && comparisonObject(error, data)) return;
    setError('');
    if (testPreventSubmitLink(prompt) || detectBannedWords(prompt).length > 0) {
      setError(data);
      return;
    }
    const bodyFormData = new FormData();
    for (const k in data) {
      bodyFormData.append(k, data[k]);
    }

    mutateGenerateDalle(bodyFormData, {
      onSuccess: (data) => {
        setOptionsSelectStyle('');
        setIdGenerateText2Img(data.data.id);
        setPromptGenerateText2Img(prompt);
        if (state[0]?.imageId !== 0) {
          setState([
            {
              customId: null,
              done: false,
              imageId: 0,
              progress: '0%',
              prompt,
              readyRun: true,
              step: 1,
              url: '',
              assetId: data.data.id,
              createdAt: new Date().toISOString(),
              modelName: modelDetail.name,
            },
            ...state,
          ]);
          setImgSelect({ ...initialState, readyRun: true, modelName: modelDetail.name });
        } else {
          setState((prev) =>
            prev.map((item) => ({
              ...item,
              readyRun: true,
              prompt,
              assetId: data.data.id,
              modelName: modelDetail.name,
            })),
          );
        }
      },
    });
  };

  const generateImgSDXL3 = (data: any) => {
    data = { ...data, modelId: modelDetail?.id };
    const { prompt } = data;
    prompt.trim();
    if (error && comparisonObject(error, data)) return;
    setError('');
    if (testPreventSubmitLink(prompt) || detectBannedWords(prompt).length > 0) {
      setError(data);
      return;
    }
    const bodyFormData = new FormData();
    for (const k in data) {
      bodyFormData.append(k, data[k]);
    }

    mutateGenerateSdxl3(bodyFormData, {
      onSuccess: (data) => {
        setOptionsSelectStyle('');
        setIdGenerateText2Img(data.data.id);
        setPromptGenerateText2Img(prompt);
        if (state[0]?.imageId !== 0) {
          setState([
            {
              customId: null,
              done: false,
              imageId: 0,
              progress: '0%',
              prompt,
              readyRun: true,
              step: 1,
              url: '',
              assetId: data.data.id,
              createdAt: new Date().toISOString(),
              modelName: modelDetail.name,
            },
            ...state,
          ]);
          setImgSelect({ ...initialState, readyRun: true, modelName: modelDetail.name });
        } else {
          setState((prev) =>
            prev.map((item) => ({
              ...item,
              readyRun: true,
              prompt,
              assetId: data.data.id,
              modelName: modelDetail.name,
            })),
          );
        }
      },
    });
  };

  const generateImgText2Image = (data: any) => {
    data = { ...data, modelId: modelDetail?.id };
    let { prompt, lora } = data;
    prompt = prompt.trim();

    if (error && comparisonObject(error, data)) return;
    setError('');
    if (testPreventSubmitLink(prompt)) {
      setError(data);
      return;
    }
    if (lora?.length > 0) {
      lora = lora.map((lora: any) => `${lora.alias}:${lora.value}`).join(',');
      data = { ...data, lora };
    }
    const bodyFormData = new FormData();
    for (const k in data) {
      if (data[k] !== '') bodyFormData.append(k, data[k]);
    }

    const { isPassRule } = checkPassRuleWithPlan({
      plan: userPlan,
      data: {
        batch_size: data?.batch_size,
        hr_scale_by: data?.hr_scale_by,
        enable_hires: data?.enable_hires,
      },
    });

    if (!isPassRule) {
      onOpenUpgrade();
      return;
    }

    mutateGenerateText2Image(bodyFormData, {
      onSuccess: (data) => {
        setOptionsSelectStyle('');
        setArrayIdGenerateText2Img(data.data);
        setPromptGenerateText2Img(prompt);
        if (state[0]?.imageId !== 0) {
          setState([
            {
              customId: null,
              done: false,
              imageId: 0,
              progress: '0%',
              prompt,
              readyRun: true,
              step: 1,
              url: '',
              createdAt: new Date().toISOString(),
              modelName: modelDetail.name,
            },
            ...state,
          ]);
          setImgSelect({ ...initialState, readyRun: true, modelName: modelDetail.name });
        } else {
          setState((prev) =>
            prev.map((item) => ({ ...item, readyRun: true, prompt, modelName: modelDetail.name })),
          );
        }
      },
    });
  };

  const generateUpscaleText2Image = (imageAsset?: PromptProviderState) => {
    if (imageAsset?.assetId?.toString() || imgSelect?.assetId?.toString()) {
      const { isPassRule } = checkPassRuleWithPlan({
        plan: userPlan,
        data: { has_check_upscaler: true },
      });
      if (!isPassRule) {
        onOpenUpgrade();
        return;
      }

      mutateUpscaleText2Image(
        imageAsset?.assetId?.toString() || imgSelect?.assetId?.toString() || '',
        {
          onSuccess: (data) => {
            setIdGenerateText2Img(data.data.id);
            setPromptGenerateText2Img(imgSelect.prompt);
            if (state[0]?.imageId !== 0) {
              setState([
                {
                  customId: null,
                  done: false,
                  imageId: 0,
                  progress: '0%',
                  prompt: imgSelect.prompt,
                  readyRun: true,
                  step: 1,
                  url: '',
                  assetId: data.data.id,
                  upScale: true,
                  createdAt: new Date().toISOString(),
                  modelName: modelDetail.name,
                },
                ...state,
              ]);
              setImgSelect({
                ...initialState,
                readyRun: true,
                modelName: modelDetail.name,
                upScale: true,
              });
            } else {
              setState((prev) =>
                prev.map((item) => ({
                  ...item,
                  readyRun: true,
                  prompt: imgSelect.prompt,
                  assetId: data.data.id,
                  modelName: modelDetail.name,
                  upScale: true,
                })),
              );
            }
          },
        },
      );
    }
  };

  const generateImgSdxl = (data: any) => {
    data = { ...data, modelId: modelDetail?.id };
    let { prompt, lora } = data;
    prompt = prompt.trim();
    if (error && comparisonObject(error, data)) return;
    setError('');
    if (testPreventSubmitLink(prompt)) {
      setError(data);
      return;
    }
    if (lora?.length > 0) {
      lora = lora.map((lora: any) => `${lora.alias}:${lora.value}`).join(',');
      data = { ...data, lora };
    }
    const bodyFormData = new FormData();
    for (const k in data) {
      if (data[k] !== '') bodyFormData.append(k, data[k]);
    }

    const { isPassRule } = checkPassRuleWithPlan({
      plan: userPlan,
      data: {
        batch_size: data?.batch_size,
        hr_scale_by: data?.hr_scale_by,
        enable_hires: data?.enable_hires,
      },
    });

    if (!isPassRule) {
      onOpenUpgrade();
      return;
    }

    mutateGenerateSdxl(bodyFormData, {
      onSuccess: (data) => {
        setOptionsSelectStyle('');
        setArrayIdGenerateText2Img(data.data);
        setPromptGenerateText2Img(prompt);
        if (state[0]?.imageId !== 0) {
          setState([
            {
              customId: null,
              done: false,
              imageId: 0,
              progress: '0%',
              prompt,
              readyRun: true,
              step: 1,
              url: '',
              createdAt: new Date().toISOString(),
              modelName: modelDetail.name,
            },
            ...state,
          ]);
          setImgSelect({ ...initialState, readyRun: true, modelName: modelDetail.name });
        } else {
          setState((prev) =>
            prev.map((item) => ({ ...item, readyRun: true, prompt, modelName: modelDetail.name })),
          );
        }
      },
    });
  };

  const generateImgInpainting = (data: any) => {
    data = { ...data, modelId: modelDetail?.id };
    let { prompt, lora } = data;
    prompt = prompt.trim();
    if (error && comparisonObject(error, data)) return;
    setError('');
    if (testPreventSubmitLink(prompt)) {
      setError(data);
      return;
    }
    if (lora?.length > 0) {
      lora = lora.map((lora: any) => `${lora.alias}:${lora.value}`).join(',');
      data = { ...data, lora };
    }
    const bodyFormData = new FormData();
    for (const k in data) {
      bodyFormData.append(k, data[k]);
    }

    mutateGenerateInpainting(bodyFormData, {
      onSuccess: (data) => {
        setOptionsSelectStyle('');
        setArrayIdGenerateText2Img(data.data);
        setPromptGenerateText2Img(prompt);
        if (state[0]?.imageId !== 0) {
          setState([
            {
              customId: null,
              done: false,
              imageId: 0,
              progress: '0%',
              prompt,
              readyRun: true,
              step: 1,
              url: '',
              assetId: data.data.id,
              createdAt: new Date().toISOString(),
            },
            ...state,
          ]);
          setImgSelect({ ...initialState, readyRun: true });
        } else {
          setState((prev) =>
            prev.map((item) => ({ ...item, readyRun: true, prompt, assetId: data.data.id })),
          );
        }
      },
    });
  };

  const generateImgAiright = (data: any) => {
    if (!data.file) return;
    data = { ...data, modelId: modelDetail?.id, mimeType: data.file.type };
    if (data.assetUrl) delete data.file;
    const bodyFormData = new FormData();

    for (const k in data) {
      bodyFormData.append(k, data[k]);
    }
    mutateGenerateFileOrigin(bodyFormData, {
      onSuccess: (data) => {
        if (data) {
          return router.push(`/profile/${auth.user.publicAddress}?tab=processing`);
        }
      },
    });
  };

  useEffect(() => {
    if (arrayIdGenerateText2Img.length > 0) {
      myInterval.current = setInterval(() => {
        getProcessingAssetByIds(arrayIdGenerateText2Img)
          .then((data: any) => {
            const fetchData = data;
            if (
              fetchData.length > 0 &&
              (fetchData.some((item: any) => item.url) ||
                fetchData.some((item: any) => item.originalUrl))
            ) {
              clearInterval(myInterval.current);
              const lastState = state[0];
              const imgs = fetchData.map((item: any): PromptProviderState => {
                let imageConfig;
                if (isJSON(item.modelConfig)) {
                  imageConfig = JSON.parse(item.modelConfig);
                }
                return {
                  ...lastState,
                  assetId: item?.id ?? 0,
                  customId: null,
                  done: true,
                  imageId: item.originalUrl ?? item.url ?? '',
                  progress: '100%',
                  prompt: promptGenerateText2Img ?? '',
                  readyRun: false,
                  step: 2,
                  url: item.originalUrl ?? item.url ?? '',
                  imageConfig,
                  isNsfw: item.isNsfw,
                  createdAt: item?.createdAt ?? new Date().toISOString(),
                  type: item.type || '',
                };
              });
              setImgSelect(imgs[0]);
              if (state.length === 1) {
                setState(imgs);
              } else {
                state.shift();
                setState([...imgs, ...state]);
              }
              setTab('result');
              refetchGetPlan();
            }
          })
          .catch(() => {
            const img: PromptProviderState = {
              ...initialState,
              prompt: promptGenerateText2Img ?? '',
            };
            state.shift();
            if (state.length > 0) {
              setImgSelect(state.at(-1) ?? img);
              setState(state);
            } else {
              setState([img]);
            }
            clearInterval(myInterval.current);
          });
      }, 5000);
    }
  }, [arrayIdGenerateText2Img]);

  useEffect(() => {
    if (idGenerateText2Img) {
      myInterval.current = setInterval(() => {
        getGenerateText2Image(idGenerateText2Img)
          .then((data: any) => {
            const fetchData = data?.data;
            if (fetchData && (fetchData.url || fetchData.originalUrl)) {
              clearInterval(myInterval.current);
              const stateTemp: PromptProviderState[] = [];
              const { url, originalUrl, createdAt } = fetchData;

              const lastSate = state[0];
              const img = {
                ...lastSate,
                customId: null,
                assetId: fetchData.id,
                done: true,
                imageId: originalUrl ?? url ?? '',
                progress: '100%',
                prompt: promptGenerateText2Img ?? '',
                readyRun: false,
                step: 3,
                isNsfw: fetchData.isNsfw,
                url: originalUrl ?? url ?? '',
                createdAt: createdAt ?? new Date().toISOString(),
                type: fetchData.type || '',
              };
              setImgSelect(img);
              stateTemp.push(img);
              if (state.length === 1) {
                setState(stateTemp);
              } else {
                state.shift();
                setState([...stateTemp, ...state]);
              }
              setTab('result');
              refetchGetPlan();
            }
          })
          .catch(() => {
            const img: PromptProviderState = {
              ...initialState,
              prompt: promptGenerateText2Img ?? '',
            };
            state.shift();
            if (state.length > 0) {
              setImgSelect(state.at(-1) ?? img);
              setState(state);
            } else {
              setState([img]);
            }
            clearInterval(myInterval.current);
          });
      }, 5000);
    }
  }, [idGenerateText2Img]);

  useEffect(() => {
    if (queueImg.length > 0 && modelDetail.type === 'Midjourney') {
      const imageId = queueImg.at(-1);
      if (!imageId) return;

      const socket = SSocket();
      socket.connect();
      socket.on('connect', () => {});

      myInterval.current = setInterval(async () => {
        const assetId = state.find((item) => item.imageId === imageId)?.assetId;
        if (assetId) {
          const res = await getImageMidjourney(assetId);
          if (res?.data?.uri) {
            setState((prev) =>
              prev.map((item) => {
                if (imageId === item.imageId) {
                  const checkFinishedUpscale =
                    (item.customId &&
                      (item.customId.slice(0, 1) === 'U' || item.customId.slice(1, 2) === 'x')) ??
                    false;
                  const img = {
                    ...item,
                    imageId,
                    progress: '100%',
                    url: res.data.uri,
                    step: checkFinishedUpscale ? 3 : 2,
                    readyRun: false,
                    done: checkFinishedUpscale,
                  };
                  setImgSelect(img);
                  return img;
                }
                return item;
              }),
            );
            socket.disconnect();
            clearInterval(myInterval.current);
            const assetId = state.find((img) => img.imageId === imageId)?.assetId;
            if (assetId) {
              mutatePostImageMidjourney(assetId);
            }
            setTab('result');
            refetchGetPlan();
          } else {
            socketEvent();
          }
        }
      }, 60000);

      const socketEvent = () =>
        socket.on(`event-create-image-id-${imageId}`, (event) => {
          const data = JSON.parse(event);
          if (state.some((item) => item.imageId === imageId)) {
            setState((prev) =>
              prev.map((item) => {
                if (imageId === item.imageId) {
                  return {
                    ...item,
                    imageId,
                    progress: data.progress,
                    url: data.uri,
                    step: 3,
                    readyRun: true,
                  };
                }
                return item;
              }),
            );
          }
          if (data.progress === '100%') {
            if (myInterval.current) clearInterval(myInterval.current);
            if (data?.error) {
              socket.off(`event-create-image-id-${data.imageId}`);
              socket.disconnect();
              setState((prev) =>
                prev.map((item) => {
                  if (imageId === item.imageId) {
                    return {
                      ...item,
                      imageId,
                      readyRun: false,
                      done: false,
                    };
                  }
                  return item;
                }),
              );
              return toast.error(data?.error?.toString().split('.')[0]);
            }

            setState((prev) =>
              prev.map((item) => {
                if (imageId === item.imageId) {
                  const checkFinishedUpscale =
                    (item.customId &&
                      (item.customId.slice(0, 1) === 'U' || item.customId.slice(1, 2) === 'x')) ??
                    false;
                  const img = {
                    ...item,
                    imageId,
                    progress: data.progress,
                    url: data.uri,
                    step: checkFinishedUpscale ? 3 : 2,
                    readyRun: false,
                    done: checkFinishedUpscale,
                  };
                  setImgSelect(img);
                  return img;
                }
                return item;
              }),
            );
            const assetId = state.find((img) => img.imageId === imageId)?.assetId;
            if (assetId) {
              mutatePostImageMidjourney(assetId);
            }
            socket.off(`event-create-image-id-${data.imageId}`);
            socket.disconnect();
            setTab('result');
            refetchGetPlan();
          }
        });
      socketEvent();
    }
    // return () => socket.disconnect();
  }, [queueImg]);

  useEffect(() => {
    if (!isLogin) {
      connectFreeAcc();
    }
  }, [isLogin]);

  const campaignTag = campaigns?.find((item: { id: number }) => item.id === campaignId)?.tag;
  if (
    campaignId > 0 &&
    imgSelect?.progress === '100%' &&
    imgSelect?.assetId !== 0 &&
    !autoMintArr.includes(imgSelect.assetId ?? 0) &&
    modelDetail.type !== 'Midjourney'
  ) {
    autoMint(imgSelect.assetId || 0, campaignTag);
    setAutoMintArr([...autoMintArr, imgSelect.assetId ?? 0]);
  }

  useEffect(() => {
    if (
      campaignId > 0 &&
      modelDetail.type === 'Midjourney' &&
      resDataPostIdMidjourney?.data.url &&
      !autoMintArr.includes(imgSelect.assetId ?? 0) &&
      imgSelect?.progress === '100%' &&
      imgSelect?.assetId !== 0
    ) {
      autoMint(imgSelect.assetId || 0, campaignTag);
      setAutoMintArr([...autoMintArr, imgSelect.assetId ?? 0]);
    }
  }, [resDataPostIdMidjourney]);

  useEffect(() => {
    if (!isLogin) {
      const listProcessId = state.map((item) => {
        return item.assetId;
      });
      localStorage.setItem('listAssetId', JSON.stringify(listProcessId));
    }
  }, [state]);

  useEffect(() => {
    if (promptCache && promptCache !== 'undefined') {
      setValue('prompt', promptCache);
    }
  }, [promptCache]);

  // Get Total credit by user
  const [totalCredit, setTotalCredit] = useState(0);

  useEffect(() => {
    if (dataTotalCredit?.credit) {
      setTotalCredit(dataTotalCredit.credit);
      setAuth({ ...auth, user: { ...auth.user, credit: dataTotalCredit.credit } });
    }
  }, [dataTotalCredit]);

  // Get fee credit by model config
  const [feeCredit, setFeeCredit] = useState(0);

  const getCalcCredit = useCallback(
    debounce(async (taskType: any, baseModel: {}) => {
      try {
        const res = await calculateCreditInModel(taskType, baseModel);
        if (res) {
          setFeeCredit(res.data?.totalCredit?.toFixed(1) || 0);
        }
      } catch (error) {
        setFeeCredit(0);
      }
    }, 300),
    [],
  );

  useEffect(() => {
    if (createForm.getValues() && isLoginClient) {
      getCalcCredit(
        modelDetail.type === 'DALL-E 2&3'
          ? String(createForm.getValues(['model'])).toUpperCase()
          : modelDetail.type,
        createForm.getValues(),
      );
    }
  }, [createForm.getValues(), isLoginClient]);

  useEffect(() => {
    if (modelLoraSelected) {
      if (modelLoraSelected?.type === 2) {
        setValue('lora', [
          {
            alias: modelLoraSelected?.alias,
            description: modelLoraSelected?.description || '',
            preview: modelLoraSelected?.previews,
            value: 1,
            trigger_words: modelLoraSelected.trigger_words || [],
            name: modelLoraSelected?.name || '',
          },
        ]);
      }
      if (modelLoraSelected?.type === 1) {
        setValue('model', modelLoraSelected?.alias);
      }
    }
  }, []);

  return (
    <div
      aria-expanded={isRemix}
      className="h-[calc(100dvh-100px)] w-full aria-expanded:h-[calc(100dvh-30px)] xl:h-[calc(100dvh-72px)] aria-expanded:xl:h-[calc(100dvh-30px)]"
    >
      <ModelControlImg
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        deleteAsset={() => deleteAsset(idAssetIsSelected)}
      />
      <div className="relative">
        <div
          aria-expanded={isRemix}
          className="border-b-1 border-gray-100 bg-gray-50 aria-expanded:rounded-[12px] md:hidden"
        >
          <div
            aria-disabled={tab === 'result'}
            className="flex w-full items-center justify-between px-5 py-[13px] aria-disabled:md:justify-end sxm:px-1"
          >
            <div
              aria-disabled={tab === 'result'}
              className="w-1/4 min-w-[400px] py-[5px] md:w-full md:min-w-full aria-disabled:md:hidden"
            >
              <div className="flex items-center justify-between">
                {[
                  { type: 'SDXL', name: 'SDXL' },
                  { type: 'Text2Image', name: 'SD' },
                  { type: 'SDXL-3', name: 'SD3' },
                  { type: 'DALL-E 2&3', name: 'DALL-E' },
                ].map((item, index) => (
                  <span
                    onClick={() => {
                      createForm.unregister(['model', 'lora']);
                      return setSateModelDetail
                        ? setSateModelDetail(models.items.find((model) => model.type === item.type))
                        : router.push(
                            `/ai-model/${models.items.find((model) => model.type === item.type)
                              ?.id}/generate`,
                          );
                    }}
                    key={index}
                    aria-checked={modelDetail.type === item.type}
                    className="flex flex-1 cursor-pointer justify-center pb-2 text-13 font-[400] text-black aria-checked:border-b-2 aria-checked:border-[#DA613A] aria-checked:text-[#DA613A]"
                  >
                    {item.name}
                  </span>
                ))}
              </div>
            </div>
            <div
              aria-disabled={tab === 'create'}
              className="flex gap-2 md:flex-wrap-reverse md:justify-end aria-disabled:md:hidden"
            >
              <Buttons
                onClick={() => {
                  mixpanel.track(MIXPANEL_TRACK.PUSHLISH_ART);
                  isLogin
                    ? publishAsset(idAssetIsSelected)
                    : toast.warning('You must login to publish images!');
                }}
                typeBtn={idAssetIsSelected.length > 0 ? 'primary' : 'disabled'}
                disabled={!(idAssetIsSelected.length > 0)}
                className="flex items-center rounded-[100px]"
              >
                Publish
              </Buttons>

              <Button
                isIconOnly
                variant="flat"
                onClick={() => {
                  isLogin ? onOpen() : toast.warning('You must login to delete images!');
                }}
                disabled={!(idAssetIsSelected.length > 0)}
                className="flex items-center rounded-[100px]"
              >
                {idAssetIsSelected.length > 0 ? <IconTrashBlack22x22 /> : <IconTrash22x22 />}
              </Button>
            </div>
          </div>
        </div>
        <div
          aria-expanded={isRemix}
          className="bg-white px-5 aria-expanded:rounded-b-[12px] sm:px-2"
        >
          <div className="mt-3 hidden w-full items-center rounded-[24px] bg-gray-200 md:flex">
            <span
              onClick={() => setTab('create')}
              aria-expanded={tab === 'create'}
              className="w-[50%] rounded-[24px] px-[10px] py-2 text-center text-14 font-semibold text-gray-400 aria-expanded:bg-orange-100 aria-expanded:text-primary"
            >
              Create
            </span>
            <span
              onClick={() => setTab('result')}
              aria-expanded={tab === 'result'}
              className="w-[50%] rounded-[24px] px-[10px] py-2 text-center text-14 font-semibold text-gray-400 aria-expanded:bg-orange-100 aria-expanded:text-primary"
            >
              Result
            </span>
          </div>
          <div className="hidden md:block">
            <div
              aria-disabled={tab === 'result'}
              className="flex w-full items-center justify-between px-5 py-[13px] aria-disabled:hidden aria-disabled:md:justify-end sxm:px-1"
            >
              <div
                aria-disabled={tab === 'result'}
                className="w-1/4 min-w-[400px] py-[5px] md:w-full md:min-w-full aria-disabled:md:hidden"
              >
                <div className="flex items-center justify-between">
                  {[
                    { type: 'SDXL', name: 'SDXL' },
                    { type: 'Text2Image', name: 'SD' },
                    { type: 'SDXL-3', name: 'SD3' },
                    { type: 'DALL-E 2&3', name: 'DALL-E' },
                  ].map((item, index) => (
                    <span
                      onClick={() => {
                        createForm.unregister(['model', 'lora']);
                        return setSateModelDetail
                          ? setSateModelDetail(
                              models.items.find((model) => model.type === item.type),
                            )
                          : router.push(
                              `/ai-model/${models.items.find((model) => model.type === item.type)
                                ?.id}/generate`,
                            );
                      }}
                      key={index}
                      aria-checked={modelDetail.type === item.type}
                      className="flex flex-1 cursor-pointer justify-center border-b-1 border-gray-100 pb-2 text-13 font-[400] text-black aria-checked:border-b-2 aria-checked:border-[#DA613A] aria-checked:text-[#DA613A]"
                    >
                      {item.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            aria-disabled={tab === 'result'}
            aria-expanded={isRemix}
            className="flex h-[calc(100dvh-167px)] w-full aria-expanded:h-[calc(100dvh-97px)] aria-expanded:overflow-hidden aria-expanded:overflow-y-auto md:flex-col aria-expanded:md:h-[calc(100dvh-146px)] aria-expanded:aria-disabled:md:h-[calc(100dvh-80px)]"
          >
            <div
              aria-disabled={tab === 'create'}
              className="w-1/4 min-w-[400px] border-r-1 border-gray-100 p-[10px] pb-0 pl-0 md:flex md:w-full md:min-w-full md:justify-center md:border-none md:p-0 aria-disabled:md:mb-[90px]"
            >
              {modelDetail.status === 4 ? (
                <div className="text-center text-[24px] font-semibold text-orange-500 md:pt-5">
                  Model maintenance!
                </div>
              ) : (
                match(modelDetail.type)
                  .with('Midjourney', () => (
                    <ImageMidjourneyModel
                      isLoading={isLoading || isLoadingUpscale || isLoadingVariation}
                      imgSelect={imgSelect}
                      isLoadingBlendImageMidjourney={isLoadingBlendImageMidjourney}
                      generateImg={generateImg}
                      blendImageMidjourney={blendImageMidjourney}
                      setQueueImg={setQueueImg}
                      setImgSelect={setImgSelect}
                      customImgCallback={customImgCallback}
                      createForm={createForm}
                      setOptionsSelectStyle={setOptionsSelectStyle}
                      campaigns={campaigns}
                      feeCredit={feeCredit}
                      totalCredit={totalCredit}
                    />
                  ))
                  .with('ControlNet', () => (
                    <ControlNetModel
                      isLoading={isLoadingControl}
                      imgSelect={imgSelect}
                      generateImg={generateImgControlNet}
                      createForm={createForm}
                      setOptionsSelectStyle={setOptionsSelectStyle}
                      campaigns={campaigns}
                    />
                  ))
                  .with('Text2Image', () => (
                    <Text2Image
                      isLoading={isLoadingText2}
                      imgSelect={imgSelect}
                      generateImg={generateImgText2Image}
                      setOptionsSelectStyle={setOptionsSelectStyle}
                      createForm={createForm}
                      campaigns={campaigns}
                      feeCredit={feeCredit}
                      totalCredit={totalCredit}
                    />
                  ))
                  .with('SDXL', () => (
                    <SDXLModel
                      isLoading={isLoadingSdxl}
                      imgSelect={imgSelect}
                      generateImg={generateImgSdxl}
                      setOptionsSelectStyle={setOptionsSelectStyle}
                      createForm={createForm}
                      campaigns={campaigns}
                      feeCredit={feeCredit}
                      totalCredit={totalCredit}
                    />
                  ))
                  .with('DALL-E 2&3', () => (
                    <DalleModel
                      isLoading={isLoadingDalle}
                      imgSelect={imgSelect}
                      generateImg={generateImgDalle}
                      setOptionsSelectStyle={setOptionsSelectStyle}
                      createForm={createForm}
                      campaigns={campaigns}
                      feeCredit={feeCredit}
                      totalCredit={totalCredit}
                    />
                  ))
                  .with('Qr Art', () => (
                    <QRCodeModel
                      isLoading={isLoadingQRCode}
                      imgSelect={imgSelect}
                      generateImg={generateImgQRCode}
                      setOptionsSelectStyle={setOptionsSelectStyle}
                      createForm={createForm}
                      campaigns={campaigns}
                    />
                  ))
                  .with('Image2Anime', () => (
                    <AnimeModel
                      isLoading={isLoadingAnime}
                      imgSelect={imgSelect}
                      generateImg={generateImgAnime}
                      setOptionsSelectStyle={setOptionsSelectStyle}
                      createForm={createForm}
                      campaigns={campaigns}
                    />
                  ))
                  .with('Inpaint', () => (
                    <InpaintingModel
                      isLoading={isLoadingInpaiting}
                      generateImg={generateImgInpainting}
                      imgSelect={imgSelect}
                      setImgSelect={setImgSelect}
                      canvasRef={canvasRef}
                      backgroundRef={backgroundRef}
                      setOptionsSelectStyle={setOptionsSelectStyle}
                      createForm={createForm}
                      campaigns={campaigns}
                    />
                  ))
                  .with('Sticker', () => (
                    <StickerModel
                      generateImg={generateImgSticker}
                      createForm={createForm}
                      campaigns={campaigns}
                      imgSelect={imgSelect}
                      isLoading={isLoadingSticker}
                      setOptionsSelectStyle={setOptionsSelectStyle}
                    />
                  ))
                  .with('LivePortrait', () => (
                    <LivePortraitModel
                      generateImg={generatePortrait}
                      createForm={createForm}
                      campaigns={campaigns}
                      imgSelect={imgSelect}
                      isLoading={isLoadingLivePortrait}
                      setOptionsSelectStyle={setOptionsSelectStyle}
                    />
                  ))
                  .with('SDXL-3', () => (
                    <SDXL3
                      generateImg={generateImgSDXL3}
                      createForm={createForm}
                      campaigns={campaigns}
                      imgSelect={imgSelect}
                      isLoading={isLoadingSdxl3}
                      setOptionsSelectStyle={setOptionsSelectStyle}
                      feeCredit={feeCredit}
                      totalCredit={totalCredit}
                    />
                  ))
                  .with('IP-Adapter', () => (
                    <IpAdapter
                      generateImg={generateIpAdapter}
                      createForm={createForm}
                      campaigns={campaigns}
                      imgSelect={imgSelect}
                      isLoading={isLoadingIpAdapter}
                      setOptionsSelectStyle={setOptionsSelectStyle}
                    />
                  ))
                  .otherwise(() => (
                    <AirightModel
                      setImgSelect={setImgSelect}
                      isLoading={isLoading || isLoadingUpscale || isLoadingVariation}
                      imgSelect={imgSelect}
                      generateImg={generateImgAiright}
                    />
                  ))
              )}
            </div>
            {modelDetail.type === 'Inpaint' ? (
              <div
                aria-disabled={tab === 'create'}
                aria-expanded={!isNotModelSpecial && !optionsSelectStyle}
                aria-checked={!isNotModelSpecial}
                className="flex h-full flex-1 items-start overflow-hidden aria-checked:lg:flex-col aria-expanded:lg:!h-fit md:!h-fit md:flex-col md:overflow-y-auto aria-disabled:md:hidden"
              >
                <div
                  aria-disabled={tab === 'result'}
                  aria-hidden={isNotModelSpecial}
                  aria-expanded={isNotModelSpecial}
                  className="h-full w-full flex-1 aria-expanded:pr-0 lg:h-[calc(100%-140px)] md:mb-5 md:mt-12 md:p-0 aria-disabled:md:mt-5 aria-hidden:md:hidden"
                >
                  <Feed
                    modelDetail={modelDetail}
                    optionsSelectStyle={optionsSelectStyle}
                    setOptionsSelectStyle={setOptionsSelectStyle}
                    loading={isLoadingText2UpscaleText2Image}
                    generateUpscaleText2Image={generateUpscaleText2Image}
                    isNotModelSpecial={isNotModelSpecial}
                    imgSelect={imgSelect}
                    canvasRef={canvasRef}
                    backgroundRef={backgroundRef}
                    createForm={createForm}
                    fieldName={fieldNameModel}
                  />
                </div>
                {!isNotModelSpecial && (
                  <ImageMidjourneyRightBar
                    imgSelect={imgSelect}
                    setImgSelect={setImgSelect}
                    setIdAssetIsSelected={setIdAssetIsSelected}
                    resetIsSelect={resetIsSelect}
                  />
                )}
              </div>
            ) : (
              <div
                aria-disabled={tab === 'create'}
                className="aria-disabled:md:hidden w-full h-full lg:min-w-[calc(100%-400px)]"
              >
                <MainHistory
                  models={models}
                  setSateModelDetail={setSateModelDetail}
                  generateUpscaleText2Image={generateUpscaleText2Image}
                  createForm={createForm}
                  modelDetail={modelDetail}
                  optionsSelectStyle={optionsSelectStyle}
                  setOptionsSelectStyle={setOptionsSelectStyle}
                  onChangeModel={(type: string, filterByName?: boolean) => {
                    if (models.items.find((model) => model.type === type) && type) {
                      if (filterByName) {
                        // @ts-ignore
                        setSateModelDetail(models.items.find((model) => model.name === type));
                      } else {
                        // @ts-ignore
                        setSateModelDetail(models.items.find((model) => model.type === type));
                      }
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {isLogin && <RuleWithPlan isOpen={isOpenUpgrade} onOpenChange={onOpenChangeUpgrade} />}
    </div>
  );
};

export default GenModel;
