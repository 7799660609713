import { Divider, Progress, ScrollShadow } from '@nextui-org/react';
import { useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'next/navigation';
import React, { memo, useEffect, useState } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

import { Buttons, SelectBase, UpLoadFileModel } from '@/components';
import ClientOnly from '@/components/client-only';
import { baseModel, generateTypeEnable } from '@/configs/constant';
import { generateTypeControlEnable } from '@/configs/optionsModel';
import AdvancedSetting from '@/contents/gen-model/sideBar/advanced-setting';
import BtnSubmit from '@/contents/gen-model/sideBar/command/BtnSubmit';
import PopupValidateModel from '@/contents/gen-model/sideBar/modal/PopupValidateModel';
import SuggestLora from '@/contents/gen-model/sideBar/modal/SuggestLora';
import type { PromptProviderState } from '@/provider';
import { usePrompt } from '@/provider';
import { getUseCase } from '@/services/usecase';
import { isJSONReturn, useSQuery } from '@/utils';

import SkeletonGen from '../SkeletonGen';
import CampaignGen from './CampaignGen';

interface ControlNetModelProps {
  isLoading: boolean;
  imgSelect: PromptProviderState;
  generateImg: (data: { [key: string]: any }) => void;
  createForm: UseFormReturn<FieldValues, any, undefined>;
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  campaigns?: any;
}

const ControlNetModel: React.FC<ControlNetModelProps> = ({
  isLoading,
  imgSelect,
  generateImg,
  createForm,
  setOptionsSelectStyle,
  campaigns,
}) => {
  const [file, setFile] = useState<File>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    getValues,
  } = createForm;
  const { state, modelConfigRetry, validateFieldError, useModalValidate, tab } = usePrompt();
  const { onOpen } = useModalValidate;
  const searchParams = useSearchParams();
  const queryClient = useQueryClient();
  const [modelConfigSate, setModelConfigSate] = useState<any>(null);
  const listLora: any = queryClient.getQueryData(['listLoras']);
  const img = state.find((item) => item.imageId === imgSelect.imageId);

  const { data: useCase } = useSQuery({
    queryKey: ['get-use-case-id', searchParams.get('template')],
    queryFn: () => getUseCase(Number(searchParams.get('template'))),
    enabled: Boolean(searchParams.get('template')) && !isNaN(Number(searchParams.get('template'))),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const handleChangeFile = (file: File) => {
    setValue('file', file);
    setFile(file);
  };

  const handleClearFile = () => {
    setValue('file', null);
  };

  const onSubmit = async (data: any) => {
    const { file, prompt } = data;
    if (!prompt || !file) {
      validateFieldError.checkRequirePrompt(prompt);
      validateFieldError.checkRequireFile(file);
      return onOpen();
    }
    await generateImg(data);
  };

  const typeControl = () => {
    const result: { value: string; label: string }[] = [];
    Object.entries(generateTypeEnable).map(([typeKey, typeValue]) =>
      result.push({ label: typeKey, value: typeValue }),
    );
    return result;
  };

  useEffect(() => {
    setValue('prompt', img?.prompt);
  }, [imgSelect]);

  useEffect(() => {
    if (img?.generateType && img.file) {
      setFile(img.file);
      setValue('generate_type', img?.generateType);
    } else {
      setValue('generate_type', 'canny');
    }
  }, [img]);

  useEffect(() => {
    if (modelConfigSate) {
      const modelConfig = isJSONReturn(modelConfigSate?.processingAsset?.modelConfig);

      const loraConvert = modelConfig.lora
        ? modelConfig.lora.split(',').map((item: any) => item.split(':'))
        : '';
      const loras = loraConvert
        ? listLora?.data
            .filter((item: any) => loraConvert.some((lora: any) => lora[0] === item.alias))
            .map((lora: any) => ({
              ...lora,
              value: loraConvert.find((item: any) => item[0] === lora.alias)[1],
            }))
        : [];
      if (modelConfig.mode === 'upscale') {
        return setValue('prompt', modelConfigSate?.prompt?.prompt || '');
      }
      reset({
        prompt: modelConfigSate?.prompt?.prompt,
        modelId: modelConfigSate.modelId,
        steps: modelConfig.steps,
        lora: loras || [],
        cfg_scale: modelConfig.cfg_scale,
        control_weight: modelConfig.control_weight,
        generate_type: modelConfig.task,
        seed: modelConfig.seed ?? -1,
      });
      if (modelConfigRetry) {
        queryClient.removeQueries({ queryKey: ['model-config-retry'] });
      }
    } else {
      reset(baseModel);
    }
  }, [listLora, modelConfigSate]);

  useEffect(() => {
    if (useCase || modelConfigRetry) {
      setModelConfigSate(useCase || modelConfigRetry);
    }
  }, [useCase, modelConfigRetry]);

  return (
    <ClientOnly skeleton={<SkeletonGen />}>
      <form
        className="flex h-full flex-col justify-between md:w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <PopupValidateModel />
        <ScrollShadow
          aria-checked={tab === 'result'}
          size={10}
          hideScrollBar
          className="flex flex-col overflow-y-auto md:flex-row aria-checked:md:hidden sm:!flex-col"
        >
          <div className="flex flex-col md:mb-4 md:!w-full">
            <CampaignGen createForm={createForm} campaigns={campaigns} />
            <div>
              <span className="mb-2 flex items-center text-14 font-semibold">
                Control Stable Diffusion with type <span className="text-red-600">*</span>
              </span>
              <div className="mb-5">
                <SelectBase
                  defaultValue={
                    typeControl().find((item) => item.value === watch('generate_type'))?.value
                  }
                  onChange={(e) => setValue('generate_type', e)}
                  list={typeControl()}
                />
              </div>
            </div>
            <SuggestLora createForm={createForm} setOptionsSelectStyle={setOptionsSelectStyle} />
            {errors.exampleRequired && <span>This field is required</span>}
            <span className="mb-2 flex items-center text-14 font-semibold">
              Upload artwork file <span className="ml-1 text-red-600"> *</span>
            </span>
            <div className="w-full">
              <UpLoadFileModel
                handleClearFile={handleClearFile}
                setFile={setFile}
                file={file}
                handleChange={handleChangeFile}
                required={false}
              />
            </div>
            <Divider className="my-5 w-full bg-gray-100" />
            <div className="md:!ml-0 md:!w-full [&>*:first-child]:px-0">
              <div>
                {generateTypeControlEnable.controlOption.options.map((item, index) => (
                  <AdvancedSetting
                    getValues={getValues}
                    setOptionsSelectStyle={setOptionsSelectStyle}
                    register={register}
                    watch={watch}
                    index={index}
                    item={item}
                    setValue={setValue}
                  />
                ))}
              </div>
            </div>
          </div>
        </ScrollShadow>
        <BtnSubmit>
          <div className="md:flex md:items-center md:justify-end">
            <Buttons
              disabled={isLoading || state.some((img) => img?.readyRun)}
              className="w-full p-0"
              type="submit"
            >
              <Progress
                disableAnimation
                value={img?.progress === '0%' ? 100 : Number(img?.progress.slice(0, -1))}
                isIndeterminate={isLoading || img?.readyRun}
                color="primary"
                label={<span className="text-white">Generate</span>}
                className="relative h-full"
                classNames={{
                  labelWrapper: 'contents z-9999',
                  base: 'h-full',
                  track: `h-full ${
                    isLoading || img?.progress !== '100%' || !watch('prompt') || !watch('file')
                      ? 'bg-disabled'
                      : ''
                  }`,
                  label: ['absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-999'],
                }}
              />
            </Buttons>
          </div>
        </BtnSubmit>
      </form>
    </ClientOnly>
  );
};

export default memo(ControlNetModel);
