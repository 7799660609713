import { Switch } from '@nextui-org/react';
import { useSearchParams } from 'next/navigation';
import React, { useEffect } from 'react';

import { ResetIcon } from '@/assets';

interface AdvancedOpsProps {
  handleReset: () => void;
  setOpenAdvanced: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdvancedOps: React.FC<AdvancedOpsProps> = ({ handleReset, setOpenAdvanced }) => {
  const searchParams = useSearchParams();

  useEffect(() => {
    if (searchParams.get('template')) setOpenAdvanced(true);
  }, []);

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <Switch
          defaultSelected={Boolean(searchParams.get('template'))}
          classNames={{
            base: 'h-[22px]',
            wrapper:
              'w-[44px] h-[22px] bg-gray-300 px-[2px] group-data-[selected=true]:bg-green-500',
            thumb: 'group-data-[selected=true]:ml-[19px]',
          }}
          className="mr-3"
          aria-label="Automatic updates"
          onValueChange={(v) => setOpenAdvanced(v)}
        />
        <span className="text-14 font-normal text-gray-500">Advanced mode</span>
      </div>
      <span
        onClick={handleReset}
        className="flex cursor-pointer items-center text-14 font-semibold"
      >
        <ResetIcon className="mr-2" /> RESET
      </span>
    </div>
  );
};

export default AdvancedOps;
