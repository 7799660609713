import { IconRemixProcessing, ImageTempGenItem, ThreeDot } from '@/assets';
import { Images } from '@/components';
import { DeleteIcon, ImagesCountIcon, RetryImageIcon } from '@/components/assets';
import NsfwMark from '@/components/NsfwMark';
import TooltipCustom from '@/components/tooltip-custom';
import LoadingGenerating from '@/contents/gen-model/contentFeed/LoadingGenerating';
import PopupSelectStyle from '@/contents/gen-model/contentFeed/PopupSelectStyle';
import useViewMoreLess from '@/hooks/useViewMoreLess';
import { initialState, PromptProviderState, useHistoryState, usePrompt } from '@/provider';
import { getProcessingAssetByIds, retryProcessingAsset } from '@/services';
import { Models, ResModels } from '@/types';
import { formatMMDD_YYYYHM } from '@/utils';
import {
  Divider,
  Checkbox,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react';
import { useQueryClient } from '@tanstack/react-query';
import { debounce } from 'lodash';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';
import { UseFormReturn, FieldValues } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';

interface MainHistoryProps {
  optionsSelectStyle: string;
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  createForm: UseFormReturn<FieldValues, any, undefined>;
  fieldName?: string;
  models: ResModels;
  modelDetail: Models;
  generateUpscaleText2Image: (imageAsset?: PromptProviderState) => void;
  onChangeModel: (type: string) => void;
  setSateModelDetail?: React.Dispatch<React.SetStateAction<Models | undefined>>;
}

const MainHistory: React.FC<MainHistoryProps> = ({
  models,
  optionsSelectStyle,
  setOptionsSelectStyle,
  createForm,
  fieldName,
  modelDetail,
  generateUpscaleText2Image,
  onChangeModel,
  setSateModelDetail,
}) => {
  const { setIdAssetIsSelected, useModalControl, setIsDelJob, idAssetIsSelected } = usePrompt();
  const [retryImages, setRetryImages] = useState<number[]>([]);
  const [isRetry, setIsRetry] = useState(false);
  const myInterval = useRef<any>();
  const { onOpen } = useModalControl;
  const queryClient = useQueryClient();
  const {
    historyProcessImg,
    setCurrentPage,
    isLoadingHistoryGenerateImage,
    setImgSelect,
    isBeginner,
    isRun,
    setState,
  } = useHistoryState();

  const remixImgs = (processing_asset: PromptProviderState) => {
    if (!setSateModelDetail) return;
    if (!processing_asset?.modelId) return toast.error('model remix not found');

    setSateModelDetail(models.items.find((model) => model.id === processing_asset?.modelId));
    setImgSelect(processing_asset || initialState);
    queryClient.setQueryData(['model-config-retry'], {
      modelId: processing_asset?.modelId,
      prompt: { prompt: processing_asset?.prompt || '' },
      processingAsset: { modelConfig: processing_asset?.modelConfig },
    });
  };

  const handleDelProcess = (processing_assets: PromptProviderState[]) => {
    const arrID: any[] = processing_assets.map((item) => item.assetId) ?? [];
    setIdAssetIsSelected(arrID);
    setIsDelJob(true);
    onOpen();
  };

  const handleRetryProcess = async (processing_asset: PromptProviderState[]) => {
    const assetIdArr = processing_asset.map((asset) => asset.assetId);
    const asset = processing_asset.find((asset) => asset.message);
    if (asset?.message) {
      if (asset.modelName) onChangeModel(asset.modelName);
      remixImgs(asset);
      return toast.error(asset.message);
    }
    if (assetIdArr.every((id) => id)) {
      try {
        const responses = await Promise.all(
          assetIdArr.map(async (id) => {
            if (id) {
              const contents = await retryProcessingAsset(id);
              console.log(contents);
              return contents;
            }
          }),
        );
        if (responses.every((res) => res?.status === 201)) {
          //@ts-ignore
          setRetryImages(assetIdArr);
          setState((items) =>
            items.map((item) => {
              const imgPound = assetIdArr.find((id: any) => id === item.assetId);
              if (imgPound) {
                return {
                  ...item,
                  step: 3,
                  done: false,
                  readyRun: true,
                };
              } else {
                return item;
              }
            }),
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleImageProcess = async (e: React.Key, img: PromptProviderState) => {
    console.log(console.log(e));
    switch (e) {
      case 'SDXL':
        onChangeModel(e);
        remixImgs(img);
        break;
      case 'Text2Image':
        onChangeModel(e);
        remixImgs(img);
        break;
      case 'SDXL-3':
        onChangeModel(e);
        remixImgs(img);
        break;
      case 'DALL-E 2&3':
        onChangeModel(e);
        remixImgs(img);
        break;
      case 'upscale':
        generateUpscaleText2Image(img);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (retryImages.length > 0) {
      setIsRetry(true);
      myInterval.current = setInterval(() => {
        getProcessingAssetByIds(retryImages)
          .then((data: any) => {
            const fetchData = data;
            if (
              fetchData.length > 0 &&
              (fetchData.some((item: any) => item.url) ||
                fetchData.some((item: any) => item.originalUrl))
            ) {
              clearInterval(myInterval.current);
              setState((items) =>
                items.map((item) => {
                  const imgPound = fetchData.find((data: any) => data.id === item.assetId);
                  if (imgPound) {
                    return {
                      ...item,
                      url: imgPound.originalUrl ?? imgPound.url ?? '',
                      imageId: imgPound.originalUrl ?? imgPound.url ?? '',
                      step: 3,
                      done: true,
                      readyRun: false,
                    };
                  } else {
                    return item;
                  }
                }),
              );
            }
            setIsRetry(false);
            setRetryImages([]);
          })
          .catch(() => {
            setState((items) =>
              items.map((item) => {
                const imgPound = retryImages.find((id: any) => id === item.assetId);
                if (imgPound) {
                  return {
                    ...item,
                    step: 3,
                    done: true,
                    readyRun: false,
                  };
                } else {
                  return item;
                }
              }),
            );
            setIsRetry(false);
            setRetryImages([]);
          });
      }, 5000);
    }
  }, [retryImages]);

  if (isBeginner)
    return (
      <div className="flex h-[calc(100%-10px)] w-full flex-col items-center justify-center bg-gray-50 p-6 md:mt-[10px]">
        <PopupSelectStyle
          createForm={createForm}
          modelDetail={modelDetail}
          optionsSelectStyle={optionsSelectStyle}
          setOptionsSelectStyle={setOptionsSelectStyle}
          fieldName={fieldName}
        />
        <Image
          className="mb-10 max-h-[325px] w-full max-w-[435px]"
          src={ImageTempGenItem}
          alt="bg"
        />
        <span className="text-14 font-semibold text-gray-400">
          Your generated artworks will show up here
        </span>
      </div>
    );

  return (
    <div className="py-4 pl-3 w-full h-full md:px-[0px] md:pb-[60px]">
      <PopupSelectStyle
        createForm={createForm}
        modelDetail={modelDetail}
        optionsSelectStyle={optionsSelectStyle}
        setOptionsSelectStyle={setOptionsSelectStyle}
        fieldName={fieldName}
      />
      <div
        className="flex flex-col items-stretch justify-start h-full overflow-hidden overflow-y-auto"
        id="list-history-process"
      >
        <InfiniteScroll
          style={{ overflow: 'visible' }}
          dataLength={historyProcessImg?.length}
          next={() => {
            if (!isLoadingHistoryGenerateImage) setCurrentPage((currentPage) => currentPage + 1);
          }}
          hasMore
          loader=""
          className="!h-full"
          scrollableTarget="list-history-process"
        >
          {historyProcessImg.map((processing_asset, index) => (
            <div
              key={index}
              className="bg-gray-50 mb-4 rounded-[12px] border border-gray-100 w-full"
            >
              <div className="flex items-center py-2 px-[10px] justify-between">
                <div className="flex items-center flex-wrap gap-2">
                  <span className="text-14 font-normal sm:text-12">
                    {formatMMDD_YYYYHM(processing_asset[0]?.createdAt || new Date().toISOString())}
                  </span>
                  {!processing_asset[0]?.readyRun && (
                    <div className="flex items-center bg-orange-50 px-3 py-[6px] rounded-lg sm:py-[4px] sm:px-[6px]">
                      <ImagesCountIcon />{' '}
                      <span className="ml-[6px] text-[13px] text-orange-500 font-semibold">
                        {processing_asset.length}
                      </span>
                    </div>
                  )}
                  <span className="bg-blue-50 text-blue-500 px-3 py-[6px] text-[13px] font-semibold rounded-lg sm:py-[4px] sm:px-[6px]">
                    {processing_asset[0]?.modelName ?? ''}
                  </span>
                  {processing_asset[0]?.upScale && (
                    <span className="bg-blue-50 text-blue-500 px-3 py-[6px] text-[13px] font-semibold rounded-lg sm:py-[4px] sm:px-[6px]">
                      UpScaled
                    </span>
                  )}
                </div>
                <div className="flex items-center">
                  {processing_asset.some((img) => !img.url) && !isRun && (
                    <TooltipCustom
                      showArrow
                      content={
                        <span className="max-w-[370px] text-13 font-normal text-white">
                          Retry process
                        </span>
                      }
                      classNames={{ base: 'tooltip', arrow: 'arrow-tooltip' }}
                    >
                      <RetryImageIcon
                        className="cursor-pointer hover:opacity-70 mr-1"
                        onClick={debounce(() => handleRetryProcess(processing_asset), 1500, {
                          leading: true,
                          trailing: false,
                        })}
                      />
                    </TooltipCustom>
                  )}
                  <TooltipCustom
                    showArrow
                    content={
                      <span className="max-w-[370px] text-13 font-normal text-white">Remix</span>
                    }
                    classNames={{ base: 'tooltip', arrow: 'arrow-tooltip' }}
                  >
                    <IconRemixProcessing
                      onClick={() => processing_asset[0] && remixImgs(processing_asset[0])}
                      className="scale-[0.7] cursor-pointer hover:opacity-70 mr-1"
                    />
                  </TooltipCustom>
                  <TooltipCustom
                    showArrow
                    content={
                      <span className="max-w-[370px] text-13 font-normal text-white">
                        Delete process assets
                      </span>
                    }
                    classNames={{ base: 'tooltip', arrow: 'arrow-tooltip' }}
                  >
                    <DeleteIcon
                      onClick={() => processing_asset && handleDelProcess(processing_asset)}
                      className="cursor-pointer hover:opacity-70"
                    />
                  </TooltipCustom>
                </div>
              </div>
              <Divider className="bg-gray-300" />
              <Prompt prompt={processing_asset[0]?.prompt ?? ''} />
              <div className="w-full grid gap-[10px] grid-cols-4 2xl:grid-cols-3 lg:grid-cols-2 px-[10px] mb-4">
                {processing_asset.map((img: any, index: number) =>
                  img.readyRun ? (
                    <LoadingGenerating isRetry={isRetry} />
                  ) : img.url ? (
                    <div key={index} className="cursor-pointer relative">
                      <Dropdown>
                        <DropdownTrigger>
                          <Image
                            src={ThreeDot}
                            alt="three dot"
                            className="rotate-90 absolute top-[10px] z-50 right-[10px] w-[25px] h-[25px] bg-white rounded-full"
                          />
                        </DropdownTrigger>
                        <DropdownMenu
                          aria-label="select-option-image"
                          onAction={(e) => handleImageProcess(e, img)}
                        >
                          <DropdownItem key="SDXL">Remix with SDXL</DropdownItem>
                          <DropdownItem key="Text2Image">Remix with SD</DropdownItem>
                          <DropdownItem key="SDXL-3">Remix with SD3</DropdownItem>
                          <DropdownItem key="DALL-E 2&3">Remix with DALL-E</DropdownItem>
                          <DropdownItem
                            isDisabled={
                              isRun ||
                              img?.upScale ||
                              (processing_asset[0]?.modelName !== 'Stable Diffusion' &&
                                processing_asset[0]?.modelName !== 'Stable Diffusion XL')
                            }
                            key="upscale"
                            className="text-primary bg-secondary"
                          >
                            Upscale
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <Checkbox
                        isSelected={idAssetIsSelected.some((id) => id === img.assetId)}
                        onValueChange={(v) =>
                          v
                            ? setIdAssetIsSelected((arr) => [...arr, img?.assetId || 0])
                            : setIdAssetIsSelected((arr) => arr.filter((e) => e !== img.assetId))
                        }
                        classNames={{ base: 'absolute top-[10px] z-50 left-[10px]' }}
                      />
                      {img.type === 'image' ? (
                        <Images
                          className="rounded-lg object-contain bg-black"
                          alt={img.prompt}
                          src={img.url}
                          isFallbackLoading
                          zoom
                        />
                      ) : (
                        <video
                          controls
                          className="rounded-lg min-h-[220px] object-contain"
                          src="https://videos.pexels.com/video-files/27021086/12049415_640_360_30fps.mp4"
                        />
                      )}
                      {img.isNsfw && <NsfwMark url={img.url} />}
                    </div>
                  ) : (
                    <LoadingGenerating noPercentage />
                  ),
                )}
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default MainHistory;

const Prompt: React.FC<{ prompt: string }> = ({ prompt }) => {
  const { expanded, toggleExpanded, textRef, shouldShowButton } = useViewMoreLess({
    maxLines: 2,
    defaultExpanded: false,
  });
  return (
    <div className="p-[10px]">
      <span ref={textRef} className="text-13 font-normal line-clamp-2">
        {prompt}
      </span>
      {shouldShowButton && (
        <p
          className="cursor-pointer hover:underline text-12 font-normal text-orange-500"
          onClick={toggleExpanded}
        >
          {expanded ? 'Hide' : 'Show More'}
        </p>
      )}
    </div>
  );
};
