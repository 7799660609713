import { Button } from '@nextui-org/react';
import React, { useState } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

import { IconAiSuggest, IconAiSuggestBlack, UseCaseIcon } from '@/assets';
import { getSuggestPrompt } from '@/services';

interface SuggestLoraProps {
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  createForm: UseFormReturn<FieldValues, any, undefined>;
}

const SuggestLora: React.FC<SuggestLoraProps> = ({ setOptionsSelectStyle, createForm }) => {
  const { register, watch, setValue } = createForm;
  const [selectLora, setSelectLora] = useState<any[]>([]);
  const [suggestPrompt, setSuggestPrompt] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const handleTriggerLora = (trigger: string, contain: boolean) => {
    if (selectLora.includes(trigger) || contain) {
      setValue('prompt', `${watch('prompt').replace(` ${trigger}`, '')}`);
      setSelectLora((items) => items.filter((item) => item !== trigger));
    } else {
      setValue('prompt', `${watch('prompt')} ${trigger}`);
      setSelectLora((items) => [...items, trigger]);
    }
  };

  const handleSuggestPrompt = async () => {
    setLoading(true);
    const prompt = watch('prompt');
    if (prompt) {
      try {
        const res = await getSuggestPrompt(prompt);
        if (res.status === 200) {
          setSuggestPrompt([res.data]);
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      try {
        const res = await getSuggestPrompt('');
        if (res.status === 200) {
          setSuggestPrompt([res.data]);
        }
      } catch (error) {
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const handleSelectPrompt = (prompt: string) => {
    setValue('prompt', prompt);
    setSuggestPrompt((prompts) => prompts.filter((item) => item !== prompt));
  };

  return (
    <>
      <div className="relative my-5">
        <div className="mb-2 flex items-center justify-between">
          <div className="text-14 font-semibold">
            Prompt
            <span className="text-primary">*</span>
          </div>
          <div
            onClick={() => setOptionsSelectStyle('use-case')}
            className="flex cursor-pointer items-center text-13 font-semibold text-primary"
          >
            <UseCaseIcon className="mr-[6px]" /> Template
          </div>
        </div>
        <textarea
          defaultValue=""
          className="min-h-[149px] w-full resize-none rounded-[16px] border-none bg-gray-50 px-4 py-[14px] text-black outline-none"
          placeholder="Write a detailed description of how you want your artwork to look like or select from My prompts, or both"
          {...register('prompt')}
        />
        <Button
          isLoading={loading}
          onClick={handleSuggestPrompt}
          className="shadow-card-transparent absolute bottom-[16px] right-[8px] flex max-h-[29px] max-w-[130px] cursor-pointer items-center rounded-[30px] bg-white px-3 py-[5px] aria-disabled:cursor-not-allowed"
        >
          <IconAiSuggest />{' '}
          <p className="text-12 font-normal aria-disabled:opacity-50 sm:hidden">AI suggestion</p>
        </Button>
      </div>

      {suggestPrompt?.length > 0 && (
        <div className="mb-5 flex flex-wrap gap-2">
          {suggestPrompt?.map((value: any, index: number) => (
            <span
              className="relative flex cursor-pointer items-start rounded-[10px] border-1 border-orange-200 bg-[#FFF8F6] px-2 py-1 text-12 font-normal"
              key={index}
              onClick={() => handleSelectPrompt(value)}
            >
              <IconAiSuggestBlack className="absolute left-[10px] top-[3px]" />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{value}
            </span>
          ))}
        </div>
      )}

      {watch('lora')
        ?.map((item: any) => item.trigger_words)
        .flat().length > 0 && (
        <div className="relative mb-5 rounded-[16px] border border-gray-200 p-3">
          <span className="absolute top-[-10px] bg-white text-12 font-semibold text-gray-700">
            Suggested prompt from LoRA
          </span>
          <div className="flex flex-wrap gap-2">
            {watch('lora')
              ?.map((item: any) => item.trigger_words)
              .flat()
              ?.map((value: any, index: number) => (
                <span
                  aria-expanded={watch('prompt')?.includes(value)}
                  className="min-w-[34px] cursor-pointer rounded-full border border-gray-100 bg-gray-50 px-3 py-[6px] text-12 font-normal text-gray-500 aria-expanded:border-black aria-expanded:text-black"
                  key={index}
                  onClick={() => handleTriggerLora(value, watch('prompt')?.includes(value))}
                >
                  {value}
                </span>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SuggestLora;
