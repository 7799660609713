import { Button, Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/react';
import Link from 'next/link';
import React from 'react';

import { IconCloseGray2828 } from '@/assets';
import { SIDEBAR_MENU_LINK } from '@/layouts/sidebar';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TRACK } from '@/configs/constant';

const RuleWithPlan: React.FC<{
  isOpen: boolean;
  onOpenChange: () => void;
}> = ({ isOpen, onOpenChange }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        hideCloseButton
        onOpenChange={onOpenChange}
        placement="center"
        backdrop="opaque"
        className="relative p-7"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex items-center justify-between p-0">
                <span className="mr-5 text-[20px] font-semibold leading-[34px]">
                  Opps! You need to upgrade to use all features
                </span>
                <Button
                  className="absolute right-2 top-2"
                  isIconOnly
                  variant="light"
                  onClick={onClose}
                >
                  <IconCloseGray2828 />
                </Button>
              </ModalHeader>
              <ModalBody className="p-0 pt-3">
                <div className="gap-1 text-[14px]">
                  <span className="text-gray-700">Subscribe to aiRight Plus plan:</span>
                  <Link
                    onClick={() => mixpanel.track(MIXPANEL_TRACK.UPGRADE_PRICING)}
                    className="ml-1 font-semibold text-orange-500 underline"
                    href={`${SIDEBAR_MENU_LINK.pricing}`}
                    target="_blank"
                  >
                    Upgrade
                  </Link>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default RuleWithPlan;
