import React from 'react';

import { InfoGenIcon } from '@/assets';
import { useRouter } from '@/provider/RouterProvider';
import TooltipCustom from '@/components/tooltip-custom';

const ModelRequestNotificationLimit = ({}) => {
  const router = useRouter();

  const listCredit = [
    { title: 'Gen aiRight models', cost: 0 },
    { title: 'Gen Midjourney', cost: 15 },
    { title: 'Gen Dall-E', cost: 4 },
    { title: 'Gen Stable Diffusion 3', cost: 15 },
    { title: 'Use tools in AI models', cost: 2 },
  ];

  return (
    <TooltipCustom
      showArrow
      content={
        <div className="max-w-[300px] px-1 py-4 text-black">
          <span className="">
            <p className="text-16 font-semibold leading-6">Credits cost</p>
            <span className="pt-2 text-14 font-normal leading-5 text-gray-500">
              Learn how each AI model costs, you can buy more with{' '}
              <a
                className="cursor-pointer text-14 font-semibold text-orange-500"
                onClick={() => router.push('/pricing')}
              >
                Credits
              </a>{' '}
              as a Plus member.
            </span>
          </span>
          <hr className="mb-1 mt-4" />
          {listCredit.map((item) => (
            <div className="pt-3">
              <div className="flex justify-between">
                <span className="text-14 font-normal text-gray-600">{item.title}</span>
                <span className="text-14 font-semibold">{item.cost} credit</span>
              </div>
            </div>
          ))}
        </div>
      }
    >
      <span className="ml-2">
        <InfoGenIcon />
      </span>
    </TooltipCustom>
  );
};

export default ModelRequestNotificationLimit;
