import { Divider } from '@nextui-org/react';
import React, { useState } from 'react';
import type {
  FieldValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { UseCaseIcon } from '@/assets';
import { parametersOfMidjourney } from '@/configs/paramsMidjourney';
import SuggestPrompt from '@/contents/gen-model/sideBar/command/SuggestPrompt';
import AdvancedOps from '@/contents/gen-model/sideBar/custom/AdvancedOps';
import ParamsCustomer from '@/contents/gen-model/sideBar/custom/ParamsCustomer';
import type { PromptProviderState } from '@/provider';
import TooltipCustom from '@/components/tooltip-custom';

interface CreateImageProps {
  img: PromptProviderState | undefined;
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  reset: UseFormReset<FieldValues>;
}

const CreateImage: React.FC<CreateImageProps> = ({
  img,
  register,
  watch,
  setValue,
  setOptionsSelectStyle,
  reset,
}) => {
  const [param, setParam] = useState('aspect');
  const [openAdvance, setOpenAdvance] = useState(false);
  const handleSetParam = (param: string) => {
    if (param === 'tile') {
      setValue('prompt', `${watch('prompt')} ${'--tile'}`);
    }
    setParam(param);
  };

  return (
    <>
      <div>
        <div className="mb-2 flex items-center justify-between">
          <div className="text-14 font-semibold">Prompt</div>
          <div
            onClick={() => setOptionsSelectStyle('use-case')}
            className="flex cursor-pointer items-center text-13 font-semibold text-primary"
          >
            <UseCaseIcon className="mr-[6px]" /> Template
          </div>
        </div>
        <SuggestPrompt createForm={{ watch, setValue }}>
          <textarea
            defaultValue={img?.prompt}
            className="min-h-[149px] w-full resize-none rounded-[16px] border-none bg-gray-50 px-4 py-[14px] text-black outline-none"
            placeholder="the prompt --ar 1:1 --niji 5 --style original"
            {...register('prompt')}
          />
        </SuggestPrompt>

        <Divider className="my-5 w-full bg-gray-100" />
        <AdvancedOps
          setOpenAdvanced={setOpenAdvance}
          handleReset={() => reset({ files: [], prompt: '' })}
        />
        <Divider className="my-5 w-full bg-gray-100" />
        <div
          aria-checked={!openAdvance}
          className="aria-checked:pointer-events-none aria-checked:opacity-[0.35]"
        >
          <div className="mb-2">
            <span className="text-14 font-semibold">Params</span>
          </div>
          <div className="mb-4 flex flex-wrap items-center gap-2">
            {parametersOfMidjourney.map((item, index) => (
              <TooltipCustom
                delay={1500}
                showArrow
                content={
                  <span className="max-w-[370px] text-13 font-normal text-white">
                    {item.description}
                  </span>
                }
                classNames={{ base: 'tooltip', arrow: 'arrow-tooltip' }}
              >
                <span
                  aria-expanded={item.name === (param || 'aspect')}
                  className="cursor-pointer rounded-full bg-gray-200 px-3 py-[6px] text-12 font-normal aria-expanded:bg-gray-400 aria-expanded:text-white"
                  key={index}
                  onClick={() => handleSetParam(item.name)}
                >
                  {item.name}
                </span>
              </TooltipCustom>
            ))}
          </div>
          <Divider className="mb-4 w-full bg-gray-100" />
          <div className="mb-2">
            <span className="text-14 font-semibold">Value</span>
          </div>
          <div className="mb-4 flex flex-wrap items-center gap-2">
            <ParamsCustomer
              watch={watch}
              setValue={setValue}
              param={parametersOfMidjourney.find((item) => item.name === (param || 'aspect'))}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateImage;
