import type { ReactSketchCanvasRef } from 'react-sketch-canvas';

export const fillColorBackground = (canvas: HTMLCanvasElement, color: string) => {
  const ctx = canvas.getContext('2d');
  if (!ctx) return;
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
};

export const canvasToImage = (canvas: HTMLCanvasElement) => {
  return canvas.toDataURL();
};

export const canvasDrawToImage = async (canvas: ReactSketchCanvasRef) => {
  const data = await canvas.exportImage('png');
  return data;
};

export const onClear = (canvas: ReactSketchCanvasRef | null) => {
  if (canvas === null) return;
  canvas.clearCanvas();
};

export const onUndo = (canvas: ReactSketchCanvasRef | null) => {
  if (canvas === null) return;
  canvas.undo();
};

export const onRedo = (canvas: ReactSketchCanvasRef | null) => {
  if (canvas === null) return;
  canvas.redo();
};

export const resizeImage = (base64Str: string, width: number, height: number) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = base64Str;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      if (!ctx) return '';
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
  });
};

export const imageBase64ToFile = async (
  base64: string,
  name: string,
  type: string,
): Promise<File> => {
  const res = await fetch(base64);
  const blob = await res.blob();
  return new File([blob], name, { type });
};
