import { Button } from '@nextui-org/react';
import mixpanel from 'mixpanel-browser';
import Image from 'next/image';
import React, { memo, useMemo } from 'react';
import { toast } from 'react-toastify';
import { match } from 'ts-pattern';

import { IconLightningPublish, ImageTempGenItem } from '@/assets';
import { Images } from '@/components';
import { CardFileRenderFollowType } from '@/components/card';
import { MIXPANEL_TRACK } from '@/configs/constant';
import useGetWidth from '@/hooks/useGetWidth';
import type { PromptProviderState } from '@/provider';
import { usePrompt } from '@/provider';
import { useAuth } from '@/provider/UserProvider';
import { publishTemplate } from '@/services/usecase';

interface GenModelDisplayProps {
  loading: boolean;
  model: string;
  isNotModelSpecial: boolean;
  imgSelect: PromptProviderState;
  generateUpscaleText2Image: () => void;
}

const GenModelDisplay: React.FC<GenModelDisplayProps> = ({
  model,
  loading,
  imgSelect,
  isNotModelSpecial,
  generateUpscaleText2Image,
}) => {
  const screenWidth = useGetWidth();
  const { isLogin } = useAuth();
  const { state } = usePrompt();
  const img = state.find((item) => item.imageId === imgSelect.imageId);
  const publishAsset = async (id: number) => {
    const res = await publishTemplate([id]);
    if (res) {
      toast.success('Published successfully');
    }
  };

  const RenderImgFollowType = useMemo(() => {
    return img?.file ? (
      <CardFileRenderFollowType
        file={img?.file}
        type={img?.file.type.split('/')[0] ?? ''}
        isControl
        isNotBorder
        enablerAnimation
      />
    ) : (
      <></>
    );
  }, [img?.file]);

  return (
    <>
      {match(img?.step)
        .with(1, () => (
          <div className="flex h-full flex-col items-center justify-center bg-gray-50 p-6">
            <Image
              className="mb-10 max-h-[325px] w-full max-w-[435px]"
              src={ImageTempGenItem}
              alt="bg"
            />
            <span className="text-14 font-semibold text-gray-400">
              Your generated artworks will show up here
            </span>
          </div>
        ))
        .with(2, () => {
          return (
            <div className="group relative flex h-full items-center justify-center bg-gray-100 p-6 md:p-0">
              <Images
                zoom
                imageWrapper="group-hover:bg-[#0000004d]"
                isFallbackLoading
                className="object-contain group-hover:opacity-70"
                src={img?.url}
                alt="bg"
              />
              <div className="invisible absolute bottom-[40px] z-99 flex items-center gap-2 group-hover:visible xl:visible md:bottom-[20px]">
                <Button
                  className=" flex items-center rounded-[100px] bg-orange-500 py-[10px] pl-[20px] pr-[16px] group-hover:visible xl:visible"
                  onClick={() => {
                    isLogin
                      ? img?.assetId && publishAsset(img.assetId)
                      : toast.warning('You must login to publish images!');
                  }}
                >
                  <IconLightningPublish />
                  <span className="text-15 font-semibold leading-[25.5px] text-white">Publish</span>
                </Button>
                {model !== 'Midjourney' &&
                  model !== 'DALL-E 2&3' &&
                  model !== 'SDXL-3' &&
                  !img?.upScale && (
                    <div
                      aria-expanded={screenWidth < 992}
                      className=" justify-center group-hover:flex aria-expanded:flex"
                    >
                      <Button
                        aria-disabled={loading || state.some((img) => img?.readyRun)}
                        disabled={loading || state.some((img) => img?.readyRun)}
                        onClick={() => {
                          isLogin
                            ? generateUpscaleText2Image()
                            : toast.warning('You must login to continue!');
                        }}
                        size="sm"
                        className="mr-1 min-w-0 cursor-pointer rounded-full bg-white p-3 px-[20px] py-5 text-15 font-semibold aria-disabled:cursor-not-allowed"
                      >
                        Upscale
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          );
        })
        .otherwise(() => (
          <div className="group relative flex h-full items-center justify-center bg-gray-100 p-6 md:p-0">
            {isNotModelSpecial && img?.file ? (
              RenderImgFollowType
            ) : (
              <>
                <Images zoom className="object-contain" src={img?.url} alt="bg" isFallbackLoading />
              </>
            )}
            <div className="absolute bottom-[40px] z-99 flex items-center gap-2">
              <Button
                className="invisible flex items-center rounded-[100px] bg-orange-500 py-[10px] pl-[20px] pr-[16px] group-hover:visible xl:visible"
                onClick={() => {
                  mixpanel.track(MIXPANEL_TRACK.PUSHLISH_ART);
                  isLogin
                    ? img?.assetId && publishAsset(img.assetId)
                    : toast.warning('You must login to publish images!');
                }}
              >
                <IconLightningPublish />
                <span className="text-15 font-semibold leading-[25.5px] text-white">Publish</span>
              </Button>
              {model !== 'Midjourney' &&
                model !== 'DALL-E 2&3' &&
                model !== 'SDXL-3' &&
                !img?.upScale && (
                  <div
                    aria-expanded={screenWidth < 992}
                    className="hidden justify-center group-hover:flex aria-expanded:flex"
                  >
                    <Button
                      aria-disabled={loading || state.some((img) => img?.readyRun)}
                      disabled={loading || state.some((img) => img?.readyRun)}
                      onClick={() => {
                        isLogin
                          ? generateUpscaleText2Image()
                          : toast.warning('You must login to continue!');
                      }}
                      size="sm"
                      className="mr-1 min-w-0 cursor-pointer rounded-full bg-white p-3 px-[20px] py-5 text-15 font-semibold aria-disabled:cursor-not-allowed"
                    >
                      Upscale
                    </Button>
                  </div>
                )}
            </div>
          </div>
        ))}
    </>
  );
};

export default memo(GenModelDisplay);
