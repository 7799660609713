import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { IconCheckWhite, IconLeft } from '@/assets';
import { Images } from '@/components';
import { transferBaseModel, transferUseCase } from '@/contents/gen-model/helpers/usecase';
import { getCampaignExample } from '@/services/campaign';
import type { Models } from '@/types';
import { isJSONReturn, useSQuery } from '@/utils';
import NsfwMark from '@/components/NsfwMark';

interface SelectExampleCampaignProps {
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  modelDetail: Models;
  createForm: UseFormReturn<FieldValues, any, undefined>;
}

const SelectExampleCampaign: React.FC<SelectExampleCampaignProps> = ({
  setOptionsSelectStyle,
  modelDetail,
  createForm,
}) => {
  const [selectItem, setSelectItem] = useState<any>({ id: '' });
  const { reset, watch } = createForm;
  const campaignId = watch('campaignId');
  const queryClient = useQueryClient();
  const listLora: any = queryClient.getQueryData(['listLoras']);

  const { data: campaignExample } = useSQuery({
    queryKey: ['campaign-example'],
    queryFn: () => getCampaignExample({ campaignId, modelId: modelDetail.id }),
    enabled: Boolean(campaignId),
  });

  const example =
    Boolean(campaignId) &&
    campaignExample &&
    campaignExample
      ?.filter(
        (useCase: any) =>
          useCase?.processingAsset?.modelConfig &&
          (useCase?.processingAsset?.modelConfig !== '{}' || modelDetail.type === 'Midjourney'),
      )
      .map((useCase: any) => ({
        ...useCase,
        processingAsset: {
          ...useCase.processingAsset,
          modelConfig: isJSONReturn(useCase?.processingAsset?.modelConfig),
        },
      }));

  const handleSelect = (item: any) => {
    if (item.id === selectItem.id) {
      setSelectItem('');
      reset({ ...transferBaseModel(modelDetail.type, watch), campaignId });
    } else {
      const { modelConfig } = item.processingAsset;
      setSelectItem(item);
      reset({
        ...transferUseCase(modelDetail.type, item, modelConfig, watch, listLora),
        campaignId,
      });
    }
  };

  return (
    <div className="h-full">
      <div className="flex items-center justify-between border-b-1 border-gray-100 p-6">
        <h2
          onClick={() => setOptionsSelectStyle('')}
          className="flex cursor-pointer items-center text-18 font-semibold lg:text-14"
        >
          <IconLeft className="mr-2" /> Example campaign
        </h2>
      </div>
      <h2 className="border-b-1 border-gray-100 px-6 py-5 text-[20px] font-semibold">
        Get ideas from the below NFT prompt
      </h2>
      <ResponsiveMasonry
        className="scrollbar-custom h-[calc(100%-167px)] overflow-hidden overflow-y-auto p-6"
        columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 4, 1200: 6 }}
      >
        <Masonry gutter="12px">
          {example &&
            example.map((item: any) => (
              <div
                onClick={() => {
                  handleSelect(item);
                  setOptionsSelectStyle('');
                }}
                key={item}
                className="relative flex h-fit cursor-pointer flex-col rounded-[16px] bg-gray-50 p-2"
              >
                <Images
                  imageWrapper="w-full mb-1 h-[180px]"
                  className="h-auto w-full rounded-[10px] object-contain object-center"
                  src={item.processingAsset.url}
                  alt={item.processingAsset.url}
                  isFallbackLoading
                  skeleton
                />
                {item.processingAsset.isNsfw && <NsfwMark className="rounded-[10px] overflow-hidden" url={item.processingAsset.url} />}
                {selectItem.id === item.id && (
                  <div className="absolute right-[5px] top-[5px] z-10 flex h-7 w-7 items-center justify-center rounded-[50%] bg-green-500">
                    <IconCheckWhite />
                  </div>
                )}
              </div>
            ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

export default SelectExampleCampaign;
