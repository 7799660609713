import SelectExampleCampaign from '@/contents/gen-model/contentFeed/SelectExampleCampaign';
import SelectLora from '@/contents/gen-model/contentFeed/SelectLora';
import SelectModel from '@/contents/gen-model/contentFeed/SelectModel';
import SelectUseCase from '@/contents/gen-model/contentFeed/SelectUseCase';
import useGetWidth from '@/hooks/useGetWidth';
import { Models } from '@/types';
import { useDisclosure, Modal, ModalContent } from '@nextui-org/react';
import React from 'react';
import { UseFormReturn, FieldValues } from 'react-hook-form';
import { match } from 'ts-pattern';

interface PopupSelectStyleProps {
  optionsSelectStyle: string;
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  createForm: UseFormReturn<FieldValues, any, undefined>;
  fieldName?: string;
  modelDetail: Models;
}

const PopupSelectStyle: React.FC<PopupSelectStyleProps> = ({
  createForm,
  fieldName = 'model',
  optionsSelectStyle,
  setOptionsSelectStyle,
  modelDetail,
}) => {
  const widthScreen = useGetWidth();
  const { watch } = createForm;
  const { isOpen, onOpenChange } = useDisclosure({
    onClose: () => setOptionsSelectStyle(''),
    isOpen: Boolean(optionsSelectStyle),
  });

  const isShowOptions =
    optionsSelectStyle === 'use-case' ||
    optionsSelectStyle === 'model' ||
    optionsSelectStyle === 'lora' ||
    optionsSelectStyle === 'example-campaign';

  return (
    <div aria-checked={isShowOptions} className="hidden h-full w-full aria-checked:block">
      {match(optionsSelectStyle)
        .with('use-case', () =>
          widthScreen < 768 ? (
            <Modal
              classNames={{ base: 'max-w-none w-full !m-0' }}
              className="h-[calc(100dvh-30px)] w-[calc(100vw-30px)] overflow-x-hidden"
              isOpen={isOpen}
              placement="center"
              hideCloseButton
              onOpenChange={onOpenChange}
            >
              <ModalContent>
                {() => (
                  <SelectUseCase
                    createForm={createForm}
                    modelDetail={modelDetail}
                    optionsSelectStyle={optionsSelectStyle}
                    setOptionsSelectStyle={setOptionsSelectStyle}
                  />
                )}
              </ModalContent>
            </Modal>
          ) : (
            <Modal isOpen={isOpen} placement="center" onOpenChange={onOpenChange}>
              <ModalContent className="relative h-[calc(100dvh-80px)] max-h-none min-h-[calc(100dvh-80px)] w-[calc(100vw-130px)] min-w-[calc(100vw-130px)] rounded-[12px] sm:!m-0">
                {() => (
                  <SelectUseCase
                    createForm={createForm}
                    modelDetail={modelDetail}
                    optionsSelectStyle={optionsSelectStyle}
                    setOptionsSelectStyle={setOptionsSelectStyle}
                  />
                )}
              </ModalContent>
            </Modal>
          ),
        )
        .with('model', () =>
          widthScreen < 768 ? (
            <Modal
              classNames={{ base: 'max-w-none w-full !m-0' }}
              className="h-[calc(100dvh-30px)] w-[calc(100vw-30px)] overflow-x-hidden"
              isOpen={isOpen}
              placement="center"
              hideCloseButton
            >
              <ModalContent>
                {() => (
                  <SelectModel
                    modelDetail={modelDetail}
                    createForm={createForm}
                    defaultValue={watch(fieldName)}
                    fieldName={fieldName}
                    setOptionsSelectStyle={setOptionsSelectStyle}
                  />
                )}
              </ModalContent>
            </Modal>
          ) : (
            <Modal
              classNames={{ closeButton: 'z-10' }}
              isOpen={isOpen}
              placement="center"
              onOpenChange={onOpenChange}
            >
              <ModalContent className="relative h-[calc(100dvh-80px)] max-h-none min-h-[calc(100dvh-80px)] w-[calc(100vw-130px)] min-w-[calc(100vw-130px)] rounded-[12px] sm:!m-0">
                {() => (
                  <SelectModel
                    modelDetail={modelDetail}
                    createForm={createForm}
                    defaultValue={watch(fieldName)}
                    fieldName={fieldName}
                    setOptionsSelectStyle={setOptionsSelectStyle}
                  />
                )}
              </ModalContent>
            </Modal>
          ),
        )
        .with('lora', () =>
          widthScreen < 768 ? (
            <Modal
              classNames={{ base: 'max-w-none w-full !m-0' }}
              className="h-[calc(100dvh-30px)] w-[calc(100vw-30px)] overflow-x-hidden"
              isOpen={isOpen}
              placement="center"
              hideCloseButton
            >
              <ModalContent>
                {() => (
                  <SelectLora
                    createForm={createForm}
                    fieldName="lora"
                    setOptionsSelectStyle={setOptionsSelectStyle}
                  />
                )}
              </ModalContent>
            </Modal>
          ) : (
            <Modal isOpen={isOpen} placement="center" onOpenChange={onOpenChange}>
              <ModalContent className="relative h-[calc(100dvh-80px)] max-h-none min-h-[calc(100dvh-80px)] w-[calc(100vw-130px)] min-w-[calc(100vw-130px)] rounded-[12px] sm:!m-0">
                {() => (
                  <SelectLora
                    createForm={createForm}
                    fieldName="lora"
                    setOptionsSelectStyle={setOptionsSelectStyle}
                  />
                )}
              </ModalContent>
            </Modal>
          ),
        )
        .with('example-campaign', () =>
          widthScreen < 768 ? (
            <Modal
              classNames={{ base: 'max-w-none w-full !m-0 rounded-b-none' }}
              className="h-3/4 w-screen"
              isOpen={isOpen}
              placement="bottom"
              onOpenChange={onOpenChange}
              hideCloseButton
            >
              <ModalContent>
                {() => (
                  <SelectExampleCampaign
                    modelDetail={modelDetail}
                    createForm={createForm}
                    setOptionsSelectStyle={setOptionsSelectStyle}
                  />
                )}
              </ModalContent>
            </Modal>
          ) : (
            <Modal
              classNames={{ closeButton: 'z-10' }}
              isOpen={isOpen}
              placement="center"
              onOpenChange={onOpenChange}
            >
              <ModalContent className="relative h-[calc(100dvh-80px)] max-h-none min-h-[calc(100dvh-80px)] w-[calc(100vw-130px)] min-w-[calc(100vw-130px)] rounded-[12px] sm:!m-0">
                {() => (
                  <SelectExampleCampaign
                    modelDetail={modelDetail}
                    createForm={createForm}
                    setOptionsSelectStyle={setOptionsSelectStyle}
                  />
                )}
              </ModalContent>
            </Modal>
          ),
        )
        .otherwise(() => (
          <></>
        ))}
    </div>
  );
};

export default PopupSelectStyle;
