import { Button, Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/react';
import React from 'react';

import { IconCloseGray2828 } from '@/assets';
import { configs } from '@/configs';

const ModelGenOutOfCredits: React.FC<{
  isOpen: boolean;
  onOpenChange: () => void;
}> = ({ isOpen, onOpenChange }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        hideCloseButton
        onOpenChange={onOpenChange}
        placement="center"
        backdrop="opaque"
        className="relative p-7"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex items-center justify-between p-0">
                <span className="mr-5 text-[20px] font-semibold leading-[34px]">
                  Opps! Do not have enough credits to continue
                </span>
                <Button
                  className="absolute right-2 top-2"
                  isIconOnly
                  variant="light"
                  onClick={onClose}
                >
                  <IconCloseGray2828 />
                </Button>
              </ModalHeader>
              <ModalBody className="p-0 pt-3">
                <div className="gap-1 text-[14px]">
                  <span className="text-gray-700">💸 Buy more credits now:</span>
                  <a
                    className="ml-1 font-semibold text-orange-500 underline"
                    href={`${configs.ssoCallback}/pricing`}
                    target="_blank"
                  >
                    Buy Credits
                  </a>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModelGenOutOfCredits;
