import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { IconCheckWhite, IconLeft } from '@/assets';
import { Images } from '@/components';

const SelectLora: React.FC<{
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  createForm: UseFormReturn<FieldValues, any, undefined>;
  fieldName: string;
}> = ({ setOptionsSelectStyle, createForm, fieldName }) => {
  const { setValue, watch } = createForm;
  const queryClient = useQueryClient();
  const dataListLoras: any = queryClient.getQueryData(['listLoras']);

  const handleSelect = (lora: any) => {
    if (watch(fieldName).some((item: any) => item.alias === lora.alias)) {
      const rest = watch(fieldName).filter((item: any) => item.alias !== lora.alias);
      setValue(fieldName, rest);
    } else {
      setValue(fieldName, [...watch(fieldName), { ...lora, value: 1 }]);
    }
  };

  return (
    <div className="h-full">
      <div className="flex items-center justify-between border-b-1 border-gray-100 p-6 sm:p-4 sm:py-3">
        <h2
          onClick={() => setOptionsSelectStyle('')}
          className="flex cursor-pointer items-center whitespace-nowrap text-18 font-semibold lg:text-14"
        >
          <IconLeft className="mr-2 lg:mr-1 lg:scale-75" /> Select a lora
        </h2>
      </div>
      {dataListLoras && (
        <ResponsiveMasonry
          className="scrollbar-custom h-[calc(100%-89px)] overflow-hidden overflow-y-auto p-6 sm:h-[calc(100%-73px)] sm:p-4"
          columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 4, 1200: 6 }}
        >
          <Masonry gutter="12px">
            {dataListLoras?.data?.map((item: any) => (
              <div
                key={item.alias}
                onClick={() => handleSelect(item)}
                className="relative flex h-auto cursor-pointer flex-col rounded-[16px] bg-gray-50 p-2"
              >
                <Images
                  imageWrapper="w-full !h-auto"
                  className="h-auto w-full rounded-[10px] object-cover object-top"
                  src={item.preview[0]}
                  alt="lora"
                  isFallbackLoading
                  skeleton
                />
                {watch(fieldName)?.some((sel: any) => sel.alias === item.alias) && (
                  <div className="absolute right-[5px] top-[5px] z-10 flex h-7 w-7 items-center justify-center rounded-[50%] bg-green-500">
                    <IconCheckWhite />
                  </div>
                )}
                <span className="mt-2 line-clamp-2 break-words text-13 font-semibold leading-[1.5]">
                  {item.name}
                </span>
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      )}
    </div>
  );
};

export default SelectLora;
