export enum USER_PLAN {
  FREE = 1,
  STARTER = 2,
  PLUS = 3,
}

export enum PLAN_RULES {
  BATCH_SIZE = 'batch_size',
  HR_SCALE_BY = 'hr_scale_by',
  UPSCALER = 'upscaler',
}

interface ConfigRules {
  batch_size?: number,
  hr_scale_by?: number,
  enable_hires?: boolean,
  has_check_upscaler?: boolean,
}

interface PassRuleRespone {
  isPassRule: boolean,
  info: string[],
}

const isPassBatchSize = (max: number, batchSize?: number) => {
  return batchSize ? batchSize <= max : true;
}

const isPassHrScaleBy = (max: number, hrScaleBy?: number, enableHires?: boolean) => {
  if (!enableHires) return true;
  return hrScaleBy ? hrScaleBy <= max : true;
}

const checkPassRuleWithFree = (data: ConfigRules): PassRuleRespone => {
  const { batch_size, hr_scale_by, enable_hires, has_check_upscaler } = data;
  let info: string[] = [];

  if (!isPassBatchSize(1, batch_size)) {
    info = [...info, PLAN_RULES.BATCH_SIZE]
  }
  if (!isPassHrScaleBy(1.5, hr_scale_by, enable_hires)) {
    info = [...info, PLAN_RULES.HR_SCALE_BY]
  }
  if (has_check_upscaler) {
    info = [...info, PLAN_RULES.UPSCALER]
  }

  return {
    isPassRule: info.length === 0,
    info,
  }
}

const checkPassRuleWithStarter = (data: ConfigRules): PassRuleRespone => {
  const { batch_size, hr_scale_by, enable_hires } = data;
  let info: string[] = [];

  if (!isPassBatchSize(2, batch_size)) {
    info = [...info, PLAN_RULES.BATCH_SIZE]
  }
  if (!isPassHrScaleBy(2, hr_scale_by, enable_hires)) {
    info = [...info, PLAN_RULES.HR_SCALE_BY]
  }

  return {
    isPassRule: info.length === 0,
    info,
  }
}

const checkPassRuleWithPlus = (data: ConfigRules): PassRuleRespone => {
  const { batch_size, hr_scale_by, enable_hires } = data;
  let info: string[] = [];

  if (!isPassBatchSize(4, batch_size)) {
    info = [...info, PLAN_RULES.BATCH_SIZE]
  }
  if (!isPassHrScaleBy(4, hr_scale_by, enable_hires)) {
    info = [...info, PLAN_RULES.HR_SCALE_BY]
  }

  return {
    isPassRule: info.length === 0,
    info,
  }
}

const CHECK_PASS_RULE = {
  [USER_PLAN.FREE]: checkPassRuleWithFree,
  [USER_PLAN.STARTER]: checkPassRuleWithStarter,
  [USER_PLAN.PLUS]: checkPassRuleWithPlus,
}

export const checkPassRuleWithPlan = ({
  plan,
  data,
}:{
  plan: number,
  data: ConfigRules,
}): PassRuleRespone => {
  return CHECK_PASS_RULE[plan as USER_PLAN](data);
}
