import { Progress, ScrollShadow, useDisclosure } from '@nextui-org/react';
import { useQueryClient } from '@tanstack/react-query';
import mixpanel from 'mixpanel-browser';
import { useSearchParams } from 'next/navigation';
import React, { memo, useEffect, useState } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

import { IconCreditFlashGray, IconCreditFlashOrange } from '@/assets';
import { Buttons } from '@/components';
import ClientOnly from '@/components/client-only';
import { baseSdxlModel, MIXPANEL_TRACK, sizeSdxl } from '@/configs/constant';
import { generateTypeText2ModelsEnable } from '@/configs/optionsModel';
import AdvancedSetting from '@/contents/gen-model/sideBar/advanced-setting';
import BtnSubmit from '@/contents/gen-model/sideBar/command/BtnSubmit';
import PopupValidateModel from '@/contents/gen-model/sideBar/modal/PopupValidateModel';
import SuggestLora from '@/contents/gen-model/sideBar/modal/SuggestLora';
import type { PromptProviderState } from '@/provider';
import { usePrompt } from '@/provider';
import { useAuth } from '@/provider/UserProvider';
import { getUseCase } from '@/services/usecase';
import { isJSONReturn, useSQuery } from '@/utils';

import SkeletonGen from '../SkeletonGen';
import CampaignGen from './CampaignGen';
import ModelGenOutOfCredits from './modal/ModelGenOutOfCredits';

interface SDXLModelProps {
  isLoading: boolean;
  imgSelect: PromptProviderState;
  generateImg: (data: any) => void;
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  createForm: UseFormReturn<FieldValues, any, undefined>;
  campaigns?: any;
  feeCredit: number;
  totalCredit: number;
}

const SDXLModel: React.FC<SDXLModelProps> = ({
  isLoading,
  imgSelect,
  generateImg,
  setOptionsSelectStyle,
  createForm,
  campaigns,
  feeCredit,
  totalCredit,
}) => {
  const { state, modelConfigRetry, validateFieldError, useModalValidate, tab } = usePrompt();
  const { isLogin } = useAuth();
  const { onOpen } = useModalValidate;
  const searchParams = useSearchParams();
  const queryClient = useQueryClient();
  const listLora: any = queryClient.getQueryData(['listLoras']);
  const [modelConfigSate, setModelConfigSate] = useState<any>(null);
  const { register, handleSubmit, watch, setValue, reset, getValues } = createForm;
  const { isOpen, onOpen: onOpenModelOutOfCredit, onOpenChange } = useDisclosure();

  const { data: useCase } = useSQuery({
    queryKey: ['get-use-case-id', searchParams.get('template')],
    queryFn: () => getUseCase(Number(searchParams.get('template'))),
    enabled: Boolean(searchParams.get('template')) && !isNaN(Number(searchParams.get('template'))),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const img = state.find((item) => item.imageId === imgSelect.imageId);

  const onSubmit = async (data: any) => {
    if (totalCredit < feeCredit) {
      onOpenModelOutOfCredit();
    } else {
      const { prompt, size, steps, batch_size, cfg_scale, height, width, ...rest } = data;

      if (!prompt) {
        validateFieldError.checkRequirePrompt(prompt);
        return onOpen();
      }
      mixpanel.track(MIXPANEL_TRACK.GENERATE_RUN);
      const sizeImage = sizeSdxl.find((item) => item.text === size);

      await generateImg({
        ...rest,
        prompt,
        height: sizeImage?.height || height,
        width: sizeImage?.width || width,
        steps: Number(steps),
        batch_size: Number(batch_size),
        cfg_scale: Number(cfg_scale),
      });
    }
  };

  useEffect(() => {
    setValue('prompt', state.find((item) => item.imageId === imgSelect.imageId)?.prompt);
  }, [imgSelect]);

  useEffect(() => {
    if (modelConfigSate) {
      const modelConfig = isJSONReturn(modelConfigSate?.processingAsset?.modelConfig);

      const loraConvert = modelConfig.lora
        ? modelConfig.lora.split(',').map((item: any) => item.split(':'))
        : '';
      const loras = loraConvert
        ? listLora?.data
            .filter((item: any) => loraConvert.some((lora: any) => lora[0] === item.alias))
            .map((lora: any) => ({
              ...lora,
              value: loraConvert.find((item: any) => item[0] === lora.alias)[1],
            }))
        : [];
      console.log('modelConfig', modelConfig);

      if (modelConfig.mode === 'upscale') {
        setValue('model', 'dreamshaperXL_lightningDPMSDE');
        setValue('height', modelConfig.height || 512);
        setValue('width', modelConfig?.width || 512);
        return setValue('prompt', modelConfigSate?.prompt?.prompt || '');
      }

      reset({
        prompt: modelConfigSate?.prompt?.prompt || '',
        negative_prompt: modelConfig.negative_prompt,
        modelId: modelConfigSate.modelId,
        model: 'dreamshaperXL_lightningDPMSDE',
        steps: modelConfig.steps,
        cfg_scale: modelConfig.cfg_scale,
        lora: loras || [],
        batch_size: isLogin ? modelConfig.batch_size || 1 : 1,
        width: modelConfig.width,
        height: modelConfig.height,
        enable_hires: modelConfig.enable_hires === 1,
        sampler: modelConfig?.samplers?.replace(' Karras', '') || baseSdxlModel.sampler,
        seed: modelConfig.seed ?? -1,
        size:
          sizeSdxl.find(
            (item: any) => item.width === modelConfig.width && item.height === modelConfig.height,
          )?.text || '1024x1024',
        vae: modelConfig.vae || baseSdxlModel.vae,
        hr_upscaler: modelConfig.hr_upscaler || baseSdxlModel.hr_upscaler,
        hr_scale_by: modelConfig.hr_scale_by || baseSdxlModel.hr_scale_by,
        hr_denoising_strength:
          modelConfig.hr_denoising_strength || baseSdxlModel.hr_denoising_strength,
        hr_width: modelConfig.hr_width || baseSdxlModel.hr_width,
        hr_height: modelConfig.hr_height || baseSdxlModel.hr_height,
        hr_steps: modelConfig.hr_steps || baseSdxlModel.hr_steps,
      });
      if (modelConfigRetry) {
        queryClient.removeQueries({ queryKey: ['model-config-retry'] });
      }
    } else if (!watch('campaignId')) {
      reset({
        ...baseSdxlModel,
        ...(watch('prompt') && { prompt: watch('prompt') }),
        ...(watch('lora')?.length > 0 && { lora: watch('lora') }),
        ...(watch('model') > 0 && { model: watch('model') }),
      });
    }
  }, [modelConfigSate, listLora]);

  useEffect(() => {
    if (useCase || modelConfigRetry) {
      setModelConfigSate(useCase || modelConfigRetry);
    }
  }, [useCase, modelConfigRetry]);

  return (
    <>
      <ClientOnly skeleton={<SkeletonGen />}>
        <form
          className="flex h-full flex-col justify-between md:w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <PopupValidateModel />
          <ScrollShadow
            aria-checked={tab === 'result'}
            size={10}
            hideScrollBar
            className="flex-1 overflow-y-auto scrollbar-hide md:flex md:justify-start aria-checked:md:hidden sm:flex-col"
          >
            <div className="md:mb-2 md:!w-full">
              <CampaignGen createForm={createForm} campaigns={campaigns} />
              <SuggestLora createForm={createForm} setOptionsSelectStyle={setOptionsSelectStyle} />
              <div className="md:!ml-0 md:!w-full [&>*:first-child]:px-0">
                <div>
                  {generateTypeText2ModelsEnable.text2option?.options.map((item: any, index) => (
                    <AdvancedSetting
                      getValues={getValues}
                      sizeModel={sizeSdxl}
                      setOptionsSelectStyle={setOptionsSelectStyle}
                      register={register}
                      watch={watch}
                      index={index}
                      item={item}
                      setValue={setValue}
                    />
                  ))}
                </div>
              </div>
            </div>
          </ScrollShadow>
          <BtnSubmit>
            <div className="md:flex md:flex-col md:items-start">
              <div className="mb-3 flex items-center justify-between text-14 text-gray-500 md:mb-1 md:flex-col md:items-start md:justify-normal">
                {isLogin && (
                  <p className="flex items-center ">
                    Your available credits: {totalCredit} <IconCreditFlashGray />
                    {/* <ModelRequestNotificationLimit /> */}
                  </p>
                )}
                <p className="flex items-center gap-1">
                  Fee:
                  <span className="flex items-center text-orange-500">
                    {feeCredit.toString() !== '0.0' ? `-${feeCredit}` : '0'}
                    <IconCreditFlashOrange />
                  </span>{' '}
                </p>
              </div>

              <Buttons
                disabled={isLoading || state.some((img) => img?.readyRun)}
                className="w-full p-0"
                type="submit"
              >
                <Progress
                  disableAnimation
                  value={img?.progress === '0%' ? 100 : Number(img?.progress.slice(0, -1))}
                  isIndeterminate={isLoading || img?.readyRun}
                  color="primary"
                  label={<span className="text-white">Generate</span>}
                  className="relative h-full"
                  classNames={{
                    labelWrapper: 'contents z-9999',
                    base: 'h-full',
                    track: `h-full ${
                      isLoading || img?.progress !== '100%' || !watch('prompt') ? 'bg-disabled' : ''
                    }`,
                    label: ['absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-999'],
                  }}
                />
              </Buttons>
            </div>
          </BtnSubmit>
        </form>
      </ClientOnly>
      {isLogin && <ModelGenOutOfCredits isOpen={isOpen} onOpenChange={onOpenChange} />}
    </>
  );
};

export default memo(SDXLModel);
