import { Skeleton } from '@nextui-org/react';

const SkeletonGen = () => {
  return (
    <div className="w-full">
      <div className="flex h-[calc(100vh-167px)] w-full md:hidden md:flex-col">
        <div className="w-full pl-0">
          <Skeleton className="h-[5%] rounded-lg" />
          <Skeleton className="mt-5 h-[20%] rounded-lg" />
          <Skeleton className="mt-5 h-[20%] rounded-lg" />
          <Skeleton className="mt-5 h-[38%] rounded-lg" />
          <Skeleton className="mt-8 h-[5%] content-end rounded-lg" />
        </div>
      </div>
      <div className="hidden h-[calc(100vh-167px)] w-full md:block">
        <Skeleton className="mx-2 mt-5 h-[8%] rounded-lg" />
        <Skeleton className="mx-2 mt-5 h-[5%] w-[30%] rounded-lg" />
        <Skeleton className="mx-2 mt-5 h-[20%] rounded-lg" />
        <Skeleton className="mx-2 mt-5 h-[5%] w-[30%] rounded-lg" />
        <Skeleton className="mx-2 mt-5 h-[30%] rounded-lg" />
        <Skeleton className="mx-2 mt-5 h-[15%] rounded-lg" />
      </div>
    </div>
  );
};

export default SkeletonGen;
