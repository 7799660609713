import { Input, Switch } from '@nextui-org/react';
import React from 'react';
import type {
  FieldValues,
  RegisterOptions,
  UseFormRegisterReturn,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { match } from 'ts-pattern';

import { DiceIcon, InfoGenIcon } from '@/assets';
import { SelectBase } from '@/components';
import TooltipCustom from '@/components/tooltip-custom';
import { listSampler, sizeText2Image } from '@/configs/constant';
import LoraModel from '@/contents/gen-model/sideBar/modal/LoraModel';
import ModelText2Image from '@/contents/gen-model/sideBar/modal/ModelText2Image';

interface OpsItemProps {
  watch: UseFormWatch<FieldValues>;
  item: {
    label: string;
    key: string;
    min: number;
    max: number;
    default: number;
    step: number;
    type: string;
    tooltip: string;
  };
  register: (
    name: string,
    options?: RegisterOptions<FieldValues, string> | undefined,
  ) => UseFormRegisterReturn<string>;
  setValue: UseFormSetValue<FieldValues>;
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
}

const OpsItem: React.FC<OpsItemProps> = ({
  watch,
  item,
  register,
  setValue,
  setOptionsSelectStyle,
}) => {
  const RenderItemFollowType = () => {
    return match(item.type)
      .with('range', () => (
        <input
          defaultValue={item.default}
          type="range"
          className="h-3 w-full bg-gray-100"
          min={item.min}
          max={item.max}
          step={item.step}
          {...register(item.key)}
        />
      ))
      .with('model', () => (
        <ModelText2Image setOptionsSelectStyle={setOptionsSelectStyle} watch={watch} />
      ))
      .with('lora', () => (
        <LoraModel
          setOptionsSelectStyle={setOptionsSelectStyle}
          setValue={setValue}
          watch={watch}
        />
      ))
      .with('selectSize', () => (
        <SelectBase
          classTrigger="bg-white"
          defaultValue={watch('size')}
          onChange={(e) => setValue('size', e)}
          list={sizeText2Image.map((item) => ({ value: item.text, label: item.text }))}
        />
      ))
      .with('selectSampler', () => (
        <SelectBase
          classTrigger="bg-white"
          defaultValue={watch('sampler')?.replace(' Karras', '') || ''}
          onChange={(e) => setValue('sampler', e)}
          list={listSampler.map((item) => ({ value: item, label: item }))}
        />
      ))
      .with('checkbox', () => (
        <Switch
          isSelected={watch('enable_hires')}
          defaultSelected={watch('enable_hires')}
          aria-label="Automatic updates"
          onValueChange={(e) => setValue('enable_hires', e)}
        />
      ))
      .with('text-area', () => (
        <textarea
          defaultValue=""
          className="min-h-[149px] w-full resize-none rounded-[16px] border-none bg-white px-4 py-[14px] text-gray-500 outline-none"
          placeholder="longbody, lowres, bad anatomy, bad hands, missing fingers, extra digit, fewer digits, cropped, worst quality, low quality"
          {...register(item.key)}
        />
      ))
      .with('inputNumber', () => (
        <Input
          type="number"
          classNames={{
            input: ['dark:placeholder:text-white font-normal text-14 pl-1', 'rounded-[100px]'],
            innerWrapper: ['bg-transparent', 'rounded-[100px]'],
            inputWrapper: ['bg-white', 'rounded-[100px]'],
          }}
          endContent={
            <DiceIcon
              onClick={() =>
                setValue(item.key, Math.floor(Math.random() * (4294967295 - 1 + 1)) + 1)
              }
              className="cursor-pointer"
            />
          }
          {...register(item.key)}
        />
      ))
      .otherwise(() => <></>);
  };

  return (
    <>
      <div className="mb-2 flex items-center justify-between">
        <div className="flex items-center">
          <span className="text-14 font-semibold">{item.label}</span>
          {item.tooltip && (
            <TooltipCustom
              showArrow
              content={
                <span className="max-w-[370px] text-13 font-normal text-white">{item.tooltip}</span>
              }
              classNames={{ base: 'tooltip', arrow: 'arrow-tooltip' }}
            >
              <span className="ml-2">
                <InfoGenIcon />
              </span>
            </TooltipCustom>
          )}
        </div>
        {item.type === 'range' && (
          <span className="rounded-[8px] bg-white px-3 py-[6px] text-14 font-normal">
            {watch(item.key) ?? item.default}
          </span>
        )}
      </div>
      {RenderItemFollowType()}
    </>
  );
};

export default OpsItem;
