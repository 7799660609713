import { Divider, Progress, ScrollShadow, useDisclosure } from '@nextui-org/react';
import { useQueryClient } from '@tanstack/react-query';
import mixpanel from 'mixpanel-browser';
import { useSearchParams } from 'next/navigation';
import React, { memo, useEffect, useState } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

import { IconCreditFlashGray, IconCreditFlashOrange } from '@/assets';
import { Buttons } from '@/components';
import ClientOnly from '@/components/client-only';
import { baseSDXL3, MIXPANEL_TRACK } from '@/configs/constant';
import { generateSDXL3 } from '@/configs/optionsModel';
import AdvancedSetting from '@/contents/gen-model/sideBar/advanced-setting';
import BtnSubmit from '@/contents/gen-model/sideBar/command/BtnSubmit';
import PopupValidateModel from '@/contents/gen-model/sideBar/modal/PopupValidateModel';
import SuggestLora from '@/contents/gen-model/sideBar/modal/SuggestLora';
import type { PromptProviderState } from '@/provider';
import { usePrompt } from '@/provider';
import { useAuth } from '@/provider/UserProvider';
import { getUseCase } from '@/services/usecase';
import { isJSONReturn, useSQuery } from '@/utils';

import SkeletonGen from '../SkeletonGen';
import CampaignGen from './CampaignGen';
import ModelGenOutOfCredits from './modal/ModelGenOutOfCredits';

interface SDXL3Props {
  isLoading: boolean;
  imgSelect: PromptProviderState;
  generateImg: (data: any) => void;
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  createForm: UseFormReturn<FieldValues, any, undefined>;
  campaigns?: any;
  feeCredit: number;
  totalCredit: number;
}

const SDXL3: React.FC<SDXL3Props> = ({
  isLoading,
  imgSelect,
  generateImg,
  setOptionsSelectStyle,
  createForm,
  campaigns,
  feeCredit,
  totalCredit,
}) => {
  const { state, modelConfigRetry, validateFieldError, useModalValidate, tab } = usePrompt();
  const { onOpen } = useModalValidate;
  const { isLogin } = useAuth();
  const searchParams = useSearchParams();
  const queryClient = useQueryClient();
  const [modelConfigSate, setModelConfigSate] = useState<any>(null);
  const { handleSubmit, watch, setValue, reset, getValues, register } = createForm;
  const { isOpen, onOpen: onOpenModelOutOfCredit, onOpenChange } = useDisclosure();

  const { data: useCase } = useSQuery({
    queryKey: ['get-use-case-id', searchParams.get('template')],
    queryFn: () => getUseCase(Number(searchParams.get('template'))),
    enabled: Boolean(searchParams.get('template')) && !isNaN(Number(searchParams.get('template'))),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const img = state.find((item) => item.imageId === imgSelect.imageId);

  const onSubmit = async (data: any) => {
    if (totalCredit < feeCredit) {
      onOpenModelOutOfCredit();
    } else {
      const { prompt, ...rest } = data;
      if (!prompt) {
        validateFieldError.checkRequirePrompt(prompt);
        return onOpen();
      }
      mixpanel.track(MIXPANEL_TRACK.GENERATE_RUN);
      await generateImg({
        ...rest,
        prompt,
      });
    }
  };

  useEffect(() => {
    setValue('prompt', state.find((item) => item.imageId === imgSelect.imageId)?.prompt);
  }, [imgSelect]);

  useEffect(() => {
    if (modelConfigSate) {
      const modelConfig = isJSONReturn(modelConfigSate?.processingAsset?.modelConfig);

      if (modelConfig.mode === 'upscale') {
        return setValue('prompt', modelConfigSate?.prompt?.prompt || '');
      }

      reset({
        prompt: modelConfigSate?.prompt?.prompt || '',
        modelId: modelConfigSate?.modelId,
        model:
          modelConfig?.model !== 'sd3' && modelConfig?.model !== 'sd3-turbo'
            ? 'sd3'
            : modelConfig?.model,
        size: modelConfig?.size || '1:1',
        negative_prompt: modelConfig?.negative_prompt || '',
        seed: modelConfig?.seed || 0,
      });
      if (modelConfigRetry) {
        queryClient.removeQueries({ queryKey: ['model-config-retry'] });
      }
    } else {
      reset(baseSDXL3);
    }
  }, [modelConfigSate]);

  useEffect(() => {
    if (useCase || modelConfigRetry) {
      setModelConfigSate(useCase || modelConfigRetry);
    }
  }, [useCase, modelConfigRetry]);

  return (
    <>
      <ClientOnly skeleton={<SkeletonGen />}>
        <form
          className="flex h-full flex-col justify-between md:w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <PopupValidateModel />
          <ScrollShadow
            aria-checked={tab === 'result'}
            size={10}
            hideScrollBar
            className="flex-1 overflow-y-auto scrollbar-hide md:flex md:justify-start aria-checked:md:hidden sm:flex-col"
          >
            <div className="md:mb-2 md:!w-full">
              <CampaignGen createForm={createForm} campaigns={campaigns} />
              <SuggestLora createForm={createForm} setOptionsSelectStyle={setOptionsSelectStyle} />
            </div>
            <Divider className="mb-4 mt-5 bg-gray-100 md:hidden" />
            <div className="md:!w-full [&>*:first-child]:px-0">
              <div>
                {generateSDXL3.controlOption.options.map((item, index) => (
                  <AdvancedSetting
                    getValues={getValues}
                    setOptionsSelectStyle={setOptionsSelectStyle}
                    register={register}
                    watch={watch}
                    index={index}
                    item={item}
                    setValue={setValue}
                  />
                ))}
              </div>
            </div>
          </ScrollShadow>
          <BtnSubmit>
            <div className="md:flex md:flex-col md:items-start">
              <div className="mb-3 flex items-center justify-between text-14 text-gray-500 md:mb-1 md:flex-col md:items-start md:justify-normal">
                {isLogin ? (
                  <p className="flex items-center text-14 font-normal text-gray-500">
                    Your available credits: {totalCredit} <IconCreditFlashGray />
                    {/* <ModelRequestNotificationLimit /> */}
                  </p>
                ) : (
                  <p className="flex items-center text-16 font-semibold text-orange-500 md:text-13">
                    You must login to use model PLUS
                  </p>
                )}
                <p className="flex items-center gap-1">
                  Fee:
                  <span className="flex items-center text-orange-500">
                    {feeCredit.toString() !== '0.0' ? `-${feeCredit}` : '0'}
                    <IconCreditFlashOrange />
                  </span>{' '}
                </p>
              </div>

              {isLogin ? (
                <Buttons
                  disabled={isLoading || state.some((img) => img?.readyRun)}
                  className="w-full p-0"
                  type="submit"
                >
                  <Progress
                    disableAnimation
                    value={img?.progress === '0%' ? 100 : Number(img?.progress.slice(0, -1))}
                    isIndeterminate={isLoading || img?.readyRun}
                    color="primary"
                    label={<span className="text-white">Generate</span>}
                    className="relative h-full"
                    classNames={{
                      labelWrapper: 'contents z-9999',
                      base: 'h-full',
                      track: `h-full ${
                        isLoading || img?.progress !== '100%' || !watch('prompt')
                          ? 'bg-disabled'
                          : ''
                      }`,
                      label: ['absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-999'],
                    }}
                  />
                </Buttons>
              ) : (
                <Buttons className="w-full p-0 " typeBtn="disabled" disabled>
                  <span className="text-white">Generate</span>
                </Buttons>
              )}
            </div>
          </BtnSubmit>
        </form>
      </ClientOnly>
      {isLogin && <ModelGenOutOfCredits isOpen={isOpen} onOpenChange={onOpenChange} />}
    </>
  );
};

export default memo(SDXL3);
