import { Progress, ScrollShadow, useDisclosure } from '@nextui-org/react';
import { useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'next/navigation';
import React, { memo, useEffect, useState } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

import { Buttons, UpLoadFileModel } from '@/components';
import ClientOnly from '@/components/client-only';
import { baseModelLivePortrait } from '@/configs/constant';
import BtnSubmit from '@/contents/gen-model/sideBar/command/BtnSubmit';
import PopupValidateModel from '@/contents/gen-model/sideBar/modal/PopupValidateModel';
import type { PromptProviderState } from '@/provider';
import { usePrompt } from '@/provider';
import { getUseCase } from '@/services/usecase';
import { isJSONReturn, useSQuery } from '@/utils';

import SkeletonGen from '../SkeletonGen';
import CampaignGen from './CampaignGen';
import LoginModal from '@/layouts/header/Login';
import { useAuth } from '@/provider/UserProvider';

interface LivePortraitModelProps {
  createForm: UseFormReturn<FieldValues, any, undefined>;
  imgSelect: PromptProviderState;
  isLoading: boolean;
  generateImg: (data: { [key: string]: any }) => void;
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  campaigns?: any;
}

const LivePortraitModel: React.FC<LivePortraitModelProps> = ({
  createForm,
  imgSelect,
  isLoading,
  campaigns,
  generateImg,
  setOptionsSelectStyle,
}) => {
  const [fileImage, setFileImage] = useState<File>();
  const [fileVideo, setFileVideo] = useState<File>();
  const { state, modelConfigRetry, validateFieldError, useModalValidate, tab } = usePrompt();
  const { onOpen } = useModalValidate;
  const searchParams = useSearchParams();
  const [modelConfigSate, setModelConfigSate] = useState<any>(null);
  const queryClient = useQueryClient();
  const { handleSubmit, watch, setValue, reset  , getValues} = createForm;
  const img = state.find((item) => item.imageId === imgSelect.imageId);
  const {isLoginClient}  = useAuth()
    const {
      isOpen: isOpenLogin,
      onOpenChange: onOpenChangeLogin,
      onOpen: onOpenLogin,
      onClose: onCloseLogin,
    } = useDisclosure();

  useEffect(() => {
    if (img && img.file) {
      setFileImage(img.file);
      setValue('file', img.file);
    }
  }, [img]);

  const { data: useCase } = useSQuery({
    queryKey: ['get-use-case-id', searchParams.get('template')],
    queryFn: () => getUseCase(Number(searchParams.get('template'))),
    enabled: Boolean(searchParams.get('template')) && !isNaN(Number(searchParams.get('template'))),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const onSubmit = async (data: any) => {
    const { video, image } = data;
    if (!video || !image) {
      validateFieldError.checkRequireFile(image);
      validateFieldError.checkRequireVideo(video);
      return onOpen();
    }
    await generateImg(data);
    setOptionsSelectStyle('');
  };

  const handleClearFileImage = () => {
    setValue('image', null);
    setFileImage(undefined);
  };

  const handleClearFileVideo = () => {
    setValue('video', null);
    setFileVideo(undefined);
  };

  const handleChangeFileImage = (file: File) => {
    setValue('image', file);
    setFileImage(file);
  };

  const handleChangeFileVideo = (file: File) => {
    setValue('video', file);
    setFileVideo(file);
  };

  useEffect(() => {
    if (modelConfigSate) {
      const modelConfig = isJSONReturn(modelConfigSate?.processingAsset?.modelConfig);

      if (modelConfigSate.mode === 'upscale') {
        return setValue('prompt', modelConfigSate?.prompt?.prompt || '');
      }
      console.log('modelConfig', modelConfig);

      reset({
        modelId: modelConfigSate.modelId,
        imageUrl: modelConfig?.image_url || null,
        videoUrl: modelConfig?.video_url || null,
      });
      if (modelConfigRetry) {
        queryClient.removeQueries({ queryKey: ['model-config-retry'] });
      }
      if (modelConfigRetry) {
        queryClient.removeQueries({ queryKey: ['model-config-retry'] });
      }
    } else {
      reset(baseModelLivePortrait);
    }
  }, [modelConfigSate]);

  useEffect(() => {
    if (useCase || modelConfigRetry) {
      setModelConfigSate(useCase || modelConfigRetry);
    }
  }, [useCase, modelConfigRetry]);

  console.log('🐉 ~ getValues ~ 🚀\n', getValues());

  return (
    <ClientOnly skeleton={<SkeletonGen />}>
      <form
        className="flex h-full flex-col justify-between md:w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <LoginModal isOpen={isOpenLogin} onOpenChange={onOpenChangeLogin} onClose={onCloseLogin} />
        <PopupValidateModel />
        <ScrollShadow
          aria-checked={tab === 'result'}
          size={10}
          hideScrollBar
          className="flex flex-col overflow-y-auto md:flex-row aria-checked:md:hidden sm:!flex-col"
        >
          <div className="md:mb-2 md:!w-full">
            <CampaignGen createForm={createForm} campaigns={campaigns} />
            <div className="mb-2 mt-5 flex items-center justify-between">
              <div className="text-14 font-semibold">
                Upload Ref Image<span className="ml-1 text-red-600"> *</span>
              </div>
            </div>
            <div className="w-full">
              <UpLoadFileModel
                handleClearFile={handleClearFileImage}
                setFile={setFileImage}
                handleChange={handleChangeFileImage}
                required={false}
                file={
                  fileImage || (typeof watch('imageUrl') === 'string' ? watch('imageUrl') : null)
                }
              />
            </div>
            <div className="mb-2 mt-5 flex items-center justify-between">
              <div className="text-14 font-semibold">
                Upload video<span className="ml-1 text-red-600"> *</span>
              </div>
            </div>
            <div className="w-full">
              <UpLoadFileModel
                types={['mp4']}
                handleClearFile={handleClearFileVideo}
                setFile={setFileVideo}
                handleChange={handleChangeFileVideo}
                required={false}
                title="Drop your video file here, or browse"
                desc="Support: MP4. Max size: 5MB"
                file={
                  fileVideo || (typeof watch('videoUrl') === 'string' ? watch('videoUrl') : null)
                }
              />
            </div>
          </div>
        </ScrollShadow>
        <BtnSubmit>
          <div className="md:flex md:items-center md:justify-end">
            {isLoginClient ? (
              <Buttons
                disabled={isLoading || state.some((img) => img?.readyRun)}
                onClick={() => setOptionsSelectStyle('')}
                className="w-full p-0"
                type="submit"
              >
                <Progress
                  disableAnimation
                  value={img?.progress === '0%' ? 100 : Number(img?.progress.slice(0, -1))}
                  isIndeterminate={isLoading || img?.readyRun}
                  color="primary"
                  label={<span className="text-white">Generate</span>}
                  className="relative h-full"
                  classNames={{
                    labelWrapper: 'contents z-9999',
                    base: 'h-full',
                    track: `h-full ${
                      isLoading || img?.progress !== '100%' || !watch('prompt') || !watch('file')
                        ? 'bg-disabled'
                        : ''
                    }`,
                    label: ['absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-999'],
                  }}
                />
              </Buttons>
            ) : (
              <Buttons
                className="flex w-full items-center gap-2 py-2 leading-[15px]"
                typeBtn="secondary"
                onClick={onOpenLogin}
              >
                <div className="flex flex-col">
                  <p>You've ran out of Free Trial.</p>
                  <p>Please login to try more!</p>
                </div>
              </Buttons>
            )}
          </div>
        </BtnSubmit>
      </form>
    </ClientOnly>
  );
};

export default memo(LivePortraitModel);
