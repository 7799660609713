import React from 'react';

import { DirectionIcon, FastIcon, IconKing, InfoGenIcon, VaryIcon } from '@/assets';
import { CrownWhitePrimary } from '@/components/assets';
import type { PromptProviderState } from '@/provider';
import { usePrompt } from '@/provider';
import { useAuth } from '@/provider/UserProvider';
import TooltipCustom from '@/components/tooltip-custom';

interface ItemCustomImgProps {
  loading: boolean;
  img: PromptProviderState;
  handleEditFunctionMidjourney: (name: 'strong' | 'x15' | 'x20' | '4x' | '2x') => void;
}

const ItemCustomImg: React.FC<ItemCustomImgProps> = ({
  loading,
  img,
  handleEditFunctionMidjourney,
}) => {
  const { auth } = useAuth();
  const { state } = usePrompt();

  return (
    <div className="mb-4 md:mt-4">
      <div className="mb-4 flex items-start gap-2 text-13 font-normal text-gray-400">
        <InfoGenIcon />
        <span>These options are for this current image only</span>
      </div>
      <div
        aria-disabled={!auth.user.isPlus}
        className="flex flex-wrap gap-2 md:mt-4 [&>*:not(:last-child)]:aria-disabled:opacity-60"
      >
        {(img.tool === '--v 6.0' ? customFunctionMidjourneyV6 : customFunctionMidjourney).map(
          (item: any) => (
            <div
              aria-checked={img?.usedOptions?.includes(item.key)}
              aria-disabled={
                !auth.user.isPlus ||
                loading ||
                state.some((img) => img?.readyRun) ||
                img?.usedOptions?.includes(item.key)
              }
              onClick={() => handleEditFunctionMidjourney(item.key)}
              className="flex cursor-pointer items-center rounded-full bg-gray-100 px-4 py-2 aria-checked:bg-green-400 aria-checked:text-white aria-disabled:cursor-not-allowed"
            >
              <span className="mr-[6px]">{item.icon}</span>
              <span className="text-[13px] font-semibold">{item.desc}</span>
            </div>
          ),
        )}
        <div className="flex flex-1 items-center justify-center">
          {!auth.user.isPlus && (
            <TooltipCustom
              showArrow
              content={
                <div className="flex w-full flex-col p-3">
                  <div className="w-fit rounded-[32px] bg-primary p-1">
                    <CrownWhitePrimary />
                  </div>
                  <span className="mt-[14px] text-13 font-normal">
                    Subscribe to aiRight plus plan to use this tool.
                  </span>
                </div>
              }
              classNames={{ base: 'light-tooltip w-[226px]' }}
            >
              <span className="rounded-[10px] bg-secondary p-1">
                <IconKing />
              </span>
            </TooltipCustom>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemCustomImg;

const customFunctionMidjourney = [
  { icon: <VaryIcon />, desc: 'Vary (Strong)', key: 'strong' },
  // { icon: <VaryIcon />, desc: 'Vary (Subtle)', key: 'Subtle' },
  { icon: <DirectionIcon />, desc: 'Upscale (2x)', key: '2x' },
  { icon: <FastIcon />, desc: 'Upscale (4x)', key: '4x' },
  // { icon: <ZoomIcon />, desc: 'Zoom Out 2x', key: 'x20' },
  // { icon: <ZoomIcon />, desc: 'Zoom Out 1.5x', key: 'x15' },
  // { icon: <ZoomIcon />, desc: 'Custom zoom', key: 'custom' },
];

const customFunctionMidjourneyV6 = [{ icon: <VaryIcon />, desc: 'Vary (Strong)', key: 'strong' }];

// const directionCustomFuncMidjourney = [
//   <LeftDirectionIcon />,
//   <RightDirectionIcon />,
//   <AboveDirectionIcon />,
//   <DownDirectionIcon />,
// ];
