import React from 'react';

import { BgRange, DeleteIconBlack, IconBackBlack } from '@/assets';
import { Buttons } from '@/components';
import { useInpaintModel } from '@/provider';

const InpaintControl: React.FC<{
  onClear: () => void;
  onUndo: () => void;
  onRedo: () => void;
  onChangeRadius: (val: number) => void;
}> = ({ onClear, onUndo, onChangeRadius, onRedo }) => {
  const { brushRadius } = useInpaintModel();

  return (
    <div className="flex h-full flex-col items-center justify-between sm:mt-5 sm:flex-row sm:px-2">
      <div className="relative pt-[65px] sm:pt-0">
        <BgRange className="absolute left-[50%] top-0 -translate-x-[50%] sm:left-[65px] sm:top-[calc(50%+2px)] sm:-translate-y-[50%] sm:translate-x-0 sm:rotate-[90deg]" />
        <input
          min={2}
          max={50}
          onChange={(e) => onChangeRadius(Number(e.target.value))}
          className="reset-input-range paint-input-range relative w-[160px] -rotate-[90deg] bg-[transparent] sm:rotate-0"
          defaultValue={brushRadius}
          type="range"
        />
      </div>
      <div className="flex flex-col items-center justify-center sm:flex-row">
        <Buttons onClick={onUndo} className="h-[48px] w-[48px] min-w-0 rounded-[50%] bg-white p-0">
          <IconBackBlack />
        </Buttons>
        <Buttons
          onClick={onRedo}
          style={{ transform: 'rotateY(180deg)' }}
          className="mt-3 h-[48px] w-[48px] min-w-0 rounded-[50%] bg-white p-0 sm:ml-2 sm:mt-0"
        >
          <IconBackBlack />
        </Buttons>
        <Buttons
          onClick={onClear}
          className="mt-3 h-[48px] w-[48px] min-w-0 rounded-[50%] bg-white p-0 sm:ml-2 sm:mt-0"
        >
          <DeleteIconBlack />
        </Buttons>
      </div>
    </div>
  );
};

export default InpaintControl;
