import { Divider, Progress, ScrollShadow } from '@nextui-org/react';
import { useQueryClient } from '@tanstack/react-query';
import mergeImages from 'merge-images';
import { useSearchParams } from 'next/navigation';
import React, { memo, useEffect, useState } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

import { Buttons, UpLoadFileModel } from '@/components';
import ClientOnly from '@/components/client-only';
import { baseInpaintModel, onlyOneBatchSizeInpaint } from '@/configs/constant';
import { generateTypeInpainting, modelOptions } from '@/configs/optionsModel';
import AdvancedSetting from '@/contents/gen-model/sideBar/advanced-setting';
import BtnSubmit from '@/contents/gen-model/sideBar/command/BtnSubmit';
import OpsItem from '@/contents/gen-model/sideBar/custom/OpsItem';
import LoraModel from '@/contents/gen-model/sideBar/modal/LoraModel';
import PopupValidateModel from '@/contents/gen-model/sideBar/modal/PopupValidateModel';
import SuggestLora from '@/contents/gen-model/sideBar/modal/SuggestLora';
import { initialState, PromptProviderState } from '@/provider';
import { useInpaintModel, usePrompt } from '@/provider';
import { useAuth } from '@/provider/UserProvider';
import { getUseCase } from '@/services/usecase';
import { isJSONReturn, useSQuery } from '@/utils';

import {
  canvasDrawToImage,
  canvasToImage,
  fillColorBackground,
  imageBase64ToFile,
  onClear,
  resizeImage,
} from '../helpers/inpaint';
import SkeletonGen from '../SkeletonGen';
import CampaignGen from './CampaignGen';

interface InpaintModelProps {
  isLoading: boolean;
  imgSelect: PromptProviderState;
  setImgSelect: React.Dispatch<React.SetStateAction<PromptProviderState>>;
  generateImg: (data: { [key: string]: any }) => void;
  canvasRef: any;
  backgroundRef: any;
  createForm: UseFormReturn<FieldValues, any, undefined>;
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  campaigns?: any;
}

const InpaintingModel = ({
  canvasRef,
  backgroundRef,
  isLoading,
  imgSelect,
  generateImg,
  setImgSelect,
  createForm,
  setOptionsSelectStyle,
  campaigns,
}: InpaintModelProps) => {
  const [file, setFile] = useState<File>();
  const { isLogin } = useAuth();
  const { setImageImport, dimensions } = useInpaintModel();
  const { register, handleSubmit, watch, setValue, reset, getValues } = createForm;
  const { state, setState, modelConfigRetry, validateFieldError, useModalValidate, tab } =
    usePrompt();
  const { onOpen } = useModalValidate;
  const searchParams = useSearchParams();
  const queryClient = useQueryClient();
  const listLora: any = queryClient.getQueryData(['listLoras']);
  const [modelConfigSate, setModelConfigSate] = useState<any>(null);
  const img = state.find((item) => imgSelect && item.imageId === imgSelect.imageId);
  const area = watch('inpaint_area');

  const { data: useCase } = useSQuery({
    queryKey: ['get-use-case-id', searchParams.get('template')],
    queryFn: () => getUseCase(Number(searchParams.get('template'))),
    enabled: Boolean(searchParams.get('template')) && !isNaN(Number(searchParams.get('template'))),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const handleClearFile = () => {
    setValue('image', null);
    resetInpaint();
    setState([initialState]);
  };

  const resetInpaint = () => {
    setImageImport('');
    onClear(canvasRef.current);
  };

  const handleChangeFile = (file: File) => {
    setValue('image', file);
    setFile(file);
    setImageImport(URL.createObjectURL(file));
    const imageState = {
      customId: null,
      done: false,
      imageId: -1,
      progress: '0%',
      prompt: '',
      readyRun: false,
      step: -1,
      url: URL.createObjectURL(file),
      assetId: 0,
    } as PromptProviderState;
    setState([imageState]);
    setImgSelect(imageState);
    setOptionsSelectStyle('');
  };

  const getMaskImage = async () => {
    const canvasPaint = canvasRef.current;
    const canvasBackground = backgroundRef.current;
    if (!canvasPaint || !canvasBackground) return;

    fillColorBackground(canvasBackground, '#000000');
    const contentImage = await canvasDrawToImage(canvasPaint);
    const backgroundImage = canvasToImage(canvasBackground);

    const maskImage = await mergeImages([backgroundImage, contentImage]);
    return maskImage;
  };

  const onSubmit = async (data: any) => {
    const { prompt, image, steps, batch_size, cfg_scale, ...rest } = data;

    if (!prompt || !image) {
      validateFieldError.checkRequirePrompt(prompt);
      validateFieldError.checkRequireFile(image);
      return onOpen();
    }
    const maskImage = await getMaskImage();
    if (!maskImage) {
      validateFieldError.pushError('Mask image is required.');
      return onOpen();
    }
    if (img?.done) return;

    const maskImageResized = await resizeImage(maskImage, dimensions.width, dimensions.height);
    const maskFile = await imageBase64ToFile(maskImageResized as string, 'mask.png', 'image/png');
    const params = {
      ...rest,
      prompt,
      image,
      mask: maskFile,
      width: dimensions.width,
      height: dimensions.height,
      steps: Number(steps),
      batch_size: Number(batch_size),
      cfg_scale: Number(cfg_scale),
    };
    await generateImg(params);
  };

  useEffect(() => {
    setValue('prompt', state.find((item) => item.imageId === imgSelect.imageId)?.prompt);
  }, [imgSelect]);

  useEffect(() => {
    if (modelConfigSate) {
      const modelConfig = isJSONReturn(modelConfigSate?.processingAsset?.modelConfig);
      const loraConvert = modelConfig.lora
        ? modelConfig.lora.split(',').map((item: any) => item.split(':'))
        : '';
      const loras = loraConvert
        ? listLora?.data
            .filter((item: any) => loraConvert.some((lora: any) => lora[0] === item.alias))
            .map((lora: any) => ({
              ...lora,
              value: loraConvert.find((item: any) => item[0] === lora.alias)[1],
            }))
        : [];
      if (modelConfig.mode === 'upscale') {
        return setValue('prompt', modelConfigSate?.prompt?.prompt || '');
      }
      reset({
        prompt: modelConfigSate?.prompt?.prompt,
        modelId: modelConfigSate.modelId,
        steps: modelConfig.steps,
        cfg_scale: modelConfig.cfg_scale,
        control_weight: modelConfig.control_weight,
        model: modelConfig.model,
        negative_prompt: modelConfig.negative_prompt,
        batch_size: isLogin ? modelConfig.batch_size || 1 : 1,
        lora: loras || [],
        seed: modelConfig.seed ?? -1,
        sampler: modelConfig.samplers && modelConfig?.samplers?.replace(' Karras', ''),
      });
      if (modelConfigRetry) {
        queryClient.removeQueries({ queryKey: ['model-config-retry'] });
      }
    } else {
      reset(baseInpaintModel);
    }
  }, [useCase, listLora, modelConfigRetry]);

  useEffect(() => {
    if (useCase || modelConfigRetry) {
      setModelConfigSate(useCase || modelConfigRetry);
    }
  }, [useCase, modelConfigRetry]);

  useEffect(() => {
    if (onlyOneBatchSizeInpaint.includes(area)) {
      setValue('batch_size', 1);
    }
  }, [area]);

  return (
    <ClientOnly skeleton={<SkeletonGen />}>
      <form
        className="flex h-full flex-col justify-between md:w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <PopupValidateModel />
        <ScrollShadow
          aria-checked={tab === 'result'}
          size={10}
          hideScrollBar
          className="flex flex-col overflow-y-auto md:flex-row aria-checked:md:hidden sm:!flex-col"
        >
          <div className="md:mb-2 md:!w-full">
            <CampaignGen createForm={createForm} campaigns={campaigns} />
            <div className="mb-2 flex items-center justify-between">
              <div className="text-14 font-semibold">
                Image
                <span className="text-primary">*</span>
              </div>
            </div>
            <div className="mb-5 w-full">
              <UpLoadFileModel
                handleClearFile={handleClearFile}
                setFile={setFile}
                file={file}
                handleChange={handleChangeFile}
                required={false}
              />
            </div>
            <SuggestLora createForm={createForm} setOptionsSelectStyle={setOptionsSelectStyle} />
            <OpsItem
              setOptionsSelectStyle={setOptionsSelectStyle}
              item={modelOptions}
              register={register}
              watch={watch}
              setValue={setValue}
            />
            <Divider className="my-5 w-full bg-gray-100" />
            <div className="md:!w-full [&>*:first-child]:px-0">
              <div>
                <div className="mb-5">
                  <p className="mb-2 text-14 font-semibold">Lora</p>{' '}
                  <LoraModel
                    setOptionsSelectStyle={setOptionsSelectStyle}
                    setValue={setValue}
                    watch={watch}
                  />
                </div>
                {generateTypeInpainting.inpaintOption.options.map((item, index) => (
                  <AdvancedSetting
                    getValues={getValues}
                    setOptionsSelectStyle={setOptionsSelectStyle}
                    register={register}
                    watch={watch}
                    index={index}
                    item={item}
                    setValue={setValue}
                  />
                ))}
              </div>
            </div>
          </div>
        </ScrollShadow>
        <BtnSubmit>
          <div className="md:flex md:items-center md:justify-end">
            <Buttons
              disabled={isLoading || state.some((img) => img?.readyRun)}
              onClick={() => setOptionsSelectStyle('')}
              className="w-full p-0"
              type="submit"
            >
              <Progress
                disableAnimation
                value={img?.progress === '0%' ? 100 : Number(img?.progress.slice(0, -1))}
                isIndeterminate={isLoading || img?.readyRun}
                color="primary"
                label={<span className="text-white">Generate</span>}
                className="relative h-full"
                classNames={{
                  labelWrapper: 'contents z-9999',
                  base: 'h-full',
                  track: `h-full ${
                    isLoading || img?.progress !== '100%' || !watch('prompt') || !watch('image')
                      ? 'bg-disabled'
                      : ''
                  }`,
                  label: ['absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-999'],
                }}
              />
            </Buttons>
          </div>
        </BtnSubmit>
      </form>
    </ClientOnly>
  );
};

export default memo(InpaintingModel);
