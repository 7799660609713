import { Divider, useDisclosure } from '@nextui-org/react';
import React from 'react';
import type {
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { IconLockLogin } from '@/assets';
import type { RatioOfModel, SizeOfModel } from '@/configs/constant';
import OptionText2 from '@/contents/gen-model/sideBar/custom/OptionText2';
import LoginModal from '@/layouts/header/Login';
import { useAuth } from '@/provider/UserProvider';

interface AdvancedSettingProps {
  item: any;
  index: number;
  getValues: UseFormGetValues<FieldValues>;
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  sizeModel?: SizeOfModel;
  ratioModel?: RatioOfModel;
}

const AdvancedSetting: React.FC<AdvancedSettingProps> = ({
  item,
  index,
  getValues,
  setOptionsSelectStyle,
  register,
  watch,
  setValue,
  sizeModel,
  ratioModel,
}) => {
  const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
  const { isLogin } = useAuth();
  return (
    <div
      aria-expanded={item?.placement === 'horizontal'}
      key={index}
      className="mb-5 aria-expanded:flex aria-expanded:items-center aria-expanded:justify-between"
    >
      {item.positionLogin && !isLogin && (
        <>
          <LoginModal isOpen={isOpen} onOpenChange={onOpenChange} onClose={onClose} />
          <div
            onClick={onOpen}
            className="mb-5 flex cursor-pointer items-center rounded-[8px] bg-orange-50 px-4 py-1"
          >
            <div className="mr-3 rounded-full bg-white p-[10px]">
              <IconLockLogin />
            </div>
            <span className="text-14 font-semibold text-orange-500">
              Log in for advanced customization
            </span>
          </div>
          <Divider className="my-5 w-full bg-gray-100" />
        </>
      )}
      <OptionText2
        getValues={getValues}
        setOptionsSelectStyle={setOptionsSelectStyle}
        item={item}
        register={register}
        watch={watch}
        setValue={setValue}
        borderLess={item.borderLess}
        sizeModel={sizeModel}
        ratioModel={ratioModel}
      />
    </div>
  );
};

export default AdvancedSetting;
