import React, { useEffect } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import type { PromptProviderState } from '@/provider';
import { usePrompt } from '@/provider';
import type { Models } from '@/types';

import GenModelDisplay from './GenModelDisplay';
import InpaintGenDisplay from './InpaintGenDisplay';
import PopupSelectStyle from '@/contents/gen-model/contentFeed/PopupSelectStyle';

interface FeedProps {
  optionsSelectStyle: string;
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  modelDetail: Models;
  loading: boolean;
  isNotModelSpecial: boolean;
  imgSelect: PromptProviderState;
  generateUpscaleText2Image: () => void;
  canvasRef: any;
  backgroundRef: any;
  createForm: UseFormReturn<FieldValues, any, undefined>;
  fieldName?: string;
}

const Feed: React.FC<FeedProps> = ({
  optionsSelectStyle,
  setOptionsSelectStyle,
  modelDetail,
  loading,
  isNotModelSpecial,
  imgSelect,
  generateUpscaleText2Image,
  canvasRef,
  backgroundRef,
  createForm,
  fieldName = 'model',
}) => {
  const { openCampaign } = usePrompt();

  useEffect(() => {
    if (openCampaign) {
      setOptionsSelectStyle('example-campaign');
    } else if (optionsSelectStyle === 'example-campaign') {
      setOptionsSelectStyle('');
    }
  }, [openCampaign]);

  return (
    <>
      <PopupSelectStyle
        createForm={createForm}
        modelDetail={modelDetail}
        optionsSelectStyle={optionsSelectStyle}
        setOptionsSelectStyle={setOptionsSelectStyle}
        fieldName={fieldName}
      />
      <div className="h-full w-full">
        {modelDetail.type === 'Inpaint' ? (
          <InpaintGenDisplay
            canvasRef={canvasRef}
            backgroundRef={backgroundRef}
            imgSelect={imgSelect}
          />
        ) : (
          <GenModelDisplay
            loading={loading}
            generateUpscaleText2Image={generateUpscaleText2Image}
            isNotModelSpecial={isNotModelSpecial}
            imgSelect={imgSelect}
            model={modelDetail.type}
          />
        )}
      </div>
    </>
  );
};

export default Feed;
