import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import React from 'react';

interface ModelControlImgProps {
  deleteAsset: () => Promise<void>;
  isOpen: boolean;
  onOpenChange: () => void;
}

const ModelControlImg: React.FC<ModelControlImgProps> = ({ deleteAsset, isOpen, onOpenChange }) => {
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      placement="center"
      hideCloseButton
      classNames={{ base: 'py-2 rounded-[24px]', footer: 'flex-col justify-center' }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 px-[40px] pb-0 text-center text-[24px] font-semibold">
              Are you sure you want to delete these artworks?
            </ModalHeader>
            <ModalBody>
              <p className="text-center text-[18px] leading-7 text-black">
                This action cannot be undone.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button
                className="rounded-[100px] bg-orange-500 py-[10px] text-[15px] font-semibold text-white"
                onPress={onClose}
                onClick={deleteAsset}
                color="primary"
              >
                Delete
              </Button>
              <Button
                color="default"
                className="rounded-[100px] py-[10px] text-[15px] font-semibold"
                onPress={onClose}
              >
                Cancel
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ModelControlImg;
