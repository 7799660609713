import { Divider, Progress, ScrollShadow } from '@nextui-org/react';
import { useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'next/navigation';
import React, { memo, useEffect, useState } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

import { Buttons, UpLoadFileModel } from '@/components';
import ClientOnly from '@/components/client-only';
import { baseModelIpAdapter } from '@/configs/constant';
import { generateIpAdapter } from '@/configs/optionsModel';
import AdvancedSetting from '@/contents/gen-model/sideBar/advanced-setting';
import BtnSubmit from '@/contents/gen-model/sideBar/command/BtnSubmit';
import PopupValidateModel from '@/contents/gen-model/sideBar/modal/PopupValidateModel';
import SuggestLora from '@/contents/gen-model/sideBar/modal/SuggestLora';
import type { PromptProviderState } from '@/provider';
import { usePrompt } from '@/provider';
import { getUseCase } from '@/services/usecase';
import { isJSONReturn, useSQuery } from '@/utils';

import SkeletonGen from '../SkeletonGen';
import CampaignGen from './CampaignGen';

interface IpAdapterProps {
  createForm: UseFormReturn<FieldValues, any, undefined>;
  imgSelect: PromptProviderState;
  isLoading: boolean;
  generateImg: (data: { [key: string]: any }) => void;
  setOptionsSelectStyle: React.Dispatch<React.SetStateAction<string>>;
  campaigns?: any;
}

const IpAdapter: React.FC<IpAdapterProps> = ({
  createForm,
  imgSelect,
  isLoading,
  campaigns,
  generateImg,
  setOptionsSelectStyle,
}) => {
  const [file, setFile] = useState<File>();
  const [referenceFile, setReferenceFile] = useState<File>();
  const { state, modelConfigRetry, validateFieldError, useModalValidate, tab } = usePrompt();
  const { onOpen } = useModalValidate;
  const searchParams = useSearchParams();
  const [modelConfigSate, setModelConfigSate] = useState<any>(null);
  const queryClient = useQueryClient();
  const { handleSubmit, watch, setValue, reset, getValues, register } = createForm;
  const img = state.find((item) => item.imageId === imgSelect.imageId);

  useEffect(() => {
    setValue('prompt', state.find((item) => item.imageId === imgSelect.imageId)?.prompt);
  }, [imgSelect]);

  useEffect(() => {
    if (img && img.file) {
      setFile(img.file);
      setValue('file', img.file);
    }
  }, [img]);

  const { data: useCase } = useSQuery({
    queryKey: ['get-use-case-id', searchParams.get('template')],
    queryFn: () => getUseCase(Number(searchParams.get('template'))),
    enabled: Boolean(searchParams.get('template')) && !isNaN(Number(searchParams.get('template'))),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const onSubmit = async (data: any) => {
    const { prompt, image, refImage } = data;
    if (!prompt || !image || !refImage) {
      validateFieldError.checkRequirePrompt(prompt);
      validateFieldError.checkRequire(image, 'Original image');
      validateFieldError.checkRequire(refImage, 'Reference image');
      return onOpen();
    }
    await generateImg(data);
    setOptionsSelectStyle('');
  };

  const handleChangeFile = (file: File) => {
    setValue('image', file);
    setFile(file);
  };

  const handleChangeFileRef = (file: File) => {
    setValue('refImage', file);
    setReferenceFile(file);
  };

  useEffect(() => {
    if (modelConfigSate) {
      const modelConfig = isJSONReturn(modelConfigSate?.processingAsset?.modelConfig);

      if (modelConfigSate.mode === 'upscale') {
        return setValue('prompt', modelConfigSate?.prompt?.prompt || '');
      }

      reset({
        modelId: modelConfigSate.modelId,
        prompt: modelConfigSate?.prompt?.prompt || '',
        negative_prompt: modelConfig?.negative_prompt || '',
        controlType: modelConfig?.control_type || 'strong style and composition',
        control_module: modelConfig?.control_module || 'scribble',
        image: modelConfig?.original_image_url || null,
        refImage: modelConfig?.ref_image_url || null,
      });
      if (modelConfigRetry) {
        queryClient.removeQueries({ queryKey: ['model-config-retry'] });
      }
    } else {
      reset(baseModelIpAdapter);
    }
  }, [modelConfigSate]);

  useEffect(() => {
    if (useCase || modelConfigRetry) {
      setModelConfigSate(useCase || modelConfigRetry);
    }
  }, [useCase, modelConfigRetry]);

  return (
    <ClientOnly skeleton={<SkeletonGen />}>
      <form
        className="flex h-full flex-col justify-between md:w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <PopupValidateModel />
        <ScrollShadow
          aria-checked={tab === 'result'}
          size={10}
          hideScrollBar
          className="flex flex-col overflow-y-auto md:flex-row aria-checked:md:hidden sm:!flex-col"
        >
          <div className="md:mb-2 md:!w-full">
            <CampaignGen createForm={createForm} campaigns={campaigns} />
            <div className="mb-2 flex items-center justify-between">
              <div className="flex text-14 font-semibold">
                Original image<span className="text-primary">*</span>
              </div>
            </div>
            <div className="mb-5 w-full">
              <UpLoadFileModel
                required={false}
                handleClearFile={() => setValue('image', null)}
                setFile={setFile}
                file={file || (typeof watch('image') === 'string' ? watch('image') : null)}
                handleChange={handleChangeFile}
              />
            </div>
            <div className="mb-2 flex items-center justify-between">
              <div className="text-14 font-semibold">
                Reference image<span className="text-primary">*</span>
              </div>
            </div>
            <div className="mb-5 w-full">
              <UpLoadFileModel
                required={false}
                handleClearFile={() => setValue('refImage', null)}
                setFile={setReferenceFile}
                file={
                  referenceFile ||
                  (typeof watch('refImage') === 'string' ? watch('refImage') : null)
                }
                handleChange={handleChangeFileRef}
              />
            </div>
            <Divider className="my-5 bg-gray-100 md:hidden" />
            <SuggestLora createForm={createForm} setOptionsSelectStyle={setOptionsSelectStyle} />
            <div className="md:!w-full [&>*:first-child]:px-0">
              <div>
                {generateIpAdapter.controlOption.options.map((item, index) => (
                  <AdvancedSetting
                    getValues={getValues}
                    setOptionsSelectStyle={setOptionsSelectStyle}
                    register={register}
                    watch={watch}
                    index={index}
                    item={item}
                    setValue={setValue}
                  />
                ))}
              </div>
            </div>
          </div>
        </ScrollShadow>
        <BtnSubmit>
          <div className="md:flex md:items-center md:justify-end">
            <Buttons
              disabled={isLoading || state.some((img) => img?.readyRun)}
              onClick={() => setOptionsSelectStyle('')}
              className="w-full p-0"
              type="submit"
            >
              <Progress
                disableAnimation
                value={img?.progress === '0%' ? 100 : Number(img?.progress.slice(0, -1))}
                isIndeterminate={isLoading || img?.readyRun}
                color="primary"
                label={<span className="text-white">Generate</span>}
                className="relative h-full"
                classNames={{
                  labelWrapper: 'contents z-9999',
                  base: 'h-full',
                  track: `h-full ${
                    isLoading || img?.progress !== '100%' || !watch('prompt') ? 'bg-disabled' : ''
                  }`,
                  label: ['absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-999'],
                }}
              />
            </Buttons>
          </div>
        </BtnSubmit>
      </form>
    </ClientOnly>
  );
};

export default memo(IpAdapter);
