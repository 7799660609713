import { useSearchParams } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

import {
  IconCheckFilledGreen,
  IconFireGenCampaign,
  IconRedirectGreen,
  IconWarnYellow,
} from '@/assets';
import { Images } from '@/components';
import { usePrompt } from '@/provider';
import { useRouter } from '@/provider/RouterProvider';
import { checkFinishedTask } from '@/services/campaign';

interface CampaignGenProps {
  createForm: UseFormReturn<FieldValues, any, undefined>;
  campaigns?: any;
}

const CampaignGen: React.FC<CampaignGenProps> = ({ createForm, campaigns }) => {
  const { setValue, unregister } = createForm;
  const router = useRouter();
  const searchParam = useSearchParams();
  const getCampaignIdByParam = Number(searchParam?.get('campaignID'));

  const [selectCampaignID, setSelectCampaignID] = useState<number>();

  const [finishedTask, setFinishedTask] = useState(true);
  const { setOpenCampaign } = usePrompt();

  const handleCampaignClick = async (id: number) => {
    const res: any = await checkFinishedTask(id);
    setOpenCampaign(true);
    setSelectCampaignID(id === selectCampaignID ? undefined : id);

    if (id === selectCampaignID) {
      setFinishedTask(true);
      setOpenCampaign(false);
      return unregister('campaignId');
    }

    if (res && res.data) {
      setValue('campaignId', id);
      setFinishedTask(true);
    } else {
      unregister('campaignId');
      setFinishedTask(false);
    }
  };
  useEffect(() => {
    if (getCampaignIdByParam > 0) {
      handleCampaignClick(getCampaignIdByParam);
    }
  }, [getCampaignIdByParam]);

  return (
    <div className="my-5">
      {Object.keys(campaigns).length >= 1 && (
        <div className="mb-3">
          <div className="flex items-center justify-between pb-3">
            <p className="flex items-center text-14 font-semibold">
              Select Campaign
              <span className="pl-2">
                <IconFireGenCampaign />
              </span>
            </p>
            {selectCampaignID && (
              <p
                onClick={() => router.push(`/campaign/${selectCampaignID}`)}
                className="flex cursor-pointer items-center text-[12px] font-normal leading-5 text-green-500"
              >
                Go to details <IconRedirectGreen className="ml-2" />
              </p>
            )}
          </div>
          <div className="px-2">
            <div className="grid grid-cols-2 gap-[14px]">
              {campaigns.map((campaign: any) => (
                <div
                  className={`relative flex  h-[60px] w-full cursor-pointer items-center justify-center ${
                    selectCampaignID === campaign.id && 'rounded-[15px] border-3 border-green-500'
                  }`}
                  onClick={() => handleCampaignClick(campaign.id)}
                >
                  {selectCampaignID === campaign.id && (
                    <span className="absolute -right-[9px] -top-[10px] z-20">
                      <IconCheckFilledGreen />
                    </span>
                  )}
                  <Images
                    className="z-0 h-full w-full rounded-xl object-cover"
                    src={campaign.banner}
                    alt=""
                  />
                  <p
                    className="absolute top-0 z-10 h-full w-full rounded-[12px]"
                    style={{
                      background:
                        'radial-gradient(46.67% 46.67% at 50% 53.33%, #000 0%, rgba(0, 0, 0, 0.30) 100%)',
                    }}
                  />
                  <div className="pointer-events-none absolute z-20 text-14 font-semibold text-white">
                    {campaign.tag}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {selectCampaignID && finishedTask && (
            <div className="pt-3 text-14 leading-5 text-gray-500">
              Fees: {campaigns.find((item: any) => item.id === Number(selectCampaignID))?.fees}{' '}
              Credits
            </div>
          )}
          {!finishedTask && (
            <p className="mt-3 flex items-start px-2 text-14 font-normal text-orange-500">
              <IconWarnYellow className="mr-3 w-[35px]" />
              Remember to complete all tasks required before generating!
            </p>
          )}
        </div>
      )}
      {Object.keys(campaigns).length >= 1 && <hr className="bg-gray-100" />}
    </div>
  );
};

export default CampaignGen;
